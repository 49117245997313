<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data () {
    return {
      screenWidth: null // 屏幕尺寸
    }
  },
  mounted () {
    setTimeout(() => { this.screenWidth = document.body.clientWidth }, 500)
    window.onresize = () => { // 屏幕尺寸变化就重新赋值
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  watch: {
    // 监听屏幕尺寸,根据不同的尺寸进入不同的页面  比如 小于1200 进入mwelcome手机首页 如果大于1200 进入 welcome PC首页
    screenWidth: {
      handler: function (val, oldVal) {
        let path = this.$router.history.current.fullPath
        if (val > 1200) {
          if (path.split('/')[1].charAt(0) === 'm') {
            path = '/' + path.split('/m')[1]
            this.$router.push(path)
          }
        } else {
          if (path.split('/')[1].charAt(0) !== 'm') {
            path = '/m' + path.split('/')[1]
            this.$router.push(path)
          }
        }
      },
      immediate: true
    }
  },
  created () {

  }
}
</script>

<style lang="scss">
@import url("@/assets/font/font.css");
.amap-geolocation-con {
  z-index: 2 !important;
}
.amap-logo {
  z-index: 2 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.nr {
  text-align: start;
  padding: 2.333vw 11vw;
  font-size: 3.2vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #003371;
  line-height: 4.533vw;
  img {
    width: 80vw;
    height: 48vw;
  }
}
.wb {
  width: 100%;
  background: rgba(0, 52, 114, 0.1);
}
.intro {
  > div {
    margin: 5.125vw 0;
  }
  > div:nth-child(2n) {
    display: flex;
    position: relative;
    width: 100%;
    height: 21.667vw;
    border: 0.833vw solid #ffc11f;
    align-items: center;
    > div:nth-child(3) {
      position: absolute;
      right: -0.833vw;
      z-index: 2;
      top: -2.72vw;
      width: 22vw;
      height: 25.833vw;
      background: #d8d8d8;
    }
    > div:nth-child(2) {
      width: 22.604vw;
      height: 100%;
    }
    > div:nth-child(1) {
      margin: 0 auto;
      padding: 0 1.042vw;
      text-align: start;
      width: 55.563vw;
      font-size: 1.25vw;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #003371;
      line-height: 2.5vw;
    }
  }
  > div:nth-child(2n-1) {
    display: flex;
    position: relative;
    width: 100%;
    height: 21.667vw;
    border: 0.833vw solid #ffc11f;
    align-items: center;
    > div:nth-child(1) {
      position: absolute;
      left: -0.833vw;
      z-index: 2;
      top: -2.72vw;
      width: 22.604vw;
      height: 25.833vw;
      background: #d8d8d8;
    }
    > div:nth-child(2) {
      width: 23.604vw;
      height: 100%;
    }
    > div:nth-child(3) {
      padding: 0 1.042vw;
      margin: 0 auto;
      text-align: start;
      width: 55.563vw;
      font-size: 1.25vw;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #003371;
      line-height: 2.5vw;
    }
  }
}

@media screen and (max-width: 1440px) {
  .detail {
    > div {
      padding: 1.667vw 0;
      border-top: 0.533vw solid rgba(0, 52, 114, 0.1);
    }
    .cs {
      text-align: start;
      .cs_item {
        display: flex;
        width: 100%;
        margin: 2.667vw 0;
        > div:nth-child(1) {
          margin-left: 5.333vw;
          width: 26.667vw;
          height: 4.533vw;
          font-size: 3.2vw;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #003371;
          line-height: 4.533vw;
        }
        > div:nth-child(2) {
          width: 40vw;
          height: 4.533vw;
          font-size: 3.2vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 51, 113, 0.8);
          line-height: 4.533vw;
        }
      }
      .cs_title {
        font-size: 4.267vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #003371;
        line-height: 5.867vw;
      }
    }
    .feature {
      text-align: start;
      display: flex;
      justify-content: space-between;
      .feature_left {
        width: 14.933vw;
        height: 5.333vw;
        font-size: 3.733vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #003371;
        line-height: 5.333vw;
      }
      .feature_right {
        > div {
          width: 66.667vw;
          // height: 9.067vw;
          font-size: 3.2vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #003371;
          line-height: 4.533vw;
          margin-bottom: 2.667vw;
        }
      }
    }
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .detail {
    .detail_item {
      border-bottom: 0.208vw solid rgba(0, 51, 113, 0.05);
      text-align: start;
      width: 100%;
      padding: 4.167vw 0;
      display: flex;
      > div:nth-child(2) {
        .cs {
          margin-top: 2.083vw;
          display: flex;

          > div:nth-child(2) {
            width: 37.958vw;
            font-size: 1.875vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 51, 113, 0.8);
            line-height: 2.604vw;
          }
          > div:nth-child(1) {
            width: 9.375vw;
            // height: 2.604vw;
            font-size: 1.875vw;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: medium;
            color: #003371;
            line-height: 2.604vw;
            margin-right: 6.51vw;
          }
        }
        .cs:nth-child(1) {
          margin-top: 0;
        }
      }
      > div:nth-child(1) {
        width: 20.833vw;
        font-size: 2.5vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: medium;
        color: #003371;
        line-height: 3.49vw;
      }
    }
    .detail_item:nth-last-child(1) {
      margin-bottom: 2.083vw;
    }
    .ts {
      > div:nth-child(1) {
        font-size: 1.875vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #003371;
        line-height: 2.604vw;
      }
      > div:nth-child(2) {
        display: flex;
        text-align: start;
        .left {
          margin-top: 2.083vw;
          // width: 23.833vw;
          width: 10.833vw;
          height: 3.49vw;
          font-size: 2.5vw;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: medium;
          color: #003371;
          line-height: 3.49vw;
        }
        .right {
          flex: 1;
          div {
            padding-left: 8.333vw;
            font-size: 1.875vw;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: medium;
            color: #003371;
            line-height: 2.604vw;
            margin-top: 2.083vw;
          }
          > div:nth-last-child(1) {
            padding-bottom: 4.167vw;
          }
        }
      }
      border-bottom: 0.208vw solid rgba(0, 51, 113, 0.05);
    }
    border-top: 0.208vw solid rgba(0, 51, 113, 0.05);
    width: 100%;
    padding-top: 2.083vw;
    marggin: 0 10.417vw;
  }
}
.solve {
  //手机端
  @media screen and (max-width: 1440px) {
    width: 89.333vw;
    border-radius: 2.133vw;
    overflow: hidden;

    height: 32vw;
    // background: url(@/static/img/bg3.png);
    position: relative;
    margin: 0 auto;
    .scheme {
      width: 89.333vw;
      margin: 0 auto;
      height: 16.533vw;
      position: absolute;
      top: 11.399vw;
      > div {
        width: 80vw;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        > div {
          width: 8.5vw;
          height: 8.7vw;
          background: url("@/static/img/dbx.png") no-repeat;
          background-size: 100% 100%;
          color: #fff;
          position: relative;
          i,
          em {
            font-size: 2.667vw;
          }
          font-size: 12px;
        }
        > div:nth-child(1) {
          // left: 268.8px;
        }
        > div:nth-child(2n) {
          background: none;
        }
        > div:nth-child(2n-1) {
          i,
          em {
            position: absolute;
            top: 2vw;
            left: 2.5vw;
          }
          > div:nth-child(2) {
            position: absolute;
            left: -1.84vw;
            top: 9.667vw;
            font-size: 3.2vw;
            width: 12.8vw;
          }
        }
        > div:nth-child(2) {
          // left: 422.4px;
          top: 2.667vw;
        }
        > div:nth-child(3) {
          // left: 576px;
        }
        > div:nth-child(4) {
          // left: 729.6px;
          top: 2.667vw;
        }
        > div:nth-child(5) {
          // left: 883.2px;
        }
        > div:nth-child(6) {
          // left: 1036.8px;
          top: 2.667vw;
        }
        > div:nth-child(7) {
          // left: 1190.4px;
        }
        > div:nth-child(8) {
          // left: 1324.8px;
          top: 2.667vw;
        }
        > div:nth-child(9) {
          // left: 1497.6px;
        }
      }
    }

    .three {
      position: absolute;
      top: 0;
    }
    .title {
      position: absolute;
      top: 0;
      height: 90.01px;
      width: 89.333vw;

      div:nth-child(1) {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: 2.667vw;
        width: 68.267vw;
        height: 5.867vw;
        font-size: 4.267vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fff;
        z-index: 3;
      }
      div:nth-child(2) {
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 68.267vw;
        height: 2vw;
        font-size: 4.267vw;
        font-family: DIN-MediumAlternate, DIN;
        font-weight: normal;
        color: rgb(68, 122, 154);
        line-height: 58.003px;
      }
    }
    #mom {
      // height: 540px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.1;
    }
  }
  @media screen and (min-width: 1440px) {
    min-width: 75vw;
    width: 100%;

    height: 28.126vw;
    background: url(@/static/img/bg3.png);
    position: relative;
    button {
      position: absolute;
      border: none;
      bottom: 2.604vw;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0vw 0vw 0.208vw 0vw #003472;
      border-radius: 0.417vw;
      width: 7.5vw;
      height: 2.604vw;
      font-size: 1.25vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
      line-height: 2.604vw;
      i {
        font-size: 1.354vw;
      }
    }
    .scheme {
      width: 100%;
      height: 10vw;
      position: absolute;
      top: 9.531vw;
      > div {
        width: 75vw;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        > div {
          width: 7.292vw;
          height: 8.333vw;
          background: url("@/static/img/dbx.png") no-repeat;
          background-size: 100% 100%;
          color: #fff;
          position: relative;
          i,
          em {
            font-size: 3.125vw;
          }
          font-size: 1.354vw;
        }
        > div:nth-child(1) {
          // left: 14vw;
        }
        > div:nth-child(2n) {
          background: none;
        }
        > div:nth-child(2n-1) {
          i,
          em {
            position: absolute;
            top: 2vw;
            left: 2vw;
          }
          > div:nth-child(2) {
            position: absolute;
            left: 1vw;
            top: 9vw;
          }
        }
        > div:nth-child(2) {
          // left: 22vw;
          top: 2vw;
        }
        > div:nth-child(3) {
          // left: 30vw;
        }
        > div:nth-child(4) {
          // left: 38vw;
          top: 2vw;
        }
        > div:nth-child(5) {
          // left: 46vw;
        }
        > div:nth-child(6) {
          // left: 54vw;
          top: 2vw;
        }
        > div:nth-child(7) {
          // left: 62vw;
        }
        > div:nth-child(8) {
          // left: 69vw;
          top: 2vw;
        }
        > div:nth-child(9) {
          // left: 78vw;
        }
      }
    }

    .three {
      position: absolute;
      top: 0;
    }
    .title {
      position: absolute;
      top: 0;
      height: 4.688vw;
      width: 100%;
      div:nth-child(1) {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: 2.083vw;
        width: 53.333vw;
        height: 4.688vw;
        font-size: 3.333vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fff;
        z-index: 3;
        line-height: 4.688vw;
      }
      div:nth-child(2) {
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 4.583vw;
        transform: translateX(-50%);
        width: 25.083vw;
        height: 3.021vw;
        font-size: 2.5vw;
        font-family: DIN-MediumAlternate, DIN;
        font-weight: normal;
        color: rgb(68, 122, 154);
        line-height: 2.5vw;
      }
    }
    #mom {
      height: 28.125vw;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.1;
    }
  }
}
.aptitude {
  .items {
    position: absolute;
    top: 9.531vw;
    // z-index: 1;

    left: 50%;
    // margin: 0 auto;
    transform: translateX(-50%);
    background-color: #fff;
    width: 75vw;
    height: 37.5vw;
    display: flex;
    flex-wrap: wrap;
    .item:nth-child(1) {
      .img {
        background: url(@/static/img/q1.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .item:nth-child(2) {
      > div:nth-child(1) {
        > div:nth-child(1) {
          font-size: 5vw;
          color: #003472;
        }
      }
      span {
        font-weight: 0;
        font-size: 2.5vw;
      }
      .img {
        background: url(@/static/img/q2.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .item:nth-child(3) {
      .img {
        background: url(@/static/img/q3.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .item:nth-child(4) {
      .img {
        background: url(@/static/img/q4.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .item {
      width: 37.5vw;
      height: 18.75vw;
      display: flex;
      > div {
        width: 50%;
        height: 100%;
      }
      > div:nth-child(1) {
        color: #003371;
        font-size: 2.5vw;
        > div:nth-child(1) {
          margin-top: 5.208vw;
        }
        // > div:nth-child(2) {
        //   margin-top: 5.208vw;
        // }
      }
      > div:nth-child(2) {
        color: #003371;
        font-size: 2.5vw;

        > div:nth-child(1) {
          margin-top: 5.208vw;
        }
        // > div:nth-child(2) {
        //   margin-top: 5.208vw;
        // }
      }
      span {
        font-size: 5vw;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #003472;
        line-height: 6.094vw;
      }
    }
  }
  > div:nth-child(1) {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 33.333vw;
    background: url(@/static/img/qyzzbg.png) no-repeat;
    background-size: 100% 100%;
  }
  position: relative;
  width: 100%;
  min-width: 75vw;

  .title1 {
    div:nth-child(2) {
      color: rgba(255, 255, 255, 0.2);
    }
  }
  height: 53.073vw;
  background: #f2f5f8;
}
.about_us_center {
  width: 75vw;
  margin: 0 auto;
  display: flex;
  text-align: start;
  > div:nth-child(1) {
    width: 23.958vw;
    position: relative;
    padding: 5.5vw 0 4.688vw 2.604vw;
    > div:nth-child(1) {
      width: 10vw;
      height: 3.49vw;
      font-size: 2.5vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: medium;
      color: #003472;
      line-height: 3.49vw;
    }
    > div:nth-child(2) {
      margin-top: 1.042vw;
      width: 6.25vw;
      height: 0.417vw;
      background: #003472;
    }
    > div:nth-child(3) {
      margin-top: 2.448vw;

      height: 1.875vw;
      font-size: 1.875vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #003472;
      line-height: 1.875vw;
      span {
        width: 10.469vw;
        height: 1.51vw;
        font-size: 1.25vw;
        font-family: DIN-MediumAlternate, DIN;
        font-weight: normal;
        color: #003472;
        line-height: 1.51vw;
      }
    }
    > div:nth-child(4) {
      margin-top: 2.083vw;

      height: 1.875vw;
      font-size: 1.875vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #003472;
      line-height: 1.875vw;
      span {
        height: 1.51vw;
        font-size: 1.25vw;
        font-family: DIN-MediumAlternate, DIN;
        font-weight: normal;
        color: #003472;
        line-height: 1.51vw;
      }
    }
    > div:nth-child(5) {
      margin-top: 2.083vw;

      height: 1.875vw;
      font-size: 1.875vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #003472;
      line-height: 1.875vw;
      span {
        width: 10.469vw;
        height: 1.51vw;
        font-size: 1.25vw;
        font-family: DIN-MediumAlternate, DIN;
        font-weight: normal;
        color: #003472;
        line-height: 1.51vw;
      }
    }
    > div:nth-child(6) {
      position: absolute;
      left: -1.042vw;
      top: 1.542vw;
      width: 4.167vw;
      height: 1.354vw;
      background: url(@/static/img/zs.png) no-repeat;
    }
    > div:nth-child(7) {
      position: absolute;
      left: -2.542vw;
      bottom: 1.542vw;
      width: 9.635vw;
      height: 9.635vw;
      background: url(@/static/img/zx.png) no-repeat;
    }
    > div:nth-child(8) {
      position: absolute;
      left: 2.42vw;
      bottom: 8.542vw;
      a {
        color: #003472;
        font-size: 1.25vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-decoration: none;
        i {
          color: #003472;
          font-size: 1.5vw;
        }
      }
    }
  }
  > div:nth-child(2) {
    > div:nth-child(1) {
      height: 25vw;
      background: url(@/static/img/lbt1.png) no-repeat;
      background-size: 100% 100%;
    }
    > div:nth-child(2) {
      margin-top: 2.167vw;
      width: 100%;
      font-size: 1.25vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
      line-height: 1.719vw;
    }
    width: 41.667vw;
    position: relative;
    padding: 1.563vw 0;
  }
  > div:nth-child(3) {
    position: relative;
    > div:nth-child(1) {
      width: 2.083vw;
      height: 20.833vw;
      font-size: 1.875vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: medium;
      color: rgb(121, 148, 181);
      line-height: 2.604vw;
    }
    > div:nth-child(2) {
      left: 4.688vw;
      margin-left: 0.5vw;
      width: 2.083vw;
      height: 15.625vw;
      font-size: 1.875vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: medium;
      color: rgb(121, 148, 181);
      line-height: 2.604vw;
      position: absolute;
      z-index: 3;
    }
    > div:nth-child(3) {
      position: absolute;
      z-index: 2;
      left: -2.2vw;
      top: 10.417vw;
      transform: rotateZ(90deg);
      height: 3.25vw;
      font-size: 2.5vw;
      font-family: DIN-BoldAlternate, DIN;
      font-weight: normal;
      color: rgb(217, 225, 234);
      line-height: 3.073vw;
      letter-spacing: 0.417vw;
    }
    flex: 1;
    position: relative;
    padding: 1.563vw 0 1.563vw 2.604vw;
    display: flex;
  }
  // padding: 10.083vw 0 0 0;
  padding: 7.813vw 0 0 0;

  height: 100%;
}
</style>
