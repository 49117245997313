import Vue from 'vue'
import { getSEO } from '@/api/homeApi'
import VueRouter from 'vue-router'
import HomePageContent from '@/components/HomePageContent.vue'

import errorView from '@/views/errorView.vue'
import merrorView from '@/views/iphone/errorView.vue'

import HomeView from '@/views/HomeView.vue'
import Journalism from '@/components/JournalismVue.vue'
import ProductCenter from '@/components/ProductCenter.vue'
import CorporateInFormation from '@/components/CorporateInFormation.vue'
import CooperationVue from '@/components/CooperationVue.vue'
import SolutionVue from '@/components/SolutionVue.vue'

import mHomeView from '@/views/iphone/mHomeView.vue'
import mHomePageContent from '@/components/iphone/mHomePageContent.vue'
import mEnterprise from '@/components/iphone/mEnterprise.vue'
import mProductCenter from '@/components/iphone/mProductCenter.vue'
import mJournalism from '@/components/iphone/mJournalism.vue'
import mCooperation from '@/components/iphone/mCooperation.vue'
import mSolutionVue from '@/components/iphone/mSolutionVue.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/error',
    component: errorView

  },
  {
    path: '/merror',
    component: merrorView
  },
  {
    path: '/',
    component: HomeView,
    redirect: '/welcome',
    children: [
      {
        path: '/welcome',
        component: HomePageContent,
        meta: {
          metaInfo: {
            title: '首页',
            keywords: '这是网页的关键词',
            description: '这是网页的描述'
          }
        }

      },
      {
        path: '/solution',
        component: SolutionVue
      },
      {
        path: '/journalism',
        component: Journalism,
        meta: {
          metaInfo: {}
        }
      },
      {
        path: '/productcenter',
        component: ProductCenter,
        meta: {
          metaInfo: {}
        }
      },
      {
        path: '/corporateinformation',
        component: CorporateInFormation,
        meta: {
          metaInfo: {}
        }
      }, {
        path: '/cooperation',
        component: CooperationVue,
        meta: {
          metaInfo: {}
        }
      }

    ]

  }, {
    path: '/m',
    redirect: '/mwelcome',
    component: mHomeView,
    children: [
      {
        path: '/mwelcome',
        component: mHomePageContent,
        meta: {
          metaInfo: {}
        }
      },
      {
        path: '/msolution',
        component: mSolutionVue
      },
      {
        path: '/mcorporateinformation',
        component: mEnterprise,
        meta: {
          metaInfo: {}
        }
      },
      {
        path: '/mproductCenter',
        component: mProductCenter,
        meta: {
          metaInfo: {}
        }
      },
      {
        path: '/mcooperation',
        component: mCooperation,
        meta: {
          metaInfo: {}
        }
      },
      {
        path: '/mjournalism',
        component: mJournalism,
        meta: {
          metaInfo: {}
        }

      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const el = document.getElementById('navbarSupportedContent')
  if (el) {
    el.className = 'navbar-collapse collapse'
  }

  if (to.fullPath === '/welcome' || to.fullPath === '/mwelcome') {
    getSEO({ navId: -1 }).then((data) => {
      to.meta.metaInfo = data.data.seo
      const head = document.getElementsByTagName('head')
      const keywords = document.getElementsByName('Keywords')
      const description = document.getElementsByName('Description')
      const ArticleTitle = document.getElementsByName('ArticleTitle')
      const Author = document.getElementsByName('Author')
      if (keywords.length === 0) {
        head[0].innerHTML = head[0].innerHTML + '<meta name="Keywords" content="' + to.meta.metaInfo.seo_keywordstags + '"></meta>' + '<meta name="Description" content="' + to.meta.metaInfo.seo_description + '"></meta>'
      } else {
        keywords.forEach(item => {
          head[0].removeChild(item)
        })
        description.forEach(item => {
          head[0].removeChild(item)
        })
        ArticleTitle.forEach(item => {
          head[0].removeChild(item)
        })
        Author.forEach(item => {
          head[0].removeChild(item)
        })
        head[0].innerHTML = head[0].innerHTML + '<meta name="Keywords" content="' + data.data.seo.seo_keywordstags + '"></meta>' + '<meta name="Description" content="' + data.data.seo.seo_description + '"></meta>'
      }
      document.title = to.meta.metaInfo.seo_title
    })
  }

  if (to.query.fid) {
    getSEO({ navId: to.query.fid }).then((data) => {
      to.meta.metaInfo = data.data.seo
      const head = document.getElementsByTagName('head')
      const keywords = document.getElementsByName('Keywords')
      const description = document.getElementsByName('Description')
      const ArticleTitle = document.getElementsByName('ArticleTitle')
      const Author = document.getElementsByName('Author')
      if (keywords.length === 0) {
        head[0].innerHTML = head[0].innerHTML + '<meta name="Keywords" content="' + to.meta.metaInfo.seo_keywordstags + '"></meta>' + '<meta name="Description" content="' + to.meta.metaInfo.seo_description + '"></meta>'
      } else {
        keywords.forEach(item => {
          head[0].removeChild(item)
        })
        description.forEach(item => {
          head[0].removeChild(item)
        })
        ArticleTitle.forEach(item => {
          head[0].removeChild(item)
        })
        Author.forEach(item => {
          head[0].removeChild(item)
        })

        head[0].innerHTML = head[0].innerHTML + '<meta name="Keywords" content="' + data.data.seo.seo_keywordstags + '"></meta>' + '<meta name="Description" content="' + data.data.seo.seo_description + '"></meta>'
      }
      document.title = to.meta.metaInfo.seo_title
    })
  }

  next()
})
export default router

// import ProductCenterList from '@/components/ProductCenterList.vue'
// import ProductCenterDetails from '@/components/ProductCenterDetails.vue'
