<template>
  <div v-if="flag">
    <!-- 轮播图部分 -->
    <div class="slideshow1">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <template v-for="(item, index) in banner">
            <button
              v-if="index"
              :key="item.title"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              :data-bs-slide-to="index"
              aria-current="true"
              :aria-label="`Slide ` + (index + 1)"
            ></button>
          </template>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="lbt-content">
              <p>{{ banner[0].title }}</p>
              <p>{{ banner[0].en }}</p>
              <div v-html="banner[0].brief"></div>
              <!-- <p>banner标题5vw medium</p>
              <p>查看详情1.875vw简介这是部分…</p> -->
              <button
                v-if="banner[0].switch"
                @click.stop="open1(banner[0].link)"
              >
                查看详情
                <i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
              </button>
            </div>
            <img :src="banner[0].image" class="d-block w-100" />
          </div>
          <template v-for="(item, index) in banner">
            <div class="carousel-item" :key="item.title" v-if="index">
              <div class="lbt-content">
                <p>{{ item.title }}</p>
                <p>{{ item.en }}</p>
                <div v-html="item.brief"></div>
                <button v-if="item.switch" @click.stop="open1(item.link)">
                  查看详情
                  <i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
                </button>
              </div>
              <img :src="item.image" class="d-block w-100" />
            </div>
          </template>
        </div>
        <button class="carousel-control-prev" type="button">
          <span
            class="carousel-control-prev-icon"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button">
          <span
            class="carousel-control-next-icon"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!-- 产品中心 -->
    <div class="product_center">
      <div class="product_center_top">
        <div>产品中心</div>
        <div>PRODUCT CENTER</div>
      </div>
      <div class="product_center_center">
        <div>
          <div style="transition: all 3">{{ "0" + (dot_active + 1) }}</div>
          <div>
            <!-- 产品中心上一页按钮 -->
            <div
              :style="dot_active != 0 ? 'background:rgb(0,52,114)' : ''"
              @click="
                () => {
                  if (dot_active == 0) return;
                  dot_active--;
                  slideshow();
                }
              "
            >
              <i
                class="icon-qiehuanqizuo iconfont"
                :style="dot_active != 0 ? 'color:rgb(255,255,255)' : ''"
              ></i>
            </div>
            <!-- 产品中心下一页按钮 -->
            <div
              class="active_jt"
              :style="
                dot_active == 6 ? 'background:rgb(217,225,235) !important' : ''
              "
              @click="
                () => {
                  if (dot_active == 6) return;
                  dot_active++;
                  slideshow();
                }
              "
            >
              <i
                class="icon-qiehuanqizuo iconfont"
                :style="dot_active == 6 ? 'color:rgb(44,62,80)' : ''"
              ></i>
            </div>
          </div>
        </div>
        <!-- 产品中心中间区域 -->
        <div class="centre_centre">
          <div class="left">
            <div>
              <div class="title">
                <div>{{ productList[dot_active * 3].title }}</div>
                <div></div>
              </div>
              <div class="content">
                {{ productList[dot_active * 3].introduce }}
              </div>
              <!-- 按钮 -->
              <button @click="toPro(productList[dot_active * 3].id)">
                查看详情
                <i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
              </button>
            </div>
            <!-- 右边的图 -->
            <div>
              <div>
                {{
                  productList[dot_active * 3].en || "Intelligent Rope Skipping"
                }}
              </div>
              <img
                :src="
                  productList[dot_active * 3].image ||
                  '@/static/img/product/便携式数据处理终端.png'
                "
              />
            </div>
          </div>
          <div class="right">
            <div>
              <div>
                <div class="title">
                  {{ productList[dot_active * 3 + 1].title || "坐位体前屈" }}
                </div>
                <!-- <div class="title">
                  {{ productList[1].tags || "智能测试仪" }}
                </div> -->
                <div></div>
                <button @click="toPro(productList[dot_active * 3 + 1].id)">
                  查看详情
                  <i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
                </button>
              </div>
              <img
                :src="
                  productList[dot_active * 3 + 1].image ||
                  '@/static/img/product/便携式数据处理终端.png'
                "
              />
            </div>
            <div>
              <div>
                <div class="title">
                  {{ productList[dot_active * 3 + 2].title || "短跑" }}
                </div>
                <!-- <div class="title">
                  {{ productList[2].title || "智能测试仪" }}
                </div> -->
                <div></div>
                <button @click="toPro(productList[dot_active * 3 + 2].id)">
                  查看详情
                  <i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
                </button>
              </div>
              <img
                :src="
                  productList[dot_active * 3 + 2].image ||
                  '@/static/img/product/便携式数据处理终端.png'
                "
              />
            </div>
          </div>
        </div>
        <!-- 产品右侧轮播点 -->
        <div class="right_dot">
          <div
            :class="
              dot_active == 0
                ? 'dot_item dot_item_active'
                : dot_active > 0
                ? 'dot_item dot_item_active1'
                : 'dot_item'
            "
          >
            <div
              @click="
                () => {
                  dot_active = 0;
                  slideshow();
                }
              "
            >
              <div></div>
              <div></div>
              <div></div>
              <div><i class="iconfont icon-xiangxiajiantou"></i></div>
              <div><i class="iconfont icon-xiangxiajiantou"></i></div>
              <div><i class="iconfont icon-xiangxiajiantou"></i></div>
            </div>
          </div>
          <div
            :class="
              dot_active == index
                ? 'dot_item dot_item_active'
                : dot_active > index
                ? 'dot_item dot_item_active1'
                : 'dot_item'
            "
            v-for="index in 5"
            :key="index"
          >
            <div
              @click="
                () => {
                  dot_active = index;
                  slideshow();
                }
              "
            >
              <div></div>
              <div></div>
              <div></div>
              <div><i class="iconfont icon-xiangxiajiantou"></i></div>
              <div><i class="iconfont icon-xiangxiajiantou"></i></div>
              <div><i class="iconfont icon-xiangxiajiantou"></i></div>
            </div>
          </div>
          <div
            :class="
              dot_active == 6
                ? 'dot_item dot_item_active'
                : dot_active > 6
                ? 'dot_item dot_item_active1'
                : 'dot_item'
            "
          >
            <div
              @click="
                () => {
                  dot_active = 6;
                  slideshow();
                }
              "
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="product_botton">
        <a href=""
          >More<i class="iconfont icon-yangshi_icon_tongyong_forward"></i
        ></a>
      </div>
    </div>
    <!-- 解决方法 -->
    <div class="solve">
      <div id="three"></div>
      <three style="width: 100%; height: 100%" class="three"></three>
      <canvas id="mom" style="background: rgba(0, 0, 0, 0)"></canvas>
      <span v-html="serviceData"></span>
      <!-- HTML模板,对应样式放在了App.vue中 -->
      <!-- <div class="title">
        <div>真正的全渠道智能客户体验解决方案</div>
        <div>Customer Solutions</div>
      </div> -->
      <!-- <div class="scheme">
        <div>
          <div>
            <div><i class="iconfont icon-tijiao"></i></div>
            <div>资料提交</div>
          </div>
          <div>
            <i class="iconfont icon-jiantou2"></i>
          </div>
          <div>
            <div><i class="iconfont icon-woshou"></i></div>
            <div>商务沟通</div>
          </div>
          <div>
            <i class="iconfont icon-jiantou2"></i>
          </div>
          <div>
            <div>
              <i class="iconfont icon-ico_xibaoyinhang_zhongzhipinduijie"></i>
            </div>
            <div>技术对接</div>
          </div>
          <div>
            <i class="iconfont icon-jiantou2"></i>
          </div>
          <div>
            <div><i class="iconfont icon-shebeikaifa"></i></div>
            <div>产品开发</div>
          </div>
          <div>
            <i class="iconfont icon-jiantou2"></i>
          </div>
          <div>
            <div><i class="iconfont icon-shangxian"></i></div>
            <div>产品上线</div>
          </div>
        </div>
      </div> -->
      <button
        @click.stop="
          aside_active === 5 ? (aside_active = '') : (aside_active = 5)
        "
      >
        合作咨询
        <i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
      </button>
    </div>
    <!-- 关于我们 -->
    <div class="about_us">
      <div class="about_us_top">
        <div>关于我们</div>
        <div>AOUBS US</div>
      </div>
      <span v-html="aboutData"></span>
    </div>
    <!-- 企业资质 -->
    <div class="aptitude">
      <div></div>
      <div class="title1">
        <div>企业资质</div>
        <div>ENTERPRISE QUALIFICATION</div>
      </div>
      <span v-html="qualificationsData"></span>
      <!-- <div class="items">
        <div class="item">
          <div>
            <div><span>5</span>项</div>
            <div>信用证书认证</div>
          </div>
          <div class="img"></div>
        </div>
        <div class="item">
          <div>
            <div>30<span>万+</span></div>
            <div>产品服务人次</div>
          </div>
          <div class="img"></div>
        </div>
        <div class="item">
          <div class="img"></div>
          <div>
            <div><span>27</span>项</div>
            <div>专利证书认证</div>
          </div>
        </div>
        <div class="item">
          <div class="img"></div>
          <div>
            <div><span>28</span>家</div>
            <div>战略合作伙伴</div>
          </div>
        </div>
      </div> -->
      <div class="More" @click.stop="toCor">
        <a href="#"
          >More<i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
        </a>
      </div>
    </div>
    <!-- 合作案例 -->
    <div class="cooperation_case">
      <div class="title1">
        <div>合作案例</div>
        <div>CASE OF COOPERATION</div>
      </div>
      <div class="cooperation_case_content">
        <template v-for="(item, index) in casesData">
          <img :src="item.image" v-if="index <= 16" :key="index" />
        </template>
      </div>
      <div class="More" @click.stop="toCoo">
        <a href="#"
          >More<i class="iconfont icon-yangshi_icon_tongyong_forward"></i
        ></a>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="journalism">
      <div class="title1">
        <div>新闻资讯</div>
        <div>NEWS TNT ORMATION</div>
      </div>
      <div class="journalism_content">
        <div
          class="journalism_left"
          @click="toJou(newsList[0].id, newsList[0].navId)"
        >
          <img :src="newsList[0].image" class="journalism_left_top" />
          <div class="journalism_left_card">
            <div>
              <div class="card_item">智慧</div>
              <div class="card_item">体育</div>
              <div class="card_item">AI智能</div>
            </div>
            <div>{{ newsList[0].createtime.split(" ")[0] }}</div>
          </div>
          <div class="journalism_left_content">
            <h2>{{ newsList[0].title || "书雷智慧体育解决方案" }}</h2>
            <div>
              {{
                newsList[0].briefly ||
                " 以书雷智体平台作为核心，提供应用于多种场景的AI智慧教学解决方案，包括：智慧教学、电子备课系统、心率检测、风险干预、智慧体测、家校互动、动作识别系统等智慧体育教学服务解决方案；具有智能体测设备、心率臂带、跳绳、网关等多款、网关等多款、网关等多款、网关等多款智能…"
              }}
            </div>
            <div>>>></div>
          </div>
        </div>
        <div
          class="journalism_right"
          @scroll="scrollY"
          id="scrollY"
          @mouseover="yr"
          @mouseout="yc"
        >
          <template v-for="(item, index) in newsList">
            <div
              v-if="index"
              class="journalism_right_item"
              @click="toJou(item.id, item.navId)"
              :key="index"
            >
              <div class="journalism_right_item_left">
                <div>
                  {{ newsList[0].createtime.split(" ")[0].split("-")[2] }}
                </div>
                <div></div>
                <div>
                  {{ newsList[0].createtime.split(" ")[0].split("-")[1] }}/{{
                    newsList[0].createtime.split(" ")[0].split("-")[0]
                  }}
                </div>
              </div>
              <div class="journalism_right_item_right">
                <h3>{{ item.title || "书雷智慧体育解决方案" }}</h3>
                <div>
                  {{
                    item.briefly ||
                    " 以书雷智体平台作为核心，提供应用于多种场景的AI智慧教学解决方案，包括：智慧教学、电子备课系统、心率检测、风险干预、智慧体测、家校互动、动作识别系统等智慧体育教学服务解决方案；具有智能体测设备、心率臂带、跳绳、网关等多款、网关等多款、网关等多款、网关等多款智能…"
                  }}
                </div>
                <div>>>></div>
              </div>
            </div>
          </template>
        </div>
        <div class="More" @click.stop="toJou1">
          <a href="#"
            >More<i class="iconfont icon-yangshi_icon_tongyong_forward"></i
          ></a>
        </div>
      </div>
    </div>
    <!-- 侧边栏 -->
    <div class="aside">
      <div
        style="display: none"
        :class="aside_active === 0 ? 'active' : ''"
        @click="aside_active === 0 ? (aside_active = '') : (aside_active = 0)"
      >
        <i class="icon-changyonglogo37 iconfont"></i>
        <div>App下载</div>
        <div class="ewm" v-show="aside_active === 0">
          <img :src="site.gongzhongh" />
        </div>
      </div>
      <div
        :class="aside_active == 1 ? 'active' : ''"
        @click="aside_active === 1 ? (aside_active = '') : (aside_active = 1)"
      >
        <i class="icon-weixingongzhonghao iconfont"></i>
        <div>微信公众号</div>
        <div class="ewm" v-show="aside_active === 1">
          <img :src="site.gongzhongh" />
        </div>
      </div>
      <div
        :class="aside_active == 2 ? 'active' : ''"
        @click="aside_active === 2 ? (aside_active = '') : (aside_active = 2)"
      >
        <i class="icon-xiaochengxu iconfont"></i>
        <div>微信小程序</div>
        <div class="ewm" v-show="aside_active === 2">
          <img :src="site.xiaochengxu" />
        </div>
      </div>
      <div
        :class="aside_active == 3 ? 'active' : ''"
        @click="aside_active === 3 ? (aside_active = '') : (aside_active = 3)"
      >
        <i class="icon-weixin iconfont"></i>
        <div>微信</div>
        <div class="ewm" v-show="aside_active === 3">
          <img :src="site.Wx" />
        </div>
      </div>
      <div @click="toPhone('/m')">
        <i class="icon-shouji iconfont"></i>
        <div>手机官网</div>
      </div>
      <div
        :class="aside_active == 5 ? 'active' : ''"
        @click="aside_active === 5 ? (aside_active = '') : (aside_active = 5)"
      >
        <i class="icon-zaixiankefux iconfont"></i>
        <div>在线咨询</div>
      </div>
      <div
        @click="
          () => {
            (aside_active = 6), topFunction();
          }
        "
      >
        <i class="icon-icon-test iconfont"></i>
        <div>回到顶部</div>
      </div>
      <div class="minwindow" v-if="aside_active == 5">
        <div class="title">
          <span>山东指南针体育产业有限公司</span>
          <span><i class="iconfont icon-liuyanmoban"></i></span>
          <span><i class="iconfont icon-weixin active"></i></span>
          <span @click="aside_active = ''">
            <i class="iconfont icon-guanbi"></i
          ></span>
        </div>
        <div class="content">
          <div class="content_title">在线留言</div>
          <div>
            <div>
              <span>您的名字:</span
              ><input type="text" v-model="leave_msg.name" />
            </div>
            <div>
              <span>您的电话:</span
              ><input type="text" v-model="leave_msg.phone" />
            </div>
            <div>
              <span>公司名称:</span
              ><input type="text" v-model="leave_msg.company" />
            </div>
            <div>
              <span>您的邮箱:</span
              ><input type="text" v-model="leave_msg.Email" />
            </div>
            <div>
              <span>公司地址:</span
              ><input type="text" v-model="leave_msg.address" />
            </div>
            留言:
            <textarea cols="60" rows="10" v-model="leave_msg.msg"></textarea>
            <!-- <div><span>验证码:</span><input type="text" id="input1" /></div> -->
            <!-- <div>
              <input
                type="button"
                id="checkCode"
                class="code"
                style="width: 3.125vw"
                @click="createCode"
              />
            </div> -->
            <button @click="leaveMessage">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else style="width: 100%; height: 1920px">
    <!-- 未获取到数据展示的轮播图部分 -->
    <div class="slideshow1">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <template v-for="(item, index) in banner">
            <button
              v-if="index"
              :key="item.title"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              :data-bs-slide-to="index"
              aria-current="true"
              :aria-label="`Slide ` + (index + 1)"
            ></button>
          </template>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="lbt-content">
              <p>教练赛系统</p>
              <p></p>
            </div>
            <img src="@/static/img/lbt1.png" class="d-block w-100" />
          </div>
        </div>
        <button class="carousel-control-prev" type="button">
          <span
            class="carousel-control-prev-icon"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button">
          <span
            class="carousel-control-next-icon"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import three from '@/components/three.vue'
import { getAbout, getService, getIndexBanner, getQualifications, getBanner, getSite, getProductList, getNewsList, getCases, leaveMessage } from '@/api/homeApi.js'

let code
export default {
  data () {
    return {
      leave_msg: {
        name: '',
        phone: '',
        company: '',
        address: '',
        msg: ''
      },
      flag: false,
      start: '',
      timer2: null,
      timer3: null,
      dot_active: 0,
      aside_active: '',
      aboutData: {},
      serviceData: {},
      indexBannerData: {},
      qualificationsData: {},
      site: {},
      productList: {},
      newsList: [],
      casesData: [],
      canvas: '',
      tc: false,
      banner: [{
        image: 'https://zhinanzhen.shuleizhiti.com/uploads/20221130/cb87a5792ab166e1911042aa48df6a41.png',
        title: '首页1',
        en: '首页1',
        brief: '<div>首页1<br/>12312123123123</div>',
        switch: 0,
        link: ''
      },
      {
        image: 'https://zhinanzhen.shuleizhiti.com/uploads/20221130/cb87a5792ab166e1911042aa48df6a41.png',
        title: '首页2',
        en: '首页2',
        brief: '<div>首页2<br/>12312123123123</div>',
        switch: 0,
        link: ''
      }]
    }
  },

  components: {
    three
  },
  created () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.initFn()

    window.addEventListener('resize', this.onWindowResize, false)
  },
  mounted () {
    setTimeout(() => {
      this.init()
    }, 3000)
  },
  watch: {
    toPhone (path) {
      this.aside_active === 4 ? (this.aside_active = '') : (this.aside_active = 4)
      if (document.documentElement.clientWidth < 1400) {
        this.$router.push('/mwelcome').catch(() => {
        })
      } else {
        this.$router.push('/welcome').catch(() => {
        })
      }
    },
    aside_active (item1) {
      if (item1 === 5) {
        setTimeout(() => {
          this.createCode()
        }, 500)
      } else {
        this.leave_msg = {
          name: '',
          phone: '',
          company: '',
          address: '',
          msg: ''
        }
      }
    },
    dot_active (item1) {
      // this.getProductList()
    }
  },
  async beforeDestroy () {
    this.tc = true
    await this.yr()
  },
  methods: {
    open1 (url) {
      window.open(url)
    },
    toPhone (path) {
      this.aside_active === 4 ? (this.aside_active = '') : (this.aside_active = 4)
      if (document.documentElement.clientWidth < 1400) {
        this.$router.push('/mwelcome').catch(() => {
        })
      } else {
        this.$router.push('/welcome').catch(() => {
        })
      }
    },
    createCode () {
      code = []
      const codeLength = 4 // 验证码的长度
      const checkCode = document.getElementById('checkCode')
      checkCode.value = ''
      const selectChar = [2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      for (let i = 0; i < codeLength; i++) {
        const charIndex = Math.floor(Math.random() * 32)
        code += selectChar[charIndex]
      }
      if (code.length !== codeLength) {
        this.createCode()
      }
      checkCode.value = code
    },
    validate () {
      const inputCode = document.getElementById('input1').value.toUpperCase()
      if (inputCode.length <= 0) {
        alert('请输入验证码！')
        return false
      } else if (inputCode !== code) {
        alert('验证码输入错误！')
        this.createCode()
        return false
      } else {
        return true
      }
    },
    async leaveMessage () {
      if (this.leave_msg.msg === '' || this.leave_msg.name === '' || this.leave_msg.phone === '' || this.leave_msg.Email === '') {
        return this.$message({
          message: '请正确填写信息',
          type: 'warning'
        })
      }
      const telStr = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
      const yxStr = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      // if (this.validate()) {
      if (!(yxStr.test(this.leave_msg.Email))) {
        return this.$message({
          message: '邮箱输入不规范',
          type: 'warning'
        })
      }
      if (!(telStr.test(this.leave_msg.phone))) {
        return this.$message({
          message: '手机号码输入不规范',
          type: 'warning'
        })
      }
      const res = await leaveMessage(this.leave_msg)
      this.$message({
        message: '留言成功！',
        type: 'success'
      })
      this.leave_msg.msg = ''
      this.leave_msg.name = ''
      this.leave_msg.phone = ''
      this.leave_msg.Email = ''
      this.leave_msg.address = ''
      this.leave_msg.company = ''
      // }
    },
    async initFn () {
      await this.slideshow()
      await this.getQualifications()
      await this.getIndexBanner()
      await this.getService()
      await this.getAbout()
      await this.getBanner()
      await this.getSite()
      await this.getProductList()
      await this.getNewsList()
      await this.getCases()
      this.flag = true
    },
    toJou1 () {
      this.$router.push('/journalism??cid=17&fid=16').catch(() => { })
    },
    toJou (id, navId) {
      if (id) {
        this.$router.push('/journalism?fid=16&cid=' + navId + '&nid=' + id).catch(() => { })
      }
    },
    // 点击合作案例更多
    toCoo () {
      this.$router.push('/cooperation?fid=20&cid=30').catch(() => { })
    },
    // 点击企业资质的更多
    toCor () {
      this.$router.push('/corporateinformation?fid=9&cid=29').catch(() => { })
    },
    // 点击产品详情
    toPro (id) {
      if (id) {
        const url = window.location.href.split('com')[0] + 'com/productcenter?fid=5&did=' + id
        // const url = window.location.href.split('top')[0] + 'top/productcenter?fid=5&did=' + id

        // const url = 'https://jls.shuleizhiti.com/productcenter'.split('com')[0] + 'com/productcenter?fid=5&did=' + id
        // console.log(url)
        window.open(url, '_blank')
        // this.$router.push('/productcenter?fid=5&did=' + id).catch(() => { })
      }
    },
    // 获取合作案例
    async getCases () {
      const { data: res } = await getCases(-1)
      this.casesData = res.case
    },
    // 获取新闻资讯列表
    async getNewsList () {
      const { data: res } = await getNewsList({
        // navId: -1,
        navId: 17,
        page: 1,
        pageSize: 20
      })

      this.newsList = res.list
    },
    // 获取产品中心列表
    async getProductList () {
      const { data: res } = await getProductList({
        navId: -1,
        page: 1,
        pageSize: 21
      })
      this.productList = res.list
    },
    // 获取侧边二维码
    async getSite () {
      const { data: res } = await getSite()
      this.site = res.site
    },
    // 获取轮播图
    async getBanner () {
      const { data: res } = await getBanner({
        navId: -1
      })
      this.banner = res.banner
    },
    async getQualifications () {
      const { data: res } = await getQualifications()
      this.qualificationsData = this.replaceHhf(res.quali)
    },
    async getIndexBanner () {
      const { data: res } = await getIndexBanner()
      this.indexBannerData = res.banner
    },
    async getService () {
      const { data: res } = await getService()
      this.serviceData = this.replaceHhf(res.service)
    },
    async getAbout () {
      const { data: res } = await getAbout()
      this.aboutData = this.replaceHhf(res.about)
    },
    yc () {
      if (this.tc) return
      if (this.timer3) return
      this.timer3 = setInterval(() => {
        this.setScrollY()
      }, 3000)
    },
    yr () {
      clearInterval(this.timer3)
      this.timer3 = null
      clearInterval(this.timer2)
      this.timer2 = null
    },
    setScrollY (height = 550) {
      if (this.tc) return

      if (this.timer2) return
      const dom = document.getElementById('scrollY')
      this.start = dom.scrollTop

      this.timer2 = setInterval(
        () => {
          dom.scrollTop = dom.scrollTop + 10
          if (dom.scrollTop - this.start >= height) {
            clearInterval(this.timer2)
            this.timer2 = null
          }
          if (dom.clientHeight + dom.scrollTop === dom.scrollHeight) {
            clearInterval(this.timer2)
            this.timer2 = null
            this.timer2 = setTimeout(() => {
              dom.scrollTop = 0
              this.timer2 = null
            }, 3000)
          }
        }, 10)
    },
    scrollY (e) {
    },
    topFunction () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    slideshow () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.timer = setInterval(() => {
        if (this.dot_active === 6) {
          this.dot_active = 0
        } else {
          this.dot_active = this.dot_active + 1
        }
      }, 3000)
    },
    onWindowResize () {
      // const windowHalfX = document.body.offsetWidth / 2
      // const windowHalfY = (document.body.offsetWidth / 3.5) / 2
      // this.camera.aspect = document.body.offsetWidth / (document.body.offsetWidth / 3.5)
      // this.camera.updateProjectionMatrix()
      // renderer.setSize(document.body.offsetWidth, (document.body.offsetWidth / 3.5))
      this.w = document.body.offsetWidth
      this.h = (document.body.offsetWidth / 3.4)
      this.canvas.width = this.w
      this.canvas.height = 540
    },
    init () {
      try {
        this.canvas = document.getElementById('mom')
        this.ctx = this.canvas.getContext('2d')
        this.w = document.body.offsetWidth
        this.h = 540
        try {
          this.canvas.width = this.w
        } catch (err) { }
        this.canvas.height = 540
        // this.canvas.height = 558

        this.fontSize = 16
        this.colunms = Math.floor(this.w / this.fontSize)
        this.drops = []/* 数组用来存放canvas中文字的Y值 更新位置 */
        for (let i = 0; i < this.colunms; i++) {
          this.drops.push(0)// 规定初始时从页面最顶端下来
        }
        this.str = 'CANVAS Javascript'
        this.draw()
        setInterval(this.draw, 50)
      } catch (err) {

      }
    },
    draw () {
      this.ctx.fillStyle = 'rgba(0,0,0,0.05)'/* 背景颜色 */
      // 由于页面在反复调用这个draw方法，透明度也在叠加，使里面的文字颜色也有变化，所有看起来感觉有3D的感觉，有层次感。
      this.ctx.fillRect(0, 0, this.w, this.h)
      this.ctx.font = '700 ' + this.fontSize + 'px 微软雅黑'/* 字体颜色 */
      this.ctx.fillStyle = 'rgb(72,106,141)'
      // 写入画布
      for (let i = 0; i < this.colunms; i++) {
        const index = Math.floor(Math.random() * this.str.length)
        const x = i * this.fontSize
        const y = this.drops[i] * this.fontSize
        this.ctx.fillText(this.str[index], x, y)
        if (y >= this.canvas.height && Math.random() > 0.99) { // 0.99的随机数造成列消失的时间不一致，产生的列也随之改变
          this.drops[i] = 0// 超出高度回到起点
        }
        this.drops[i]++
      }
    }
  }
}
</script>

<style scoped lang="scss">
.aside {
  .minwindow {
    .code {
      background-image: url("@/static/img/bg3.png");
      font-family: Arial, 宋体;
      font-style: italic;
      color: green;
      border: 0;
      padding: 0.104vw 0.156vw;
      letter-spacing: 0.156vw;
      font-weight: bolder;
    }
    .unchanged {
      border: 0;
    }

    .content {
      > div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 1.042vw;
        textarea {
          margin-bottom: 1.042vw;
          width: 100%;
          resize: none;
        }
        div {
          margin-bottom: 1.042vw;
          width: 10.417vw;
          height: 2.083vw;
          display: flex;
          align-content: center;
          flex-wrap: nowrap;
          border: 0.052vw solid #003472;
          > span {
            padding: 0.521vw;
            white-space: nowrap;
            font-size: 0.625vw;
            background: rgba(0, 52, 114, 0.1);
          }
          > input {
            display: inline-block;
            width: 5.729vw;
            flex: 1;
            background: rgb(217, 225, 233);
            outline: none;
            border: none;
          }
        }
        div:nth-child(5) {
          width: 100%;
        }
        > div:nth-last-child(2) {
          width: 5.208vw;
          height: 2.083vw;
          margin-right: 5.208vw;
          img {
            width: 100%;
          }
        }
        button {
          width: 6.25vw;
          margin: 0.26vw auto;
          border: none;
          font-size: 0.833vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          height: 1.875vw;
          background: #ffc11f;
          box-shadow: 0vw 0vw 0.208vw 0vw #003472;
          border-radius: 0.417vw;
        }
      }
      .content_title {
        margin: 1.042vw auto;
        width: 5vw;
        height: 1.719vw;
        font-size: 1.25vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #003472;
        line-height: 1.719vw;
      }
    }
    .title {
      padding: 0 1.094vw;
      span {
        font-size: 1.25vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 1.719vw;
      }
      .active {
        background: #fff;
        color: #003371;
      }
      i {
        display: inline-block;
        font-size: 1.354vw;
        color: #fff;
        width: 1.563vw;
        height: 1.563vw;
        border-radius: 0.104vw;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      width: 25vw;
      height: 3.333vw;
      background: #003472;
      border-radius: 0.417vw 0.417vw 0vw 0vw;
    }
    position: fixed;
    right: 5.208vw;
    width: 25vw;
    height: 33.333vw;
    top: 13.021vw;
    background: #f2f5f8;
    box-shadow: 0vw 0.104vw 0.208vw 0vw #003472;
    border-radius: 0.417vw;
    background-color: rgb(242, 245, 248);
  }
  position: fixed;
  z-index: 5;
  // height: 29.167vw;
  width: 4.167vw;
  right: 0;
  top: 15.625vw;
  background-color: rgb(229, 235, 241);
  > div {
    position: relative;
  }
  .ewm {
    position: absolute;
    left: -7.813vw;
    width: 7.813vw;
    height: 7.813vw;
    top: 0;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  > div {
    cursor: pointer;
    height: 4.167vw;
    width: 4.167vw;
    text-align: center;

    > i {
      color: #003472;
      font-size: 1.825vw;
    }
    > div:nth-child(2) {
      @media screen and (max-width: 78.125vw) {
        font-size: 0.625vw;
        transform: scale(0.8);
      }
      @media screen and (max-width: 75.521vw) {
        font-size: 0.625vw;
        transform: scale(0.7);
      }
    }
    > div {
      height: 0.885vw;
      font-size: 0.729vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003371;
      line-height: 0.885vw;
    }
  }
  > .active {
    background: #003472;

    > i {
      color: rgb(229, 235, 241);
    }
    > div {
      color: rgb(229, 235, 241);
    }
  }
}
.product_center {
  width: 100%;
  min-width: 75vw;

  height: 53.073vw;
  background: rgba(0, 52, 114, 0.05);
  .product_botton {
    width: 100%;
    float: left;
    padding-top: 2.188vw;
    text-align: center;
    > a {
      font-size: 1.25vw;
      color: #003472;
      display: inline;
      position: relative;
      left: -0.781vw;
      i {
        position: absolute;
        font-size: 1.875vw;
        transform: translateY(-15%);
      }
    }
  }
  .product_center_center {
    > .right_dot {
      margin-left: 2.004vw;
      margin-top: 5.208vw;
      float: left;

      .dot_item {
        transition: color, border 1.5s;
        width: 2.292vw;
        height: 4.5vw;
        cursor: pointer;
        > div:nth-child(1) {
          position: relative;
          width: 1.25vw;
          height: 1.25vw;
          border-radius: 50%;
          background: rgb(206, 215, 226);
          transition: border, background 0.5s;

          > div {
            transition: color, border 1.5s;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
          }
          > div:nth-child(1) {
            transition: border 1.5s;

            display: none;
            width: 1.6vw;
            height: 1.6vw;
          }
          > div:nth-child(2) {
            transition: border 1.5s;
            display: none;

            width: 1.9vw;
            height: 1.9vw;
          }
          > div:nth-child(3) {
            transition: border 1.5s;

            display: none;
            width: 2.2vw;
            height: 2.2vw;
          }

          > div:nth-child(4) {
            transition: color 3s;
            color: rgba(0, 0, 0, 0);
            top: 2.4vw;
          }
          > div:nth-child(5) {
            transition: color 3s;
            color: rgba(0, 0, 0, 0);

            top: 2.7vw;
          }
          > div:nth-child(6) {
            transition: color 3s;
            color: rgba(0, 0, 0, 0);

            top: 3vw;
          }
        }
      }
      .dot_item_active1 {
        > div:nth-child(1) {
          background: #ffc11f;

          > div:nth-child(4) {
            color: #ffc01fdc;
          }
          > div:nth-child(5) {
            color: #ffc01fdc;
          }
          > div:nth-child(6) {
            color: #ffc01fdc;
          }
        }
      }
      .dot_item_active {
        > div:nth-child(1) {
          background: #ffc11f;
          // > div {
          //   opacity: 1;
          // }
          > div:nth-child(1) {
            border: 0.052vw solid #ffc01fdc;
            display: block;
          }
          > div:nth-child(2) {
            border: 0.052vw solid #ffc01fb0;
            display: block;
          }
          > div:nth-child(3) {
            border: 0.052vw solid #ffc01fab;
            display: block;
          }
          > div:nth-child(4) {
            color: #ffc01fdc;
          }
          > div:nth-child(5) {
            color: #ffc01fdc;
          }
          > div:nth-child(6) {
            color: #ffc01fdc;
          }
        }
      }
    }
    width: 75vw;

    height: 37.5vw;
    margin: 0 auto;
    margin-top: 2.083vw;

    > .centre_centre {
      float: left;
      margin-left: 2.083vw;
      > .left {
        float: left;
        width: 33.333vw;
        height: 36.458vw;
        background: #ffffff;
        box-shadow: 0vw 0.104vw 0.208vw 0vw rgba(0, 52, 114, 0.5);
        border-radius: 0.417vw;
        > div:nth-child(1) {
          float: left;
          width: 14.583vw;
          padding: 0 1.042vw;
          height: 100%;
          position: relative;

          > .title {
            > div:nth-child(1) {
              text-align: start;
              width: 10vw;
              // height: 3.49vw;
              font-size: 2.5vw;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: medium;
              color: #003472;
              line-height: 3.49vw;
              margin-top: 3.125vw;
            }
            > div:nth-child(2) {
              margin-top: 1.042vw;
              width: 6.25vw;
              height: 0.417vw;
              background: #003472;
            }
          }
          > .content {
            position: relative;
            z-index: 5;
            text-align: start;
            margin-top: 1.042vw;
            width: 12.5vw;
            height: 13.75vw;
            font-size: 1.25vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #003472;
            line-height: 1.719vw;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }

          > button {
            position: absolute;
            left: 40%;
            transform: translateX(-50%);
            bottom: 3.125vw;
            border: none;
            width: 9.167vw;
            height: 3.333vw;
            background: #003472;
            box-shadow: 0vw 0vw 0.208vw 0vw #003472;
            border-radius: 0.417vw;
            text-align: start;
            padding-left: 1.542vw;

            font-size: 1.25vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 1.719vw;
            .iconfont {
              position: absolute;
              font-size: 1.875vw;
            }
          }
        }
        > div:nth-child(2) {
          box-sizing: border-box;

          float: left;
          margin-top: 1.563vw;
          padding-top: 2.083vw;
          width: 17.708vw;

          height: 28.698vw;
          border: 0.625vw solid #ffc11f;
          position: relative;
          > div {
            position: relative;
            z-index: 5;
            margin: 0 0 0 2.083vw;
            text-align: start;
            width: 13.021vw;
            height: 4.583vw;
            font-size: 1.875vw;
            font-family: DIN-MediumAlternate, DIN;
            font-weight: normal;
            color: #003472;
            line-height: 2.292vw;
          }
          > img {
            width: 16.667vw;
            height: 20.833vw;
            position: absolute;
            bottom: -1.76vw;
            left: -1.76vw;
          }
        }
      }
      > .right {
        float: left;
        margin-left: 2.083vw;
        > div {
          width: 28.125vw;
          height: 17.188vw;
          border-radius: 0.417vw;
          background: url(@/static/img/product/bg.png);
          background-size: 100%, 100%;
          position: relative;
          img {
            position: absolute;
            right: 1.042vw;
            top: 50%;
            transform: translateY(-50%);
            width: 14.896vw;
            height: 12.969vw;
          }
          > div {
            padding-left: 1vw;
            padding-top: 1vw;
            > .title {
              position: relative;
              z-index: 5;
              text-align: start;
              line-height: 3.333vw;
              width: 12.5vw;
              // height: 4vw;
              font-size: 2.5vw;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: medium;
              color: #003472;
            }
            div:nth-child(2) {
              margin-top: 1.042vw;
              width: 6.25vw;
              height: 0.417vw;
              background: #003472;
            }
            button {
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 2.604vw;
              position: absolute;
              left: 1.042vw;
              bottom: 2.083vw;
              width: 9.167vw;
              height: 3.333vw;
              background: #003472;
              box-shadow: 0vw 0.104vw 0.208vw 0vw rgba(0, 52, 114, 0.49);
              border-radius: 0.417vw;
              border: none;
              border-radius: 0.417vw;
              font-size: 1.25vw;
              .iconfont {
                top: 0.26vw;
                position: relative;
                font-size: 1.875vw;
              }
            }
          }
        }
        > div:nth-child(1) {
        }
        > div:nth-child(2) {
          margin-top: 2.083vw;
          img {
            width: 14.896vw;
            height: 13.021vw;
          }
        }
      }
    }

    > div:nth-child(1) {
      position: relative;
      width: 4.74vw;
      float: left;
      height: 100%;
      > div:nth-child(1) {
        width: 4.74vw;
        height: 6.042vw;
        font-size: 5vw;
        font-family: DIN-Alternate, DIN;
        font-weight: normal;
        color: rgb(0, 52, 114, 0.75);
        line-height: 6.042vw;
      }
      > div:nth-child(2) {
        width: 4.74vw;
        height: 12.083vw;
        position: absolute;
        bottom: 2.083vw;
        // 上箭头
        > div:nth-child(1) {
          cursor: pointer;
          width: 5vw;
          height: 5vw;
          position: relative;
          > i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 2.604vw;
          }
          background: rgb(0, 52, 114, 0.1);
          transform: rotateZ(90deg);
        }
        // 下箭头
        > div:nth-child(2) {
          cursor: pointer;
          width: 5vw;
          height: 5vw;
          margin-top: 2.083vw;
          transform: rotateZ(-90deg);
          > i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 2.604vw;
          }
          background: rgb(0, 52, 114, 0.1);
        }

        .active_jt {
          background: #003472 !important;
          i {
            color: #fff;
          }
        }
      }
    }
  }
  .product_center_top {
    padding-top: 2.083vw;
    text-align: center;
    perspective: 5.208vw;
    > div:nth-child(1) {
      position: absolute;
      left: 50%;
      top: 2.083vw;
      transform: translateX(-50%);
      margin: 0 auto;
      width: 13.333vw;
      height: 4.688vw;
      font-size: 3.333vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #003472;
      line-height: 4.688vw;
    }
    > div:nth-child(2) {
      // margin-top: 3.042vw;
      margin-top: 2.344vw;
      // height: 3.021vw;
      font-size: 2.5vw;
      font-family: DIN-MediumAlternate, DIN;
      font-weight: normal;
      color: rgb(205, 214, 226);
      line-height: 2.5vw;
    }
  }
}
.journalism {
  position: relative;
  width: 100%;
  min-width: 75vw;
  height: 45.271vw;
  background: rgb(228, 233, 239);
  .journalism_content {
    width: 75vw;
    margin: 0 auto;
    padding-top: 10.604vw;
    // width: 42.708vw;
    height: 39.246vw;
    display: flex;
    justify-content: space-between;
    .journalism_left {
      cursor: pointer;
      position: relative;
      .journalism_left_content {
        text-align: start;
        padding: 1.042vw;
        position: relative;
        background: rgb(205, 214, 225);
        border-radius: 0.417vw;
        height: 8.854vw;
        h2 {
          font-size: 1.25vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #003371;
          line-height: 1.719vw;
        }
        div {
          position: relative;
          font-size: 0.833vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #003371;
          line-height: 1.25vw;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        > div:nth-last-child(1) {
          position: absolute;
          right: 1.042vw;
          bottom: 0.52vw;
          cursor: pointer;
        }
      }
      .journalism_left_top {
        width: 39.583vw;
        height: 19.792vw;
        // background: url(@/static/img/lbt1.png) no-repeat;
        // background-size: 100% 100%;
      }
      .journalism_left_card {
        > div:nth-child(1) {
          display: flex;
          .card_item {
            padding: 0 0.521vw;
            margin-right: 1.042vw;
            font-size: 0.833vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 1.667vw;
            background: #e15f08;
            border-radius: 0.208vw;
          }
          float: left;
        }
        > div:nth-child(2) {
          float: right;
          width: 9.375vw;
          height: 1.667vw;
          line-height: 1.667vw;
          background: #003371;
          border-radius: 0.208vw;
          font-size: 0.833vw;
          font-family: DIN-Regular, DIN;
          font-weight: 400;
          color: #ffffff;
        }
        padding: 0 0.521vw;
        position: absolute;
        top: 18.8vw;
        width: 39.583vw;

        height: 1.667vw;
        z-index: 2;
      }
      width: 39.583vw;
      height: 28.646vw;
    }
    .journalism_right {
      overflow-y: scroll;
      .journalism_right_item {
        cursor: pointer;

        display: flex;
        .journalism_right_item_left {
          background-color: rgb(0, 51, 113);

          text-align: center;
          > div:nth-child(1) {
            margin-top: 0.5vw;

            font-size: 3.333vw;
            font-family: DIN-Bold, DIN;
            font-weight: bold;
            color: #ffffff;
            line-height: 4.063vw;
          }
          > div:nth-child(2) {
            width: 6.75vw;
            background-color: #fff;
            margin: 0 auto;
            height: 0.052vw;
            border: 0.208vw solid #ffffff;
            margin-bottom: 0.5vw;
          }
          > div:nth-child(3) {
            font-size: 1.875vw;
            font-family: DIN-Regular, DIN;
            font-weight: 400;
            color: #ffffff;
            line-height: 2.24vw;
          }
          width: 8.854vw;
          height: 8.854vw;
        }
        .journalism_right_item_right {
          background-color: rgb(205, 215, 226);
          padding: 0 1.042vw;
          text-align: start;
          cursor: pointer;
          position: relative;
          h3 {
            margin-top: 0.26vw;
            font-size: 1.25vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #003371;
          }
          div {
            position: relative;
            font-size: 0.833vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #003371;
            line-height: 1.146vw;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; //行数
            -webkit-box-orient: vertical;
          }
          > div:nth-last-child(1) {
            position: absolute;
            right: 1.042vw;
            bottom: 0.522vw;
          }
          flex: 1;
        }
        width: 100%;
        height: 8.854vw;

        margin-bottom: 0.729vw;
      }
      width: 33.75vw;
      height: 28.646vw;
    }
  }
  .title1 {
    > div:nth-child(1) {
      color: #003472;
    }
    > div:nth-child(2) {
      color: rgb(217, 225, 234);
    }
  }
}

#map_container {
  width: 100%;
  height: 20.833vw;
}
.amap_demo {
  width: 100%;
  height: 100%;
}

.cooperation_case {
  position: relative;
  width: 100%;
  min-width: 75vw;
  // height: 55.156vw;
  // max-height: 55.156vw;
  padding: 7.813vw 0;
  background: #f2f5f8;
  .cooperation_case_content {
    max-height: 39.583vw;
    width: 75vw;
    // position: absolute;
    // left: 50%;
    // top: 7.813vw;
    margin: 0 auto;
    // transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap;
    img {
      margin-right: 1.042vw;
      width: 17.969vw;
      height: 8.333vw;
      margin-bottom: 1.042vw;
      // background: url(@/static/img/xx1.png) no-repeat;
      // background-size: 100% 100%;
    }
    img:nth-child(4n) {
      margin-right: 0;
    }
    // img:nth-last-child(1) {
    //   filter: grayscale(100%);
    // }
  }
  .title1 {
    top: -49.997px;
    > div:nth-child(1) {
      color: #003472;
    }
    > div:nth-child(2) {
      color: rgb(217, 225, 234);
    }
  }

  .More {
    bottom: 3.083vw;
  }
}

.More {
  position: absolute;
  bottom: 2.083vw;
  left: 50%;
  transform: translateX(-50%);
  a {
    font-size: 1.25vw;
    color: #003472;
    display: inline;
    position: relative;
    i {
      position: absolute;
      font-size: 1.875vw;
      transform: translateY(-15%);
    }
  }
}
.title1 {
  position: absolute;
  top: 0;
  height: 4.688vw;
  width: 100%;
  div:nth-child(1) {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 2.083vw;
    width: 53.333vw;
    height: 4.688vw;
    font-size: 3.333vw;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    z-index: 3;
    line-height: 4.688vw;
  }
  div:nth-child(2) {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 4.3vw;
    transform: translateX(-50%);
    font-size: 2.5vw;
    font-family: DIN-MediumAlternate, DIN;
    font-weight: normal;
    color: rgb(68, 122, 154);
    line-height: 2.5vw;
  }
}
.about_us {
  position: relative;

  .about_us_top {
    position: absolute;
    top: 2.083vw;
    left: 50%;
    transform: translateX(-50%);
    > div:nth-child(1) {
      position: relative;
      width: 13.333vw;
      height: 4.688vw;
      font-size: 3.333vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #003472;
      line-height: 4.688vw;
      z-index: 5;
    }
    > div:nth-child(2) {
      position: absolute;
      left: 0.8vw;
      top: 2.344vw;
      // width: 11.719vw;
      // height: 3.021vw;
      font-size: 2.5vw;
      font-family: DIN-MediumAlternate, DIN;
      font-weight: normal;
      color: rgb(217, 225, 234);
      z-index: 2;
      line-height: 2.5vw;
    }
  }
  width: 100%;
  min-width: 75vw;
  height: 48.802vw;
  background: #f2f5f8;
}

a {
  text-decoration: none;
  outline: none;
  color: #000;
}

.slideshow1 {
  width: 100%;
  height: 46.875vw;
  min-width: 75vw;

  .carousel-control-prev,
  .carousel-control-next {
    cursor: default;
  }

  .carousel-item {
    position: relative;

    img {
      // height: 46.771vw;
      height: 46.77vw;
    }

    .lbt-content {
      position: absolute;
      left: 15%;
      top: 20%;
      text-align: start;

      p {
        text-align: start;
      }

      p:nth-child(1) {
        min-width: 25vw;
        height: 7.031vw;
        font-size: 5vw;
        display: block;

        font-size: 5vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: medium;
        color: #ffffff;
        line-height: 7.031vw;
      }
      p:nth-child(2),
      > div {
        width: 32.448vw;
        min-height: 2.604vw;
        display: block;
        font-size: 1.875vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 2.604vw;
      }

      button {
        margin-top: 2.083vw;
        .iconfont {
          font-size: 1.875vw;
        }

        font-size: 1.875vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 2.604vw;
        width: 12.5vw;
        height: 4.167vw;
        background: #003472;
        border: none;
        box-shadow: 0vw 0vw 0.208vw 0vw #003472;
        border-radius: 0.417vw;
      }
    }
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 4.167vw;
  height: 4.167vw;
  cursor: pointer;
}
.carousel-indicators {
  button {
    width: 1.563vw;
  }
}
</style>
