import axios from 'axios'
import router from '@/router'

const service = axios.create({
  // baseURL: 'https://zhinanzhen.shuleizhiti.com/api/index', // 请求地址前缀
  baseURL: 'https://backstage.shuleizhiti.com/api/index', // 请求地址前缀
  timeout: 0
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 添加请求头部参数
    // config.headers.arg1 = 'arg1Value'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 拦截到成功的数据
    if (response.data.code !== 1) {
      // Message({ message: '' + , type: 'warning' })

      if (document.documentElement.clientWidth < 1440) {
        router.push('/merror').catch(() => {

        })
      } else {
        router.push('/error').catch(() => {

        })
      }
    }
    return response.data
  },
  error => {
    // 拦截到失败的数据
    if (document.documentElement.clientWidth < 1440) {
      router.push('/merror').catch(() => {

      })
    } else {
      router.push('/error').catch(() => {

      })
    }
    return Promise.reject(error)
  }
)

export default service
