<template>
  <div v-if="flag">
    <div
      class="bg_top"
      :style="'background:url(' + banner[0].image + ') no-repeat 100%/cover'"
    >
      <h2>{{ banner[0].en || "product center" }}</h2>
      <h3>{{ banner[0].title || "企业介绍" }}</h3>
      <div v-html="banner[0].brief"></div>
    </div>
    <div class="wb">
      <div class="title">
        <div>企业简介</div>
        <div>
          <div
            v-for="item in nav"
            :key="item.id"
            :class="active_item == item.id ? 'active_item' : ''"
            @click="active_item = item.id"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="content">
        <div class="content_title">
          <h3>{{ honorData.nav ? honorData.nav.title : "" }}</h3>
          <h3>{{ honorData.nav ? honorData.nav.dirname : "" }}</h3>
        </div>
        <div class="service" v-if="active_item == 28">
          <img
            :src="item.image"
            v-for="item in honorData.honor"
            :key="item.id"
          />
        </div>
        <!-- <div
          class="content_title"
            v-if="active_item == 28 || active_item == 29"
          v-if="active_item == 25 || active_item == 26 || active_item == 27"
        >
          <h3>{{ honorData.nav.title || "" }}</h3>
          <h3>{{ honorData.nav.dirname || "" }}</h3>
        </div> -->
        <div
          v-html="singleData.single ? singleData.single.content : ''"
          v-if="active_item == 25 || active_item == 26 || active_item == 27"
        ></div>
        <div class="honor" v-if="active_item == 29">
          <img
            :src="item.image"
            v-for="item in honorData.honor"
            :key="item.id"
          />
        </div>
        <!-- <div class="intro">
          <div class="item1">
            <div>
              <img
                src="@/static/img/bg3.png"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>
            <div></div>
            <div>
              山东指南针体育产业有限公司坐落于中国东部沿海经济大省——山东省的省会，著名泉水之城----济南。是一家集体育工程建设、体育器材技术开发、技术转让、技术咨询服务、健身器材销售、校园体测设备开发生产、健身项目设计、设备安装调试及培训指导、售后服务等全方位、一站式服务能力的专业化公司。
              质测试、体育课堂评价、课外体育作业、体质数据分析等功能，打造“书雷智体”品牌。
            </div>
          </div>
          <div class="item2">
            <div>
              山东指南针体育产业有限公司坐落于中国东部沿海经济大省——山东省的省会，著名泉水之城----济南。是一家集体育工程建设、体育器材技术开发、技术转让、技术咨询服务、健身器材销售、校园体测设备开发生产、健身项目设计、设备安装调试及培训指导、售后服务等全方位、一站式服务能力的专业化公司。
              山东指南针体育产业有限公司致力于打造智慧体育平台，赋能智慧校园体育建设。构建了线上统一平台与线下物联网智能硬件系统，在线上统一平台方面建设了统一多系统，具有体质测试、体育课堂评价、课外体育作业、体质数据分析等功能，打造“书雷智体”品牌。
            </div>
            <div></div>
            <div>
              <img
                src="@/static/img/bg3.png"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
          <div class="item1">
            <div>
              <img
                src="@/static/img/bg3.png"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>
            <div></div>
            <div>
              山东指南针体育产业有限公司坐落于中国东部沿海经济大省——山东省的省会，著名泉水之城----济南。是一家集体育工程建设、体育器材技术开发、技术转让、技术咨询服务、健身器材销售、校园体测设备开发生产、健身项目设计、设备安装调试及培训指导、售后服务等全方位、一站式服务能力的专业化公司。
              质测试、体育课堂评价、课外体育作业、体质数据分析等功能，打造“书雷智体”品牌。
            </div>
          </div>
          <div class="item2">
            <div>
              山东指南针体育产业有限公司坐落于中国东部沿海经济大省——山东省的省会，著名泉水之城----济南。是一家集体育工程建设、体育器材技术开发、技术转让、技术咨询服务、健身器材销售、校园体测设备开发生产、健身项目设计、设备安装调试及培训指导、售后服务等全方位、一站式服务能力的专业化公司。
              山东指南针体育产业有限公司致力于打造智慧体育平台，赋能智慧校园体育建设。构建了线上统一平台与线下物联网智能硬件系统，在线上统一平台方面建设了统一多系统，具有体质测试、体育课堂评价、课外体育作业、体质数据分析等功能，打造“书雷智体”品牌。
            </div>
            <div></div>
            <div>
              <img
                src="@/static/img/bg3.png"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner, getChildrenNav } from '@/api/homeApi.js'
import { getSingle, getHonor } from '@/api/enterprise.js'

export default {
  data () {
    return {
      active_item: 0,
      banner: {},
      nav: [],
      singleData: {},
      honorData: {},
      flag: false
    }
  },
  watch: {
    $route () {
      this.active_item = this.$route.query.cid
    },
    active_item (id) {
      this.getSingle()
      this.getHonor()
    }
  },
  async created () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.active_item = this.$route.query.cid
    await this.getBanner()
    await this.getChildrenNav()
    await this.getSingle()
    await this.getHonor()
    this.flag = true
  },
  methods: {
    async getSingle () {
      const { data: res } = await getSingle({ navId: this.active_item })
      this.singleData = res
    },
    async getHonor () {
      const { data: res } = await getHonor({ navId: this.active_item })

      res.nav.dirname = res.nav.dirname.toUpperCase()
      this.honorData = res
    },
    async getChildrenNav () {
      const { data: res } = await getChildrenNav({ navId: this.$route.query.fid })
      this.nav = res.nav
    },
    async getBanner () {
      const { data: res } = await getBanner({ navId: this.$route.query.fid })
      this.banner = res.banner
    }
  }

}
</script>

<style scoped lang="scss">
.content {
  // width: 100%;
  width: 75vw;
  min-height: 26.042vw;
  // padding: 0 10.417vw;
  margin: 0 auto;
  .service {
    text-align: start;
    > img {
      margin-right: 1.406vw;
      margin-bottom: 2.083vw;
      width: 24.042vw;
      border-top: 1.563vw solid #fff;
      border-bottom: 1.563vw solid #fff;
      height: 17.604vw;
      box-shadow: 0vw 0.104vw 0.208vw 0vw rgba(0, 52, 114, 0.5);
    }
    > img:nth-child(3n) {
      margin-right: 0;
    }
  }
  .honor {
    text-align: start;
    > img {
      margin-right: 1.042vw;
      width: 17.969vw;
      height: 25vw;
      margin-bottom: 2.083vw;
    }
    > img:nth-child(4n) {
      margin-right: 0;
    }
  }
  .content_title {
    margin: 2.604vw 0;
    text-align: start;
    > h3:nth-child(1) {
      font-size: 3.333vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
      line-height: 4.688vw;
    }
    > h3:nth-child(2) {
      font-size: 1.875vw;
      font-family: DIN-RegularAlternate, DIN;
      font-weight: normal;
      color: #003472;
      line-height: 2.24vw;
    }
  }
}
.page {
  margin-top: 3.125vw;
}

.title {
  // width: 100%;
  width: 75vw;
  height: 4.167vw;
  // background: rgba(0, 52, 114, 0.1);
  // padding: 0 10.417vw;
  margin: 0 auto;
  > div:nth-child(2) {
    display: flex;
    float: right;
    cursor: pointer;

    .active_item {
      color: #ffc11f;
      border-bottom: 0.208vw solid #ffc11f;
    }
    > div {
      line-height: 4.167vw;
      // width: 6.25vw;
      padding: 0 0.521vw;
      margin: 0 0.521vw;

      height: 4.167vw;
      font-size: 1.25vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
    }
  }
  > div:nth-child(1) {
    float: left;
    width: 10vw;
    height: 3.49vw;
    font-size: 2.5vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #003472;
    line-height: 3.49vw;
  }
}
.bg_top {
  > h2 {
    font-size: 5vw;
    font-family: DIN-Light, DIN;
    font-weight: 300;
    color: #ffc11f;
    line-height: 6.094vw;
  }
  > h3 {
    font-size: 3.333vw;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ffc11f;
  }

  > div {
    margin-top: 2.083vw;
    font-size: 1.25vw;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ffc11f;
    line-height: 1.719vw;
  }
  .div2 {
    margin-top: 0;
  }
  width: 100%;
  min-width: 75vw;

  height: 30.469vw;
  background: url(@/static/img/q2.png) no-repeat;
  background-size: 100% 100%;
  padding-left: 10.417vw;
  padding-top: 7.813vw;
  text-align: start;
}
</style>
