<template>
  <div id="main">
    <!-- 顶部搜索 -->
    <nav class="nav_top d-none d-lg-block">
      <div>打造新时代体育智能教学平台</div>
      <div>
        <div class="phone">
          <i class="iconfont icon-24gl-telephone2"></i>
          <span>{{ site.phone }}</span>
        </div>
        <div class="input">
          <input type="text" />
          <i class="iconfont icon-sousuo"></i>
        </div>
      </div>
    </nav>
    <!-- 导航栏 -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light navbar_top">
      <div class="container-fluid">
        <a
          class="navbar-brand"
          @click.stop="toHome"
          href="#"
          style="positon: relative"
          ><img
            src="@/static/img/logo.png"
            class="navbar-brand"
            style="width: 16.875vw"
          />
          <h1 style="visibility: hidden; position: absolute; left: 0; top: 0">
            山东指南针体育产业有限公司
          </h1>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                class="nav-link active"
                aria-current="page"
                style="cursor: pointer"
                @click="toHome"
                >首页</a
              >
            </li>
            <template v-for="item in nav">
              <li
                class="nav-item"
                v-if="item.childrens.length == 0"
                :key="item.id"
              >
                <a
                  class="nav-link active"
                  aria-current="page"
                  style="cursor: pointer"
                  @click="clickNav(item)"
                  >{{ item.title }}</a
                >
              </li>
              <li class="nav-item dropdown" v-else :key="item.id + '.'">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ item.title }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li v-for="item2 in item.childrens" :key="item2.id">
                    <a class="dropdown-item" @click="clickNav(item, item2)">{{
                      item2.title
                    }}</a>
                  </li>
                </ul>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </nav>
    <router-view></router-view>
    <!-- 底部 -->
    <div class="bottom">
      <div>
        <div class="bottom_left">
          <div class="bottom_left_title">山东指南针体育产业有限公司</div>
          <div class="bottom_left_content">
            <div class="bottom_left_content_left">
              <div>
                <i class="iconfont icon-24gl-telephone2"></i>
                <span>{{ site.phone }}</span>
              </div>
              <div>
                <i class="iconfont icon-QQ"></i>
                <span>{{ site.qq }}</span>
              </div>
              <div>
                <i class="iconfont icon-wodeye_diqu"></i>
                <span>{{ site.address }}</span>
              </div>
              <div>
                <span>ICP备案号：{{ site.beian }}</span>
              </div>
            </div>
            <div class="bottom_left_content_right">
              <div>友情链接</div>
              <div v-for="(item, index) in site.friendly_url" :key="index">
                <a :href="item.url" target="_blank">{{ item.title }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom_right" id="map_container">
          <a
            href="https://uri.amap.com/marker?position=117.093784,36.68091&name=智能圆511"
            target="_blank"
          >
            <el-amap
              :center="center"
              :zoom="zoom"
              class="amap_demo"
              :dragEnable="false"
              :plugin="plugin"
            >
              <!-- 地图标记 -->
              <el-amap-marker
                :dragEnable="false"
                v-for="(marker, index) in markers"
                :position="marker.position"
                :key="index"
              ></el-amap-marker>
            </el-amap>
          </a>
        </div>
      </div>
      <div>Copyright © 2012-2018 山东指南针体育产业有限公司 版权所有</div>
      <div class="jy">建议使用1920*1080分辨率的屏幕观看使用</div>
    </div>
  </div>
</template>

<script>
import { getNavList, getSEO, getChildrenNav, getBanner, getSite, leaveMessage } from '@/api/homeApi.js'

export default {
  name: 'HomeView',
  data () {
    const that = this

    return {
      site: {},
      nav: [],
      zoom: 20,
      // 默认中心点
      center: [117.093784, 36.68091],
      // 标记点
      markers: [
        // 标记点位置
        { position: [117.093784, 36.68091] }
      ],
      // 当前地图的插件
      plugin: [{

        enableHighAccuracy: true, // 是否使用高精度定位，默认:true
        timeout: 10000, // 超过10秒后停止定位，默认：无穷大
        maximumAge: 0, // 定位结果缓存0毫秒，默认：0
        convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
        showButton: true, // 显示定位按钮，默认：true
        buttonPosition: 'RB', // 定位按钮停靠位置，默认：'LB'，左下角
        showMarker: false, // 定位成功后在定位到的位置显示点标记，默认：true
        showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
        panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
        zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
        extensions: 'all',
        pName: 'Geolocation',

        events: {
          init (o) {
            // o 是高德地图定位插件实例
            // o.getCurrentPosition((status, result) => {
            //   console.log(result)
            //   // if (result && result.position) {
            //   //   // 将当前经纬度给中心点
            //   //   that.center = [result.position.lng, result.position.lat]
            //   //   // 将当前经纬度给标记点
            //   //   that.markers[0].position = that.center
            //   //   that.loaded = true
            //   // }
            // })
          }
        }
      }],

      aside_active: '',
      dot_active: 0,
      timer: null,
      canvas: null,
      ctx: null,
      w: null,
      h: null,
      fontSize: null,
      colunms: null,
      drops: null,
      str: null
    }
  },
  mounted () {

  },
  created () {
    this.getNavList()
    this.getSite()
  },
  methods: {
    // 获取侧边二维码
    async getSite () {
      const { data: res } = await getSite()
      this.site = res.site
    },
    async getNavList () {
      const { data: res } = await getNavList()
      this.nav = res.nav
    },
    // 点击nav跳转到不同的路由
    clickNav (fitem, citem = '') {
      if (fitem.is_part_data === '0' || citem.is_part_data === '0') {
        if (fitem.id === 9) {
          // 企业介绍
          this.$router.push('/corporateinformation?fid=' + fitem.id + '&cid=' + citem.id).catch(() => {
          })
        } else if (fitem.id === 20) {
          // 合作案例
          this.$router.push('/cooperation?fid=' + fitem.id + '&cid=' + citem.id).catch(() => {
          })
        } else if (fitem.id === 21) {
          // 联系我们
        } else if (fitem.id === 22) {
          // 在线预约
        } else if (fitem.id === 5) {
          // 产品中心
          this.$router.push('/productcenter?cid=' + citem.id + '&fid=' + fitem.id).catch(() => { })
        } else if (fitem.id === 10) {
          // 解决方案
          this.$router.push('/solution?fid=' + fitem.id + '&cid=' + citem.id).catch(() => {
          })
        } else if (fitem.id === 16) {
          // 新闻咨询
          this.$router.push('/journalism?cid=' + citem.id + '&fid=' + fitem.id).catch(() => { })
        }
      } else {
        window.open('http://' + citem.typelink || fitem.typelink + '/', '_blank')
      }
    },

    toHome () {
      this.$router.push('/welcome').catch(() => {
        //  console.log(err)
      })
    }

  },
  components: {
  }

}
</script>

<style lang="scss" scoped>
html {
  min-width: 1440px;
}

.container-fluid {
  padding: 0;
}
#main {
  // min-width: 78.125vw;
}
// @media screen and (min-width: 75vw) {
.jy {
  font-size: 0.833vw;
  margin-top: 1.042vw;
  color: #fff;
  padding-bottom: 2.083vw;
}
.bottom {
  a {
    text-decoration: none;
  }
  > div:nth-child(1) {
    padding: 2.083vw 6.25vw;
    display: flex;
    .bottom_right {
      // flex: 1;
      width: 31.25vw;
      height: 20.833vw;
    }
    .bottom_left {
      width: 67.708vw;

      height: 100%;
      .bottom_left_title {
        text-align: start;
        font-size: 2.5vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: medium;
        color: #ffffff;
        line-height: 3.49vw;
      }
      .bottom_left_content {
        text-align: start;
        height: 100%;
        display: flex;
      }
      .bottom_left_content_left {
        width: 30.208vw;
        height: 100%;
        padding-top: 2.083vw;
        font-size: 1.25vw;
        font-family: DIN-Bold, DIN;
        font-weight: medium;
        color: #ffffff;
        line-height: 1.51vw;
        div {
          margin-top: 1.042vw;
        }
        div:nth-child(1) {
          margin-top: 0;
        }
        i {
          font-size: 1.25vw;
        }
        span {
          margin-left: 1.042vw;
        }
        div:nth-child(4) {
          span {
            margin-left: 0;
            font-size: 1.25vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 1.719vw;
          }
        }
      }
      .bottom_left_content_right {
        padding-top: 1.979vw;

        // flex: 1;
        > div:nth-child(1) {
          font-size: 1.875vw;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: medium;
          color: #ffffff;
          line-height: 2.604vw;
          margin-top: 0.8vw;
        }
        div {
          margin-top: 1.042vw;
          font-size: 1.25vw;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: medium;
          color: #ffffff;
          line-height: 1.719vw;
        }
        div:nth-child(1) {
          margin-top: 0;
        }
        a {
          color: #ffffff;
        }
        // > div:nth-child(4) {
        //   font-size: 1.875vw;
        // }
      }
    }
  }
  > div:nth-child(2) {
    text-align: center;
    font-size: 1.25vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  width: 100%;
  // height: 0.271vw;
  min-width: 75vw;
  background: #003371;
  padding-top: 2.083vw;
}

.navbar_top {
  position: absolute;
  z-index: 10;
  padding: 0 6.25%;
  width: 100%;
  background: rgba(0, 52, 114, 0.1) !important;

  @media screen and (min-width: 51.563vw) {
    .show {
      color: #ffc11f !important;
    }

    .dropdown-menu {
      background: rgba(0, 52, 114, 0.8);

      > li > a {
        color: #ffffff;
      }

      > li > a:hover {
        color: #ffc11f;
        background: none;
      }
    }
  }

  @media screen and (max-width: 51.563vw) {
    background: #fff !important;
    // position: relative;

    .dropdown-menu {
      border: none;
    }
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
  }

  .container-fluid {
    border-color: #003472;
  }

  .collapse {
    justify-content: end;
  }

  .nav-item {
    color: #fff;

    > a {
      color: #fff;

      @media screen and (max-width: 51.563vw) {
        color: rgb(0, 0, 0);
      }
    }
  }
}

.nav_top {
  width: 100%;
  min-width: 75vw;
  padding: 0 6.25%;
  height: 2.031vw;
  background: #003472;
  font-size: 0.833vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.9vw;

  > div {
    color: #fff;
  }

  > div:nth-child(1) {
    float: left;
  }

  > div:nth-child(2) {
    float: right;
    // display: flex;
    // justify-content: end;
    width: 25.792vw;

    > .input {
      width: 9.375vw;
      position: relative;
      float: right;
      transform: translateY(25%);

      > input {
        float: left;
        outline: none;
        width: 9.531vw;
        height: 1.25vw;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 0.625vw;

        color: #fff;
      }

      > i {
        position: absolute;
        font-size: 0.833vw;
        right: 0.521vw;
        height: 1.25vw;
        top: 35%;
        transform: translateY(-50%);
      }
    }

    > .phone {
      float: right;
      flex: 1;
      margin-left: 1.042vw;
      font-size: 0.833vw;

      > span {
        margin-left: 0.26vw;
        font-size: 0.833vw;
      }
    }
  }
}
// }
</style>
