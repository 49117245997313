<template>
  <div v-if="flag">
    <div
      class="bg_top"
      :style="'background:url(' + banner[0].image + ') no-repeat 100%/cover'"
    >
      <h2>{{ banner[0].en || "product center" }}</h2>
      <h3>{{ banner[0].title || "新闻中心" }}</h3>
      <div v-html="banner[0].brief"></div>
    </div>
    <div class="wb">
      <div class="title">
        <div>{{ banner.title || "产品中心" }}</div>
        <div>
          <div
            v-for="item in nav"
            :key="item.id"
            :class="active_item == item.id ? 'active_item' : ''"
            @click="(active_item = item.id), (newsId = ''), (isList = true)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="newsList" v-if="isList">
      <div class="content_title">
        <h3>{{ title.title }}</h3>
        <h3>{{ title.dirname }}</h3>
      </div>
      <div
        class="content_item"
        v-for="item in newsList"
        :key="item.id"
        @click="toDetails(item.id)"
      >
        <img class="content_item_left" :src="item.image" />
        <!-- :style="'background: url(' + item.image + ') no-repeat 100%/cover  '" -->
        <div class="content_item_meide">
          <div>
            {{ item.title }}
          </div>
          <div>
            {{ item.briefly }}
          </div>
          <div>{{ item.createtime }}</div>
        </div>
        <div class="content_item_right">
          <div><i class="iconfont icon-ico_yueduliang"></i></div>
          <div>{{ item.views }}</div>
        </div>
      </div>
      <div class="page" v-if="total != 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="newsDetails" v-else>
      <h2>{{ detailsList.detail.title }}</h2>
      <h3>{{ detailsList.detail.subheading }}</h3>
      <div class="author">
        <div>发布时间：{{ detailsList.detail.createtime }}</div>
        <div>作者：{{ detailsList.detail.author }}</div>
      </div>
      <div class="main_body" v-html="detailsList.detail.content"></div>
      <div class="btn">
        <div
          @click="next(detailsList.pre.id)"
          :class="Object.keys(detailsList.pre).length != 0 ? 'color' : ''"
        >
          上一篇：{{
            Object.keys(detailsList.pre).length == 0
              ? "暂无"
              : detailsList.pre.title
          }}
        </div>
        <div
          @click="next(detailsList.next.id)"
          :class="Object.keys(detailsList.next).length != 0 ? 'color' : ''"
        >
          下一篇：{{
            Object.keys(detailsList.next).length == 0
              ? "暂无"
              : detailsList.next.title
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner, getChildrenNav } from '@/api/homeApi.js'
import { getNewsList, getDetails } from '@/api/news.js'
export default {
  data () {
    return {
      pageNum: 1,
      total: 10,
      pageSize: 10,
      active_item: 0,
      banner: {},
      nav: [],
      currentPage4: 1,
      isList: true,
      newsList: [],
      newsId: '',
      detailsList: [],
      flag: false,
      title: {}
    }
  },
  watch: {
    $route () {
      this.active_item = parseInt(this.$route.query.cid)
      this.isList = false

      if (this.$route.query.nid) {
        this.newsId = this.$route.query.nid
        this.getDetails()
        this.isList = false
      }
    },
    active_item () {
      this.nav.forEach(item => {
        if (item.id === this.active_item) {
          item.dirname = item.dirname.toUpperCase()
          this.title = item
          console.log(item, this.title)
        }
      })

      this.getNewsList()
    },
    pageNum () {
      this.getNewsList()
    },
    pageSize () {
      this.getNewsList()
    }
  },
  async created () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.active_item = parseInt(this.$route.query.cid)
    if (this.$route.query.nid) {
      this.newsId = this.$route.query.nid
      this.getDetails()
      this.isList = false
    }
    await this.getBanner()
    await this.getChildrenNav()
    this.nav.forEach(item => {
      if (item.id === this.active_item) {
        item.dirname = item.dirname.toUpperCase()
        this.title = item
      }
    })
    this.flag = true
  },
  methods: {

    next (id) {
      if (!id) return
      this.newsId = id
      this.getDetails()
    },
    // 获取新闻详情
    async getDetails () {
      const { data: res } = await getDetails({
        navId: this.active_item,
        newsId: this.newsId
      })
      this.detailsList = res

      const head = document.getElementsByTagName('head')
      const ArticleTitle = document.getElementsByName('ArticleTitle')
      const Author = document.getElementsByName('Author')
      const Description = document.getElementsByName('Description')
      const Keywords = document.getElementsByName('Keywords')
      if (Keywords.length === 0) {
        head[0].innerHTML = head[0].innerHTML + '<meta name="ArticleTitle" content="' + res.detail.seo_title + '"></meta>' + '<meta name="Author" content="' + res.detail.author + '"></meta>' +
          '<meta name="Description" content="' + res.detail.seo_description + '"></meta>' + '<meta name="Keywords" content="' + res.detail.seo_description + '"></meta>'
      } else {
        Keywords.forEach(item => {
          head[0].removeChild(item)
        })
        Description.forEach(item => {
          head[0].removeChild(item)
        })
        Author.forEach(item => {
          head[0].removeChild(item)
        })
        ArticleTitle.forEach(item => {
          head[0].removeChild(item)
        })

        head[0].innerHTML = head[0].innerHTML + '<meta name="ArticleTitle" content="' + res.detail.seo_title + '"></meta>' + '<meta name="Author" content="' + res.detail.author + '"></meta>' +
          '<meta name="Description" content="' + res.detail.seo_description + '"></meta>' + '<meta name="Keywords" content="' + res.detail.seo_description + '"></meta>'
      }
    },

    // 获取新闻列表
    async getNewsList () {
      const head = document.getElementsByTagName('head')
      const ArticleTitle = document.getElementsByName('ArticleTitle')
      const Author = document.getElementsByName('Author')
      const Description = document.getElementsByName('Description')
      const Keywords = document.getElementsByName('Keywords')
      Keywords.forEach(item => {
        head[0].removeChild(item)
      })
      Description.forEach(item => {
        head[0].removeChild(item)
      })

      Author.forEach(item => {
        head[0].removeChild(item)
      })
      ArticleTitle.forEach(item => {
        head[0].removeChild(item)
      })
      const { data: res } = await getNewsList({
        navId: this.active_item,
        page: this.pageNum,
        pageSize: this.pageSize
      })
      this.newsList = res.list
      this.total = res.count
      document.body.scrollTop = 500
      document.documentElement.scrollTop = 500
    },
    // 新闻列表页来的
    handleSizeChange (val) {
      this.pageSize = val
    },
    handleCurrentChange (val) {
      this.pageNum = val
    },

    async toDetails (id) {
      console.log(id)
      this.newsId = id
      await this.getDetails()
      this.isList = false
    },
    async getChildrenNav () {
      const { data: res } = await getChildrenNav({ navId: this.$route.query.fid })
      this.nav = res.nav
    },
    async getBanner () {
      const { data: res } = await getBanner({ navId: this.$route.query.fid })
      this.banner = res.banner
    }
  }

}
</script>

<style scoped lang="scss">
.newsDetails {
  position: relative;
  .btn {
    position: absolute;
    // left: 11.458vw;
    left: 0;
    border: none;

    bottom: 2.083vw;
    width: 76.198vw;
    cursor: auto;
    .color {
      color: #ffc11f !important;
    }
    > div:nth-child(1) {
      cursor: pointer;
      float: left;
      font-size: 0.833vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
    }
    > div:nth-child(2) {
      cursor: pointer;

      float: right;
      font-size: 0.833vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
      line-height: 1.146vw;
    }
  }
  > .main_body {
    text-align: start;
    font-size: 1.25vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #003472;
    line-height: 2.083vw;
  }
  > .author {
    display: flex;
    justify-content: center;
    > div {
      font-size: 0.833vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
      line-height: 1.146vw;
    }
    > div:nth-child(1) {
      margin-right: 0.86vw;
    }
  }
  h2 {
    font-size: 1.875vw;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #003472;
    line-height: 2.604vw;
  }
  h3 {
    font-size: 1.25vw;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #003472;
    line-height: 1.719vw;
  }
  // width: 100%;
  width: 75vw;
  margin: 0 auto;
  padding: 2.083vw 0 7.813vw 0;
}
.newsList {
  // width: 100%;
  width: 75vw;
  // height: 122.552vw;
  min-height: 26.042vw;
  // padding: 0 10.417vw;
  margin: 0 auto;
  .content_item {
    margin-top: 1.042vw;
    width: 79.167vw;
    height: 9.375vw;
    display: flex;
    border-bottom: 0.104vw solid rgba(0, 52, 114, 0.1);
    .content_item_left {
      width: 15.625vw;
      height: 8.333vw;
      border-radius: 0.417vw;
      margin-right: 1.042vw;
    }
    .content_item_meide {
      > div:nth-child(1) {
        font-size: 1.25vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #003472;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
      }
      > div:nth-child(2) {
        margin-top: 0.365vw;
        height: 3.125vw;
        font-size: 0.833vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #003472;
        line-height: 1.563vw;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
      }
      > div:nth-child(3) {
        margin-top: 0.885vw;
        font-size: 0.833vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #003472;
        line-height: 1.25vw;
      }
      text-align: start;
      width: 52.083vw;
      margin-right: 1.042vw;
    }
    .content_item_right {
      transform: translateY(25%);
      cursor: pointer;
      > div:nth-child(1) {
        margin-top: 0.221vw;
        i {
          font-size: 1.667vw;
        }
      }
      > div:nth-child(2) {
        font-size: 1.25vw;
        font-family: DIN-Regular, DIN;
        font-weight: 400;
        color: #003472;
        line-height: 1.51vw;
      }
      width: 5.208vw;
      height: 5.208vw;
      background: rgba(0, 52, 114, 0.05);
      border: 0.154vw solid #003472;
    }
  }
  .content_title {
    margin: 2.604vw 0;
    text-align: start;
    > h3:nth-child(1) {
      font-size: 3.333vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
      line-height: 4.688vw;
    }
    > h3:nth-child(2) {
      font-size: 1.875vw;
      font-family: DIN-RegularAlternate, DIN;
      font-weight: normal;
      color: #003472;
      line-height: 2.24vw;
    }
  }
}

.page {
  margin-top: 2.083vw;
  margin-bottom: 2.083vw;
}

.title {
  // width: 100%;
  width: 75vw;
  height: 4.167vw;
  // background: rgba(0, 52, 114, 0.1);
  // padding: 0 10.417vw;
  margin: 0 auto;
  > div:nth-child(2) {
    display: flex;
    float: right;
    cursor: pointer;

    .active_item {
      color: #ffc11f;
      border-bottom: 0.208vw solid #ffc11f;
    }
    > div {
      line-height: 4.167vw;
      padding: 0 0.521vw;
      margin: 0 0.521vw;
      height: 4.167vw;
      font-size: 1.25vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
    }
  }
  > div:nth-child(1) {
    float: left;
    width: 10vw;
    height: 3.49vw;
    font-size: 2.5vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #003472;
    line-height: 3.49vw;
  }
}
.bg_top {
  > h2 {
    font-size: 5vw;
    font-family: DIN-Light, DIN;
    font-weight: 300;
    color: #ffc11f;
    line-height: 6.094vw;
  }
  > h3 {
    font-size: 3.333vw;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ffc11f;
  }

  > div {
    margin-top: 2.083vw;
    font-size: 1.25vw;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ffc11f;
    line-height: 1.719vw;
  }
  .div2 {
    margin-top: 0;
  }
  width: 100%;
  min-width: 75vw;

  height: 30.469vw;
  background: url(@/static/img/q2.png) no-repeat;
  background-size: 100% 100%;
  padding-left: 10.417vw;
  padding-top: 7.813vw;
  text-align: start;
}
</style>
