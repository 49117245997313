import request from '@/utils/request' // 引入request.js

// 关于我们
export function getAbout (data) {
  return request({
    url: '/phone_about',
    method: 'get',
    params: data
  })
}
