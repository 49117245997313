<template>
  <div class="pro" v-if="flag">
    <div
      class="bg_top"
      :style="
        'background:url(' + banner[0].image + ') no-repeat 100%/cover ;  '
      "
    >
      <h2>{{ banner[0].en || "product center" }}</h2>
      <h3>{{ banner[0].title || "产品中心" }}</h3>
      <div v-html="banner[0].brief"></div>
      <!-- <div class="div2">
        {{
          banner.introduce
            ? banner.introduce.split(" ")[1]
            : " AI智慧教学、智慧体育平台、智慧校园体育建设、统一平台多系统四大核心业务"
        }}
      </div> -->
    </div>
    <div class="wb">
      <div class="title">
        <div>{{ banner.title || "产品中心" }}</div>
        <div>
          <div
            v-for="item in nav"
            :key="item.id"
            :class="active_item == item.id ? 'active_item' : ''"
            @click="check(item.id, item)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="content" v-if="isList">
      <div class="content_title">
        <h3>{{ title.title }}</h3>
        <h3>{{ title.dirname }}</h3>
      </div>
      <div class="ul" v-if="initList.length == 0"></div>
      <div class="ul" v-for="(item, index) in initList" :key="index + 'a'">
        <div
          v-for="(item1, index1) in item"
          :key="item1.id"
          @mouseenter="enter(item1.id)"
          @click="onDetail(item1.id)"
          @mouseleave="leave()"
          :class="
            move_item === item[item.length - 1].id &&
            index1 === 0 &&
            item.length === 6
              ? 'close_div'
              : move_item == item[index1].id
              ? 'active_div'
              : ''
          "
        >
          <div>
            <img :src="item1.image" :alt="item1.title" />
            <div>
              <div v-for="item4 in item1.tags.split(',')" :key="item4">
                <i class="icon-youshi iconfont"></i>{{ item4 }}
              </div>

              <div>{{ item1.edition }}</div>
            </div>
          </div>
          <div>{{ item1.title }}</div>
        </div>
      </div>

      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-size="18"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="content2" v-else>
      <el-carousel
        indicator-position="none"
        :interval="40000"
        type="card"
        height="41.667vw
      "
        @change="setIndex"
      >
        <el-carousel-item v-for="item3 in imgs.length" :key="item3">
          <div class="item">
            <img :src="imgs[item3 - 1]" :alt="imgs[item3 - 1].title" />
            <template v-if="active_index1 == item3 - 1"
              ><div style="margin-top: 2.083vw">{{ Detail.title }}</div>
              <div style="margin-top: 0.365vw">
                <span
                  v-for="(item5, index5) in Detail.tags"
                  :key="item5"
                  style="
                    color: #003371;
                    font-size: 1.25vw;
                    font-family: PingFangSC-Regular, PingFang SC;
                  "
                >
                  {{
                    index5 === Detail.tags.length - 1
                      ? "&nbsp;" + item5
                      : "&nbsp;" + item5 + "&nbsp;/"
                  }}
                </span>
              </div>
            </template>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-html="Detail.content"></div>
      <!-- <div class="detail">
        <div class="ts">
          <div>规格参数</div>
          <div>
            <div class="left">产品特色</div>
            <div class="right">
              <div>
                搭配三霍尔传感器，数据采集更精准，设置各种学校跳绳运动模式
                （计数、计时、自由跳）
              </div>
              <div>
                支持校内教师终端一键开启测试、一键调整模式，支持校内校外数据
                互通。
              </div>
              <div>
                智能计时、计数、独特顶部按钮手柄、搭配不同绳体，专属移动端程
                序，运动、锻炼、竞赛多种模式选择
              </div>
            </div>
          </div>
        </div>
        <div class="detail_item">
          <div>外观尺寸</div>
          <div>
            <div class="cs">
              <div>型号</div>
              <div>ZNZ-TSH-01</div>
            </div>
            <div class="cs">
              <div>尺寸</div>
              <div>165mm*24mm*24mm</div>
            </div>
            <div class="cs">
              <div>颜色</div>
              <div>黑色、白色、红色</div>
            </div>
          </div>
        </div>
        <div class="detail_item">
          <div>硬件配置</div>
          <div>
            <div class="cs">
              <div>产品名称</div>
              <div>ZNZ-TSH-01</div>
            </div>
            <div class="cs">
              <div>产品材质</div>
              <div>165mm*24mm*24mm</div>
            </div>
            <div class="cs">
              <div>电池容量</div>
              <div>黑色、白色、红色</div>
            </div>
            <div class="cs">
              <div>电池容量</div>
              <div>黑色、白色、红色</div>
            </div>
            <div class="cs">
              <div>电池容量</div>
              <div>黑色、白色、红色</div>
            </div>
            <div class="cs">
              <div>电池容量</div>
              <div>黑色、白色、红色</div>
            </div>
            <div class="cs">
              <div>电池容量</div>
              <div>黑色、白色、红色</div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 侧边栏 -->
    <div class="aside">
      <div
        :class="aside_active1 === 0 ? 'active' : ''"
        @click="
          aside_active1 === 0 ? (aside_active1 = '') : (aside_active1 = 0)
        "
        style="display: none"
      >
        <i class="icon-changyonglogo37 iconfont"></i>
        <div>App下载</div>
        <div class="ewm" v-show="aside_active1 === 0">
          <img :src="site.gongzhongh" />
        </div>
      </div>
      <div
        :class="aside_active1 == 1 ? 'active' : ''"
        @click="
          aside_active1 === 1 ? (aside_active1 = '') : (aside_active1 = 1)
        "
      >
        <i class="icon-weixingongzhonghao iconfont"></i>
        <div>微信公众号</div>
        <div class="ewm" v-show="aside_active1 === 1">
          <img :src="site.gongzhongh" />
        </div>
      </div>
      <div
        :class="aside_active1 == 2 ? 'active' : ''"
        @click="
          aside_active1 === 2 ? (aside_active1 = '') : (aside_active1 = 2)
        "
      >
        <i class="icon-xiaochengxu iconfont"></i>
        <div>微信小程序</div>
        <div class="ewm" v-show="aside_active === 2">
          <img :src="site.xiaochengxu" />
        </div>
      </div>
      <div
        :class="aside_active1 == 3 ? 'active' : ''"
        @click="
          aside_active1 === 3 ? (aside_active1 = '') : (aside_active1 = 3)
        "
      >
        <i class="icon-weixin iconfont"></i>
        <div>微信</div>
        <div class="ewm" v-show="aside_active1 === 3">
          <img :src="site.Wx" />
        </div>
      </div>
      <div
        @click="
          aside_active1 === 4 ? (aside_active1 = '') : (aside_active1 = 4);
          to('/m');
        "
      >
        <i class="icon-shouji iconfont"></i>
        <div>手机官网</div>
      </div>
      <div
        :class="aside_active1 == 5 ? 'active' : ''"
        @click="
          aside_active1 === 5 ? (aside_active1 = '') : (aside_active1 = 5)
        "
      >
        <i class="icon-zaixiankefux iconfont"></i>
        <div>在线咨询</div>
      </div>
      <div
        @click="
          () => {
            (aside_active1 = 6), topFunction();
          }
        "
      >
        <i class="icon-icon-test iconfont"></i>
        <div>回到顶部</div>
      </div>
      <div class="minwindow" v-if="aside_active1 == 5">
        <div class="minwindow_title">
          <span>山东指南针体育产业有限公司</span>
          <span><i class="iconfont icon-liuyanmoban"></i></span>
          <span><i class="iconfont icon-weixin active"></i></span>
          <span @click="aside_active1 = ''">
            <i class="iconfont icon-guanbi"></i
          ></span>
        </div>
        <div class="minwindow_content">
          <div class="content_title">在线留言</div>
          <div>
            <div>
              <span>您的名字:</span
              ><input type="text" v-model="leave_msg.name" />
            </div>
            <div>
              <span>您的电话:</span
              ><input type="text" v-model="leave_msg.phone" />
            </div>
            <div>
              <span>公司名称:</span
              ><input type="text" v-model="leave_msg.company" />
            </div>
            <div>
              <span>您的邮箱:</span
              ><input type="text" v-model="leave_msg.Email" />
            </div>
            <div>
              <span>公司地址:</span
              ><input type="text" v-model="leave_msg.address" />
            </div>
            留言:
            <textarea cols="60" rows="10" v-model="leave_msg.msg"></textarea>
            <!-- <div><span>验证码:</span><input type="text" id="input1" /></div>
            <div>
              <input
                type="button"
                id="checkCode"
                class="code"
                style="width: 3.125vw"
                @click="createCode"
              />
            </div> -->
            <button @click="leaveMessage">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner, getChildrenNav, getSite, leaveMessage } from '@/api/homeApi.js'
import { getProductList, getProductDetail } from '@/api/productApi'
let code

export default {
  data () {
    return {
      title: {},
      // active_item: 0,
      // aside_active: '',
      // currentPage4: 100,
      leave_msg: {
        name: '',
        phone: '',
        company: '',
        address: '',
        msg: ''
      },
      site: {},
      banner: {},
      nav: [],
      isList: true,
      active_item: 0,
      move_item: '',
      cd: 6,
      aside_active1: '',
      aside_active: '',
      currentPage4: 1,
      page: 1,
      total: 18,
      list: [],
      last: 0,
      initList: [],
      active_index1: 0,
      Detail: {},
      productId: '',
      imgs: [],
      flag: false
    }
  },
  watch: {
    $route () {
      this.isList = true
      this.aside_active = parseInt(this.$route.query.cid)
      this.active_item = this.$route.query.cid
    },
    aside_active (n) {
      this.getChildrenNav()

      this.nav.forEach(item => {
        if (item.id === n) {
          item.dirname = item.dirname.toUpperCase()
          this.title = item
        }
      })
      this.getProductList()
    },
    productId () {
      this.getProductDetail()
    },
    aside_active1 (item1) {
      if (item1 === 5) {
        setTimeout(() => {
          this.createCode()
        }, 500)
      } else {
        this.leave_msg = {
          name: '',
          phone: '',
          company: '',
          address: '',
          msg: ''
        }
      }
    }
  },
  async created () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.active_item = this.$route.query.cid
    this.aside_active = parseInt(this.$route.query.cid)
    if (this.$route.query.did) {
      this.isList = false
      this.productId = this.$route.query.did
    }

    await this.getSite()
    await this.getBanner()
    await this.getChildrenNav()
    this.nav.forEach(item => {
      if (item.id === parseInt(this.aside_active)) {
        item.dirname = item.dirname.toUpperCase()
        this.title = item
      }
    })
    this.flag = true
  },
  methods: {
    to (path) {
      if (document.documentElement.clientWidth < 1400) {
        this.$router.push('/m').catch(() => {
        })
      } else {
        this.$router.push('/').catch(() => {
        })
      }
    },
    createCode () {
      code = []
      const codeLength = 4 // 验证码的长度
      const checkCode = document.getElementById('checkCode')
      checkCode.value = ''
      const selectChar = [2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      for (let i = 0; i < codeLength; i++) {
        const charIndex = Math.floor(Math.random() * 32)
        code += selectChar[charIndex]
      }
      if (code.length !== codeLength) {
        this.createCode()
      }
      checkCode.value = code
    },
    validate () {
      const inputCode = document.getElementById('input1').value.toUpperCase()
      if (inputCode.length <= 0) {
        alert('请输入验证码！')
        return false
      } else if (inputCode !== code) {
        alert('验证码输入错误！')
        this.createCode()
        return false
      } else {
        return true
      }
    },
    async leaveMessage () {
      if (this.leave_msg.msg === '' || this.leave_msg.name === '' || this.leave_msg.phone === '' || this.leave_msg.Email === '') {
        return this.$message({
          message: '请正确填写信息',
          type: 'warning'
        })
      }
      const telStr = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
      const yxStr = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      // if (this.validate()) {
      if (!(yxStr.test(this.leave_msg.Email))) {
        return this.$message({
          message: '邮箱输入不规范',
          type: 'warning'
        })
      }
      if (!(telStr.test(this.leave_msg.phone))) {
        return this.$message({
          message: '手机号码输入不规范',
          type: 'warning'
        })
      }
      const res = await leaveMessage(this.leave_msg)
      this.$message({
        message: '留言成功！',
        type: 'success'
      })
      this.leave_msg.msg = ''
      this.leave_msg.name = ''
      this.leave_msg.phone = ''
      this.leave_msg.Email = ''
      this.leave_msg.address = ''
      this.leave_msg.company = ''
      // }
    },

    // 获取侧边二维码
    async getSite () {
      const { data: res } = await getSite()
      this.site = res.site
    },
    onDetail (id) {
      // const url = window.location.href.split('top')[0] + 'top/productcenter?fid=5&cid=' + this.active_item + '&did=' + id
      const url = window.location.href.split('com')[0] + 'com/productcenter?fid=5&cid=' + this.active_item + '&did=' + id

      window.open(url, '_blank')

      // this.productId = id
      // this.getProductDetail()
      // this.move_item = ''
      // this.isList = false
    },
    async getProductDetail () {
      const { data: res } = await getProductDetail({ productId: this.productId })
      res.Detail.content = this.replaceHhf(res.Detail)
      res.Detail.tags = res.Detail.tags.split(',')
      this.Detail = res.Detail
      this.imgs = res.Detail.images.split(',')
    },
    setIndex (index) {
      this.active_index1 = index
    },
    async getProductList () {
      this.initList = []

      const { data: res } = await getProductList({
        navId: this.aside_active,
        page: this.page,
        pageSize: 18
      })
      this.list = res.list
      const cd = Math.ceil(this.list.length / 6)
      for (let i = 0; i < cd; i++) {
        this.initList.push(this.list.slice(i * 6, i * 6 + 6))
      }
      // this.initList.forEach(item => {
      //   item.forEach(item1 => {
      //     item1.initTags = item1.tags.split(',')
      //   })
      // })
      this.total = res.count
    },
    handleSizeChange () { },
    handleCurrentChange () { },
    topFunction () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    enter (index) {
      this.move_item = index
    },
    leave () {
      this.move_item = ''
    },
    check (id, item) {
      item.dirname = item.dirname.toUpperCase()
      this.title = item
      this.active_item = id
      this.aside_active = id
      this.$route.query.cid = this.active_item
      this.isList = true
    },
    async getChildrenNav () {
      const { data: res } = await getChildrenNav({ navId: this.$route.query.fid })
      this.nav = res.nav
    },
    async getBanner () {
      const { data: res } = await getBanner({ navId: this.$route.query.fid })
      this.banner = res.banner
    }
  }

}
</script>

<style scoped lang="scss">
::v-deep .el-carousel__arrow,
::v-deep .el-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0) !important;
  font-size: 3.333vw;
  color: rgb(0, 52, 114);
}
::v-deep .el-icon-arrow-left {
  position: relative;
  left: -1.302vw;
}

.content2 {
  > div:nth-child(1) {
    > .el-carousel__indicators--outside {
      display: none !important;
    }
  }
  .item {
    margin-top: 4.167vw;
    img {
      width: 29.167vw;
      height: 29.167vw;
    }
    div:nth-child(2) {
      font-size: 2.5vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003371;
    }
  }
  width: 75vw;
  margin: 0 auto;
}
.content {
  // width: 100%;
  width: 75vw;
  // padding: 0 10.417vw;
  margin: 0 auto;
  .ul {
    display: flex;
    width: 75vw;
    overflow: hidden;
    height: 18.229vw;
    min-height: 18.229vw;
    z-index: 2;
    .active_div {
      width: 24.167vw;
      > div:nth-child(1) {
        width: 24.167vw;
        background: none;
        border: 0.417vw solid #ffc11f;

        img {
          left: 17vw;
        }
        > div {
          display: block;
        }
      }
      > div:nth-child(2) {
        color: #ffc11f;
      }
    }
    .close_div {
      transition: all 2s;
      overflow: hidden;
      margin-right: 0;
      div {
        width: 0.521vw;
      }
      > div:nth-child(1) {
        width: 0;
      }
      > div:nth-child(2) {
        display: none;
        opacity: 0;
      }
    }

    > div {
      cursor: pointer;
      margin-right: 1.25vw;
      transition: all 2s;

      width: 11.458vw;
      > div:nth-child(1) {
        position: relative;
        overflow: hidden;
        width: 11.458vw;
        // width: 25.625vw;
        transition: width 2s;
        height: 15.625vw;
        // background: rgba(229, 229, 229);
        box-shadow: inset 0vw 0vw 1.25vw 0vw rgba(255, 193, 31, 0.4);
        // border: 0.417vw solid #ffc11f;
        background: url("@/static/img/cpbg.png") no-repeat;

        background-size: 100% 100%;
        > img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 9.375vw;
          height: 11.979vw;

          // z-index: 5;
          // padding: 0.625vw 1.406vw;
          // position: absolute;
          // // top: 1.083vw;
          // left: 0.26vw;
          // top: 50%;
          // transform: translateY(-50%);
          // // width: 9.406vw;
          // // // height: 11.892vw;
          // // height: 100%;
          // width: 9.375vw;
          // height: 11.979vw;
        }
        > div {
          padding: 40px;
          text-align: start;
          position: absolute;
          height: 100%;
          display: none;
          width: 14.583vw;
          // width: 9.583vw;
          // left: 10vw;
          left: 0.26;

          > div:nth-child(1) {
            margin-top: 0;
          }
          > div {
            margin-top: 0.521vw;
            font-size: 0.833vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #003472;
            line-height: 1.146vw;
            i {
              color: red;
              margin-right: 0.521vw;
            }
          }
          > div:nth-last-child(1) {
            // margin-top: 5.333vw;
            position: absolute;
            bottom: 3.125vw;
            font-size: 1.25vw !important;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #003472;
            line-height: 1.719vw !important;
          }
        }
      }
      > div:nth-child(2) {
        width: 11.458vw;

        margin-top: 10px;
        height: 1.302vw;
        color: #003472;
      }
    }
    > div:nth-last-child(1) {
      margin-right: 0;
    }
  }

  .content_title {
    margin: 2.604vw 0;
    text-align: start;
    > h3:nth-child(1) {
      font-size: 3.333vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
      line-height: 4.688vw;
    }
    > h3:nth-child(2),
    h3:nth-child(3) {
      font-size: 1.875vw;
      font-family: DIN-RegularAlternate, DIN;
      font-weight: normal;
      color: #003472;
      line-height: 2.24vw;
    }
  }
}
.aside {
  z-index: 999;
  .minwindow {
    .code {
      background-image: url("@/static/img/bg3.png");
      font-family: Arial, 宋体;
      font-style: italic;
      color: green;
      border: 0;
      padding: 0.104vw 0.156vw;
      letter-spacing: 0.156vw;
      font-weight: bolder;
    }
    .unchanged {
      border: 0;
    }

    .minwindow_content {
      > div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 1.042vw;
        textarea {
          margin-bottom: 1.042vw;
          width: 100%;
          resize: none;
        }
        div {
          margin-bottom: 1.042vw;
          width: 10.417vw;
          height: 2.083vw;
          display: flex;
          align-content: center;
          flex-wrap: nowrap;
          border: 0.052vw solid #003472;
          > span {
            padding: 0.521vw;
            white-space: nowrap;
            font-size: 0.625vw;
            background: rgba(0, 52, 114, 0.1);
          }
          > input {
            display: inline-block;
            width: 5.729vw;
            flex: 1;
            background: rgb(217, 225, 233);
            outline: none;
            border: none;
          }
        }
        div:nth-child(5) {
          width: 100%;
        }
        > div:nth-last-child(2) {
          width: 5.208vw;
          height: 2.083vw;
          margin-right: 5.208vw;
          img {
            width: 100%;
          }
        }
        button {
          width: 6.25vw;
          margin: 0.261vw auto;
          border: none;
          font-size: 0.833vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          height: 1.875vw;
          background: #ffc11f;
          box-shadow: 0vw 0vw 0.208vw 0vw #003472;
          border-radius: 0.417vw;
        }
      }
      .content_title {
        margin: 1.042vw auto;
        width: 5vw;
        height: 1.719vw;
        font-size: 1.25vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #003472;
        line-height: 1.719vw;
      }
    }
    .minwindow_title {
      padding: 0 1.094vw;
      span {
        font-size: 1.25vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 1.719vw;
      }
      .active {
        background: #fff;
        color: #003371;
      }
      i {
        display: inline-block;
        font-size: 1.354vw;
        color: #fff;
        width: 1.563vw;
        height: 1.563vw;
        border-radius: 0.104vw;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      width: 25vw;
      height: 3.333vw;
      background: #003472;
      border-radius: 0.417vw 0.417vw 0vw 0vw;
    }
    position: fixed;
    right: 5.208vw;
    width: 25vw;
    height: 33.333vw;
    top: 13.021vw;
    background: #f2f5f8;
    box-shadow: 0vw 0.104vw 0.208vw 0vw #003472;
    border-radius: 0.417vw;
    background-color: rgb(242, 245, 248);
  }
  position: fixed;
  z-index: 5;
  // height: 29.167vw;
  width: 4.167vw;
  right: 0;
  top: 15.625vw;
  background-color: rgb(229, 235, 241);
  > div {
    position: relative;
  }
  .ewm {
    position: absolute;
    left: -7.813vw;
    width: 7.813vw;
    height: 7.813vw;
    top: 0;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  > div {
    cursor: pointer;
    height: 4.167vw;
    width: 4.167vw;
    text-align: center;

    > i {
      color: #003472;
      font-size: 1.825vw;
    }
    > div:nth-child(2) {
      @media screen and (max-width: 78.125vw) {
        font-size: 0.625vw;
        transform: scale(0.8);
      }
      @media screen and (max-width: 75.521vw) {
        font-size: 0.625vw;
        transform: scale(0.7);
      }
    }
    > div {
      height: 0.885vw;
      font-size: 0.729vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003371;
      line-height: 0.885vw;
    }
  }
  > .active {
    background: #003472;

    > i {
      color: rgb(229, 235, 241);
    }
    > div {
      color: rgb(229, 235, 241);
    }
  }
}
.page {
  margin-top: 2.083vw;
  margin-bottom: 2.083vw;
}

.title {
  // width: 100%;
  width: 75vw;
  height: 4.167vw;
  // background: rgba(0, 52, 114, 0.1);
  // padding: 0 10.417vw;
  // padding: 0 10.417vw;
  margin: 0 auto;
  box-sizing: content-box;

  > div:nth-child(2) {
    display: flex;
    float: right;
    cursor: pointer;

    .active_item {
      color: #ffc11f;
      border-bottom: 0.208vw solid #ffc11f;
    }
    > div {
      line-height: 4.167vw;
      // width: 8.25vw;
      padding: 0 0.521vw;
      margin: 0 0.521vw;
      height: 4.167vw;
      font-size: 1.25vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin: 0 1.042vw !important;
      color: #003472;
    }
  }
  > div:nth-child(1) {
    float: left;
    width: 10vw;
    height: 3.49vw;
    font-size: 2.5vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #003472;
    line-height: 3.49vw;
  }
}
.bg_top {
  > h2 {
    font-size: 5vw;
    font-family: DIN-Light, DIN;
    font-weight: 300;
    color: #ffc11f;
    line-height: 6.094vw;
  }
  > h3 {
    font-size: 3.333vw;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ffc11f;
  }

  > div {
    margin-top: 2.083vw;
    font-size: 1.25vw;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ffc11f;
    line-height: 1.719vw;
  }
  .div2 {
    margin-top: 0;
  }
  width: 100%;
  min-width: 75vw;
  height: 30.469vw;
  background: url(@/static/img/q2.png) no-repeat;
  background-size: 100% 100%;
  padding-left: 10.417vw;
  padding-top: 7.813vw;
  text-align: start;
}
</style>
