<template>
  <div id="main">
    <!-- 导航栏 -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light navbar_top">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style="color: red"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a class="logo" href="#" style="positon: relative" @click.stop="toHome"
          ><img
            v-if="!title"
            src="@/static/img/logo.png"
            class=""
            style="width: 25.885vw; height: 6.4vw; margin: 0 auto"
          />
          <h1 style="visibility: hidden; position: absolute; left: 0; top: 0">
            山东指南针体育产业有限公司
          </h1>
        </a>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                class="nav-link active"
                aria-current="page"
                style="cursor: pointer"
                @click="toHome"
                >首页</a
              >
            </li>
            <template v-for="item in nav">
              <li
                class="nav-item"
                v-if="item.childrens.length == 0"
                :key="item.id"
              >
                <a
                  class="nav-link active"
                  aria-current="page"
                  style="cursor: pointer"
                  @click="clickNav(item)"
                  >{{ item.title }}</a
                >
              </li>
              <li class="nav-item dropdown" v-else :key="item.id + '.'">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ item.title }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li v-for="item2 in item.childrens" :key="item2.id">
                    <a class="dropdown-item" @click="clickNav(item, item2)">{{
                      item2.title
                    }}</a>
                  </li>
                </ul>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </nav>
    <router-view></router-view>

    <!-- 底部 -->
    <div class="bottom">
      <div class="mip" id="map_container">
        <a
          href="https://uri.amap.com/marker?position=117.093784,36.68091&name=智能圆511"
        >
          <el-amap
            :center="center"
            :zoom="zoom"
            :dragEnable="false"
            class="amap_demo"
            :plugin="plugin"
          >
            <!-- 地图标记 -->
            <el-amap-marker
              :dragEnable="false"
              v-for="(marker, index) in markers"
              :position="marker.position"
              :key="index"
            ></el-amap-marker>
          </el-amap>
        </a>
      </div>
      <div>
        <div class="bottom_left">
          <div class="bottom_left_title">{{ site.title }}</div>
          <div>
            <i class="iconfont icon-24gl-telephone2"></i>
            <span>{{ site.phone }}</span>
          </div>
          <div>
            <i class="iconfont icon-QQ"></i>
            <span>{{ site.qq }}</span>
          </div>
          <div>
            <i class="iconfont icon-wodeye_diqu"></i>
            <span>{{ site.address }}</span>
          </div>
          <div>
            <span>ICP备案号：{{ site.beian }}</span>
          </div>
          <div>Copyright © 2012-2018 {{ site.title }} 版权所有</div>
          <div><a :href="site.clause">隐私协议</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNavList, getSEO, getChildrenNav, getBanner, getSite } from '@/api/homeApi.js'

export default {
  name: 'mHomeView',
  data () {
    const that = this

    return {
      title: '',
      site: {},
      nav: [],
      zoom: 20,
      // 默认中心点
      center: [117.093784, 36.68091],
      // 标记点
      markers: [
        // 标记点位置
        { position: [117.093784, 36.68091] }
      ],
      // 当前地图的插件
      plugin: [{

        enableHighAccuracy: true, // 是否使用高精度定位，默认:true
        timeout: 10000, // 超过10秒后停止定位，默认：无穷大
        maximumAge: 0, // 定位结果缓存0毫秒，默认：0
        convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
        showButton: true, // 显示定位按钮，默认：true
        buttonPosition: 'RB', // 定位按钮停靠位置，默认：'LB'，左下角
        showMarker: false, // 定位成功后在定位到的位置显示点标记，默认：true
        showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
        panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
        zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
        extensions: 'all',
        pName: 'Geolocation',

        events: {
          init (o) {
          }
        }
      }],

      aside_active: '',
      dot_active: 0,
      timer: null,
      canvas: null,
      ctx: null,
      w: null,
      h: null,
      fontSize: null,
      colunms: null,
      drops: null,
      str: null
    }
  },
  mounted () {

  },
  created () {
    this.getNavList()
    this.getSite()
  },
  methods: {
    // 获取侧边二维码
    async getSite () {
      const { data: res } = await getSite()
      this.site = res.site
    },
    async getNavList () {
      const { data: res } = await getNavList()
      this.nav = res.nav
    },
    clickNav (fitem, citem = '') {
      if (fitem.is_part_data === '0' || citem.is_part_data === '0') {
        if (fitem.id === 9) {
          // 企业介绍
          this.$router.push('/mcorporateinformation?fid=' + fitem.id + '&cid=' + citem.id).catch(() => {
          })
        } else if (fitem.id === 20) {
          // 合作案例
          this.$router.push('/mcooperation?fid=' + fitem.id + '&cid=' + citem.id).catch(() => {
          })
        } else if (fitem.id === 21) {
          // 联系我们
        } else if (fitem.id === 22) {
          // 在线预约
        } else if (fitem.id === 5) {
          // 产品中心
          this.$router.push('/mproductcenter?cid=' + citem.id + '&fid=' + fitem.id).catch(() => { })
        } else if (fitem.id === 10) {
          // 解决方案
          this.$router.push('/msolution?fid=' + fitem.id + '&cid=' + citem.id).catch(() => {
          })
        } else if (fitem.id === 16) {
          // 新闻咨询
          this.$router.push('/mjournalism?cid=' + citem.id + '&fid=' + fitem.id).catch(() => { })
        }
      } else {
        window.open('http://' + citem.typelink || fitem.typelink + '/', '_blank')
      }
    },

    toHome () {
      this.$router.push('/mwelcome').catch(() => {
        //  console.log(err)
      })
    }

  },
  components: {
  }

}
</script>

<style lang="scss" scoped>
#main {
  background: rgba(0, 52, 114, 0.05);
}
.dropdown-item {
  text-align: center;
}
.navbar-toggler-icon {
  color: #fff;
}
.navbar-toggler:focus,
.navbar-toggler:active:focus {
  //消除button的默认样式
  border: none !important; //去除边框
  outline: none !important; //消除默认点击蓝色边框效果
  box-shadow: none !important;
  outline: none !important; //消除默认点击蓝色边框效果
}

.bottom {
  width: 100%;
  height: 127.733vw;
  background: #003472;
  padding-top: 5.333vw;
  padding: 5.333vw 8vw;
  .mip {
    border-radius: 1.333vw;
    overflow: hidden;
    width: 85.333vw;
    height: 53.333vw;
  }
  .bottom_left {
    a {
      color: #fff;
      text-decoration: none;
    }
    div {
      color: #fff;
      margin: 2.667vw 0;

      font-size: 3.2vw;
    }
    .bottom_left_title {
      font-size: 4.267vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #ffffff;
      line-height: 5.867vw;
    }
    text-align: left;
  }
}

.navbar {
  padding: 0;
}
.container-fluid {
  width: 100vw;
  padding: 0;
}
.navbar_top {
  * {
    color: #fff !important;
  }
}
.navbar-collapse,
.dropdown-menu {
  width: 100vw !important;
  background-color: rgb(2, 41, 90);
}
.logo {
  position: relative;
  left: -8vw;
  margin: 0 auto;
}
.container-fluid {
  padding: 0;
}
#main {
  // min-width: 400vw;
  width: 100vw;
}
// @media screen and (min-width: 384vw) {
.jy {
  font-size: 4.265vw;
  margin-top: 5.335vw;
  color: #fff;
  padding-bottom: 10.665vw;
}

.navbar_top {
  line-height: 11.733vw;
  position: absolute;
  z-index: 10;
  // padding: 0 6.25%;
  width: 100vw;
  // height: 11.733vw;
  background: rgb(2, 41, 90) !important;
  @media screen and (min-width: 264.003vw) {
    .show {
      color: #ffc11f !important;
    }

    .dropdown-menu {
      background: rgba(0, 52, 114, 0.8);

      > li > a {
        color: #ffffff;
      }

      > li > a:hover {
        color: #ffc11f;
        background: none;
      }
    }
  }

  @media screen and (max-width: 264.003vw) {
    // background: #fff !important;
    // position: relative;

    .dropdown-menu {
      border: none;
    }
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
  }

  .container-fluid {
    border-color: #003472;
  }

  .collapse {
    justify-content: end;
  }

  .nav-item {
    color: #fff;

    > a {
      color: #fff;

      @media screen and (max-width: 264.003vw) {
        color: rgb(0, 0, 0);
      }
    }
  }
}
</style>
