<template>
  <div class="mproduct-conent" v-if="flag">
    <div class="mproduct_list" v-if="isList">
      <img :src="banner[0].image" />
      <div class="scroll" style="overflow-x: auto">
        <div
          class="items"
          id="items"
          :style="'width: ' + 30 * nav.length + 'vw'"
        >
          <span
            v-for="item in nav"
            :key="item.id"
            :class="active_item == item.id ? 'active item' : 'item'"
            @click="check(item.id)"
          >
            {{ item.title }}
          </span>
          <!--
          <span class="item active">智能体测设备</span>
          <span class="item">运动心率臂带</span>
          <span class="item">智慧跳绳</span> -->
        </div>
      </div>
      <div class="pro_content">
        <div v-for="item in list" :key="item.id" @click="onDetail(item.id)">
          <img :src="item.image" />
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="mproduct_detail" v-if="!isList">
      <el-carousel
        :interval="4000"
        type="card"
        height="41.667vw
      "
        @change="setIndex"
      >
        <el-carousel-item v-for="item3 in 3" :key="item3">
          <div class="item">
            <!-- <img :src="imgs[item3 - 1]" /> -->
            <img :src="imgs[0]" />
            <template v-if="active_index1 == item3 - 1"
              ><div>{{ Detail.title }}</div>
              <!-- <div v-html="Detail.content"></div -->
              <!-- > -->
            </template>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-html="Detail.content"></div>
      <!-- <div class="detail">
        <div class="feature">
          <div class="feature_left">产品特色</div>
          <div class="feature_right">
            <div>
              搭配三霍尔传感器，数据采集更精准，设置各种学校跳绳运动模式（计数、计时、自由跳）
            </div>
            <div>
              支持校内教师终端一键开启测试、一键调整模式，支持校内校外数据互通。
            </div>
            <div>
              智能计时、计数、独特顶部按钮手柄、搭配不同绳体，专属移动端程序，运动、锻炼、竞赛多种模式选择
            </div>
          </div>
        </div>
        <div class="cs">
          <div class="cs_title">硬件配置</div>
          <div class="cs_item">
            <div>产品名称</div>
            <div>指南针智能跳绳</div>
          </div>
          <div class="cs_item">
            <div>产品名称</div>
            <div>指南针智能跳绳</div>
          </div>
          <div class="cs_item">
            <div>产品名称</div>
            <div>指南针智能跳绳</div>
          </div>
        </div>
        <div class="cs">
          <div class="cs_title">硬件配置</div>
          <div class="cs_item">
            <div>产品名称</div>
            <div>指南针智能跳绳</div>
          </div>
        </div>
        <div class="cs">
          <div class="cs_title">硬件配置</div>
          <div class="cs_item">
            <div>产品名称</div>
            <div>指南针智能跳绳</div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 侧边栏 -->
    <div class="aside">
      <!-- <div
        :class="aside_active === 0 ? 'active' : ''"
        @click="aside_active === 0 ? (aside_active = '') : (aside_active = 0)"
      >
        <i class="icon-changyonglogo37 iconfont"></i>
        <div>App下载</div>
        <div class="ewm" v-show="aside_active === 0">
          <img :src="site.gongzhongh" />
        </div>
      </div> -->
      <div
        :class="aside_active1 == 1 ? 'active' : ''"
        @click="
          aside_active1 === 1 ? (aside_active1 = '') : (aside_active1 = 1)
        "
      >
        <i class="icon-weixingongzhonghao iconfont"></i>
        <div>微信公众号</div>
        <div class="ewm" v-show="aside_active1 === 1">
          <img :src="site.gongzhongh" />
        </div>
      </div>
      <div
        :class="aside_active1 == 2 ? 'active' : ''"
        @click="
          aside_active1 === 2 ? (aside_active1 = '') : (aside_active1 = 2)
        "
      >
        <i class="icon-xiaochengxu iconfont"></i>
        <div>微信小程序</div>
        <div class="ewm" v-show="aside_active1 === 2">
          <img :src="site.xiaochengxu" />
        </div>
      </div>
      <div
        :class="aside_active1 == 3 ? 'active' : ''"
        @click="
          aside_active1 === 3 ? (aside_active1 = '') : (aside_active1 = 3)
        "
      >
        <i class="icon-weixin iconfont"></i>
        <div>微信</div>
        <div class="ewm" v-show="aside_active1 === 3">
          <img :src="site.Wx" />
        </div>
      </div>
      <!-- <div
        :class="aside_active == 4 ? 'active' : ''"
        @click="aside_active === 4 ? (aside_active = '') : (aside_active = 4)"
      >
        <i class="icon-shouji iconfont"></i>
        <div>手机官网</div>
      </div> -->
      <div
        :class="aside_active1 == 5 ? 'active' : ''"
        @click="
          aside_active1 === 5 ? (aside_active1 = '') : (aside_active1 = 5)
        "
      >
        <i class="icon-zaixiankefux iconfont"></i>
        <div>在线咨询</div>
      </div>
      <!-- <div
        @click="
          () => {
            (aside_active = 6), topFunction();
          }
        "
      >
        <i class="icon-icon-test iconfont"></i>
        <div>回到顶部</div>
      </div> -->
      <div class="minwindow" v-if="aside_active1 == 5">
        <div class="title">
          <span>山东指南针体育产业有限公司</span>
          <span><i class="iconfont icon-liuyanmoban"></i></span>
          <span><i class="iconfont icon-weixin active"></i></span>
          <span @click="aside_active1 = ''">
            <i class="iconfont icon-guanbi"></i
          ></span>
        </div>
        <div class="content">
          <div class="content_title">在线留言</div>
          <div>
            <div>
              <span>您的名字:</span
              ><input type="text" v-model="leave_msg.name" />
            </div>
            <div>
              <span>您的电话:</span
              ><input type="text" v-model="leave_msg.phone" />
            </div>
            <div>
              <span>公司名称:</span
              ><input type="text" v-model="leave_msg.company" />
            </div>
            <div>
              <span>您的邮箱:</span
              ><input type="text" v-model="leave_msg.Email" />
            </div>
            <div>
              <span>公司地址:</span
              ><input type="text" v-model="leave_msg.address" />
            </div>
            <div class="ly">
              <span>留&nbsp;&nbsp;&nbsp;&nbsp;言:</span>
              <textarea cols="60" rows="10" v-model="leave_msg.msg"></textarea>
            </div>
            <!-- <div class="yzm">
              <span>验&nbsp;证&nbsp;码:</span><input type="text" id="input1" />
            </div>
            <div class="tp">
              <input
                type="button"
                id="checkCode"
                class="code"
                style="width: 16vw"
                @click="createCode"
              />
            </div> -->
            <button @click="leaveMessage">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner, getChildrenNav, getSite, leaveMessage } from '@/api/homeApi.js'
import { getProductList, getProductDetail } from '@/api/productApi'
let code

export default {
  data () {
    return {
      // active_item: 0,
      // aside_active: '',
      // currentPage4: 100,
      aside_active1: '',
      leave_msg: {
        name: '',
        phone: '',
        company: '',
        address: '',
        msg: ''
      },
      site: {},
      banner: {},
      nav: [],
      isList: true,
      active_item: 0,
      move_item: '',
      cd: 6,
      aside_active: '',
      currentPage4: 1,
      page: 1,
      total: 18,
      list: [],
      last: 0,
      initList: [],
      active_index1: 0,
      Detail: {},
      productId: '',
      imgs: [],
      flag: false
    }
  },
  watch: {
    $route () {
      this.isList = true
      this.aside_active = this.$route.query.cid
      this.active_item = this.$route.query.cid
    },
    aside_active () {
      this.getProductList()
    },
    productId () {
      this.getProductDetail()
    },
    aside_active1 (item1) {
      if (item1 === 5) {
        setTimeout(() => {
          this.createCode()
        }, 500)
      } else {
        this.leave_msg = {
          name: '',
          phone: '',
          company: '',
          address: '',
          msg: ''
        }
      }
    }
  },
  async created () {
    this.active_item = this.$route.query.cid
    this.aside_active = this.$route.query.cid
    if (this.$route.query.did) {
      this.isList = false
      this.productId = this.$route.query.did
    }

    await this.getSite()
    await this.getBanner()
    await this.getChildrenNav()
    this.flag = true
  },
  methods: {
    createCode () {
      code = []
      const codeLength = 4 // 验证码的长度
      const checkCode = document.getElementById('checkCode')
      checkCode.value = ''
      const selectChar = [2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      for (let i = 0; i < codeLength; i++) {
        const charIndex = Math.floor(Math.random() * 32)
        code += selectChar[charIndex]
      }
      if (code.length !== codeLength) {
        this.createCode()
      }
      checkCode.value = code
    },
    validate () {
      const inputCode = document.getElementById('input1').value.toUpperCase()
      if (inputCode.length <= 0) {
        alert('请输入验证码！')
        return false
      } else if (inputCode !== code) {
        alert('验证码输入错误！')
        this.createCode()
        return false
      } else {
        return true
      }
    },
    async leaveMessage () {
      if (this.leave_msg.msg === '' || this.leave_msg.name === '' || this.leave_msg.phone === '' || this.leave_msg.Email === '') {
        return this.$message({
          message: '请正确填写信息',
          type: 'warning'
        })
      }
      const telStr = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
      const yxStr = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      // if (this.validate()) {
      if (!(yxStr.test(this.leave_msg.Email))) {
        return this.$message({
          message: '邮箱输入不规范',
          type: 'warning'
        })
      }
      if (!(telStr.test(this.leave_msg.phone))) {
        return this.$message({
          message: '手机号码输入不规范',
          type: 'warning'
        })
      }
      const res = await leaveMessage(this.leave_msg)
      this.$message({
        message: '留言成功！',
        type: 'success'
      })
      this.leave_msg.msg = ''
      this.leave_msg.name = ''
      this.leave_msg.phone = ''
      this.leave_msg.Email = ''
      this.leave_msg.address = ''
      this.leave_msg.company = ''
      // }
    },

    // 获取侧边二维码
    async getSite () {
      const { data: res } = await getSite()
      this.site = res.site
    },
    onDetail (id) {
      this.productId = id
      this.getProductDetail()
      this.move_item = ''
      this.isList = false
    },
    async getProductDetail () {
      const { data: res } = await getProductDetail({ productId: this.productId, pc: -1 })
      this.Detail = res.Detail

      this.imgs = res.Detail.images.split(',')
    },
    setIndex (index) {
      this.active_index1 = index
    },
    async getProductList () {
      this.initList = []

      const { data: res } = await getProductList({
        navId: this.aside_active,
        page: this.page,
        pageSize: 100
      })
      this.list = res.list
      // const cd = Math.ceil(this.list.length / 6)
      // for (let i = 0; i < cd; i++) {
      //   this.initList.push(this.list.slice(i * 6, i * 6 + 6))
      // }
      // this.initList.forEach(item => {
      //   item.forEach(item1 => {
      //     item1.initTags = item1.tags.split(',')
      //   })
      // })
      this.total = res.count
    },
    handleSizeChange () { },
    handleCurrentChange () { },
    topFunction () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    enter (index) {
      this.move_item = index
    },
    leave () {
      this.move_item = ''
    },
    check (id) {
      this.active_item = id
      this.aside_active = id
      this.$route.query.cid = this.active_item
      this.isList = true
    },
    async getChildrenNav () {
      const { data: res } = await getChildrenNav({ navId: this.$route.query.fid })
      this.nav = res.nav
    },
    async getBanner () {
      const { data: res } = await getBanner({ navId: this.$route.query.fid })
      this.banner = res.banner
    }
  }

}
</script>

<style scoped lang="scss">
.aside {
  position: fixed;
  z-index: 10;
  // height: 149.335vw;
  height: 50vw;
  width: 12.8vw;
  right: 0;
  top: 40vw;
  background-color: rgb(229, 235, 241);
  .minwindow {
    .code {
      background-image: url("@/static/img/bg3.png");
      font-family: Arial, 宋体;
      font-style: italic;
      color: green;
      border: 0;
      padding: 0.267vw 0.4vw;
      letter-spacing: 0.4vw;
      font-weight: bolder;
    }
    .unchanged {
      border: 0;
    }

    .content {
      .ly {
        height: 32vw !important;
      }
      > div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 2.667vw;
        // width: 70.667vw;
        margin: 0 auto;
        box-sizing: content-box;
        height: 200.8vw;
        textarea {
          margin-bottom: 2.667vw;
          width: 100%;
          height: 32vw;
          resize: none;
        }
        > div {
          margin-bottom: 2.667vw;
          // width: 100%;
          height: 5.333vw;
          display: flex;
          align-content: center;
          flex-wrap: nowrap;

          > span {
            padding: 0 1.333vw;
            white-space: nowrap;
            font-size: 3.2vw;
          }
          > input {
            display: inline-block;
            width: 62.2vw;
            height: 10.667vw;
            background: rgba(0, 52, 114, 0.1);
            border: 0.267vw solid #003472 !important;

            outline: none;
            border: none;
          }
        }
        div:nth-child(5) {
          width: 100%;
        }
        > div:nth-last-child(2) {
          height: 40px;
          margin-right: 100px;
          img {
            width: 100%;
          }
        }
        button {
          width: 120px;
          margin: 5px auto;
          border: none;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          height: 36px;
          background: #ffc11f;
          box-shadow: 0px 0px 4px 0px #003472;
          border-radius: 8px;
        }
      }
      .content_title {
        margin: 2.667vw auto;
        width: 12.8vw;
        height: 4.4vw;
        font-size: 3.2vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #003472;
        line-height: 4.4vw;
      }
      .yzm {
        input {
          width: 25vw !important;
        }
      }
      .tp {
        width: 25vw !important;
        margin-right: 0 !important;
      }
    }
    .title {
      padding: 0 2.8vw;
      span {
        font-size: 4.2vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 4.4vw;
      }
      > span:nth-child(1) {
        font-size: 3.2vw;
      }
      .active {
        background: #fff;
        color: #003371;
      }
      i {
        display: inline-block;
        font-size: 5.467vw;
        color: #fff;
        border-radius: 0.267vw;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      width: 100%;
      height: 11.733vw;
      background: #003472;
      border-radius: 1.067vw 1.067vw 0px 0px;
    }
    position: fixed;
    right: 6.667vw;
    width: 84vw;
    height: 162.133vw;
    top: 6.667vw;
    background: #f2f5f8;
    box-shadow: 0px 2px 4px 0px #003472;
    border-radius: 8px;
    background-color: rgb(242, 245, 248);
  }
  .ewm {
    position: absolute;
    left: -25.003vw;
    width: 25.067vw;
    height: 25.067vw;
    top: 0vw;
    > img {
      width: 100%;
      height: 100%;
    }
    background: red;
  }
  > div {
    cursor: pointer;
    height: 12.8vw;
    width: 12.8vw;

    text-align: center;

    > i {
      color: #003472;
      // font-size: 9.344vw;
    }
    > div:nth-child(2) {
      @media screen and (max-width: 400vw) {
        font-size: 3.2vw;
        transform: scale(0.8);
      }
      @media screen and (max-width: 386.667vw) {
        font-size: 3.2vw;
        transform: scale(0.7);
      }
    }
    > div {
      height: 4.531vw;
      font-size: 3.2vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003371;
      line-height: 4.531vw;
    }
  }
  > .active {
    background: #003472;

    > i {
      color: rgb(229, 235, 241);
    }
    > div {
      color: rgb(229, 235, 241);
    }
  }
}
.mproduct-conent {
  .el-carousel__container {
    height: 43.667vw !important;
  }
  .mproduct_detail {
    .el-carousel__item {
      // height: 52.167vw !important;
    }
    .el-carousel__mask {
      background: none !important;
    }
    // .el-carousel__indicators {
    //   display: none;
    // }
    .item {
      margin-top: 4.167vw;
      img {
        width: 29.167vw;
        height: 29.167vw;
      }
      div:nth-child(1) {
        margin-top: 2.083vw;
        font-size: 2.5vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #003472;
        line-height: 3.49vw;
      }
      div:nth-child(2) {
        margin-top: 2vw;
        font-size: 4.25vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #003371;
        // line-height: 1.719vw;
      }
    }
    width: 100%;
    padding: 0 8vw;
    padding-top: 18.667vw;
  }
  .mproduct_list {
    .pro_content {
      padding: 5.333vw 8vw;
      font-size: 3.2vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003371;
      line-height: 4.533vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        width: 40vw;
        height: 53.333vw;
        background: #ffffff;
        box-shadow: 0vw 0.533vw 1.067vw 0vw rgba(0, 51, 113, 0.5);
        border-radius: 2.133vw;
        margin-bottom: 5.333vw;
        > div {
          height: 10.667vw;
          // line-height: 10.667vw;

          margin: 2.1333vw 0;
          padding: 1.042vw 1.333vw;
          background: #ffffff;
          // box-shadow: 0vw 0.533vw 1.067vw 0vw rgba(0, 51, 113, 0.5);
          border-radius: 2.133vw;
          font-size: 2.133vw;
          line-height: 2.667vw;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      img {
        width: 40vw;
        height: 42.667vw;
      }
    }
    .jj_content {
      padding: 5.333vw 8vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      img {
        margin-bottom: 5.333vw;
        width: 40vw;
        height: 26.667vw;
      }
    }
    .scroll {
      margin-top: 5.333vw;
      width: 100vw;
      padding-left: 5.333vw;
      overflow: hidden;
      .items {
        text-align: start;
        // width: 133.333vw;
        // height: 10.667vw;
        display: flex;
        align-content: center;
      }
      .item {
        display: inline-block;
        padding: 0 2.133vw;
        font-size: 3.733vw;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #003371;
        line-height: 5.333vw;
      }
      .active {
        padding-bottom: 2.133vw;
        line-height: 6.667vw;
        font-size: 4.8vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        border-bottom: 1.067vw solid #003371;
      }
    }
    > img {
      margin: 0 auto;
      margin-top: 17.333vw;
      width: 84vw;
      height: 53.333vw;
      background: #d8d8d8;
      border-radius: 2.133vw;
    }
  }
  width: 100vw;
  min-height: 133.333vw;
  background: rgba(0, 51, 113, 0.05);
}
</style>
