<template>
  <div class="LatheBufferGeometry"></div>
</template>
<script>
import * as Three from 'three'
import Stats from 'stats-js'
export default {
  name: 'LatheBufferGeometry',
  mounted () {
    // FPS引入
    const stats = new Stats()
    stats.showPanel(1) // 0: fps, 1: ms, 2: mb, 3+: custom
    // document.body.appendChild(stats.dom)

    const SEPARATION = 60; const AMOUNTX = 50; const AMOUNTY = 50
    let container
    let camera, scene, renderer
    let particles; let particle; let count = 0
    let mouseX = 100
    let mouseY = -200
    let windowHalfX = document.body.offsetWidth * 0.9 / 2
    // let windowHalfY = (document.body.offsetWidth*0.9 / 2.7) / 2
    let windowHalfY = (document.body.offsetWidth * 0.9 / 2.7) / 2

    const init = () => {
      container = document.createElement('div')
      document.getElementById('three').appendChild(container)
      camera = new Three.PerspectiveCamera(75, document.body.offsetWidth * 0.9 / (document.body.offsetWidth * 0.9 / 2.7), 1, 10000)
      camera.position.z = 1400
      scene = new Three.Scene()

      // 添加背景图
      const texture = new Three.TextureLoader().load(require('@/static/img/bg3.png'))
      scene.background = texture

      particles = []
      const PI2 = Math.PI * 1
      const material = new Three.SpriteMaterial({
        color: '#DFF9FE',
        program: (context) => {
          context.beginPath()
          context.arc(0, 0, 1, 0, PI2, true)
          context.fill()
        }
      })

      let i = 0
      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          particle = particles[i++] = new Three.Sprite(material)
          particle.position.x = ix * SEPARATION - ((AMOUNTX * SEPARATION) / 2)
          particle.position.z = iy * SEPARATION - ((AMOUNTY * SEPARATION) / 2)
          scene.add(particle)
        }
      }
      renderer = new Three.WebGLRenderer({ antialias: true, alpha: true })
      // 设置透明度
      renderer.setClearAlpha(0.2)
      renderer.setSize(document.body.offsetWidth * 0.9, (document.body.offsetWidth * 0.9 / 2.7))
      container.appendChild(renderer.domElement)

      // document.addEventListener('mousemove', onDocumentMouseMove, false)
      // document.addEventListener('touchstart', onDocumentTouchStart, false)
      // document.addEventListener('touchmove', onDocumentTouchMove, false)
      window.addEventListener('resize', onWindowResize, false)
    }

    const onWindowResize = () => {
      windowHalfX = document.body.offsetWidth * 0.9 / 2
      windowHalfY = (document.body.offsetWidth * 0.9 / 2.7) / 2
      camera.aspect = document.body.offsetWidth * 0.9 / (document.body.offsetWidth * 0.9 / 2.7)
      camera.updateProjectionMatrix()
      renderer.setSize(document.body.offsetWidth * 0.9, (document.body.offsetWidth * 0.9 / 2.7))
    }

    const onDocumentMouseMove = (event) => {
      mouseX = event.clientX - windowHalfX
      mouseY = event.clientY - windowHalfY
    }

    const onDocumentTouchStart = (event) => {
      if (event.touches.length === 1) {
        event.preventDefault()
        mouseX = event.touches[0].pageX - windowHalfX
        mouseY = event.touches[0].pageY - windowHalfY
      }
    }

    const onDocumentTouchMove = (event) => {
      if (event.touches.length === 1) {
        event.preventDefault()
        mouseX = event.touches[0].pageX - windowHalfX
        mouseY = event.touches[0].pageY - windowHalfY
      }
    }

    const animate = () => {
      stats.begin()
      stats.end()
      requestAnimationFrame(animate)
      render()
    }

    const render = () => {
      camera.position.x += (mouseX - camera.position.x) * 0.05
      camera.position.y += (-mouseY - camera.position.y) * 0.05
      camera.lookAt(scene.position)

      let i = 0
      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          particle = particles[i++]
          particle.position.y = (Math.sin((ix + count) * 0.3) * 50) + (Math.sin((iy + count) * 0.5) * 50)
          particle.scale.x = particle.scale.y = (Math.sin((ix + count) * 0.3) + 1) * 2 + (Math.sin((iy + count) * 0.5) + 1) * 2
        }
      }

      renderer.render(scene, camera)
      count += 0.1
    }

    // 初始化
    init()
    animate()
  }
}
</script>
