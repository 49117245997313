import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import VueAMap from 'vue-amap'
import axios from 'axios'
import Meta from 'vue-meta'
import { Pagination, Carousel, CarouselItem, Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import 'lib-flexible'
import '@/static/font/font.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

Vue.prototype.$message = Message
Vue.config.productionTip = false
Vue.use(VueAMap)
Vue.use(Meta)
Vue.use(Pagination)
Vue.use(Carousel)
Vue.use(CarouselItem)
// Vue.use(Message)
Vue.prototype.axios = axios
Vue.prototype.replaceHhf = (str) => {
  return str.content.replace(/&nbsp;|\n|\s{2,}/ig, '')
}

VueAMap.initAMapApiLoader({
  key: 'dd4cc5f831b67cde767de9a65f983650',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
