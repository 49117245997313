import request from '@/utils/request' // 引入request.js

// 获取新闻列表
export function getNewsList (data) {
  return request({
    url: '/newsList',
    method: 'get',
    params: data
  })
}
// 获取新闻详情
export function getDetails (data) {
  return request({
    url: '/newsDetail',
    method: 'get',
    params: data
  })
}
