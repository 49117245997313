<template>
  <!-- 合作案例 -->
  <div v-if="flag">
    <div
      class="bg_top"
      :style="'background:url(' + banner[0].image + ') no-repeat 100%/cover'"
    >
      <h2>{{ banner[0].en || "product center" }}</h2>
      <h3>{{ banner[0].title || "合作案例" }}</h3>
      <div v-html="banner[0].brief"></div>
    </div>
    <div class="wb">
      <div class="title">
        <div>{{ banner[0].title }}</div>
        <div>
          <div
            v-for="item in nav"
            :key="item.id"
            :class="active_item == item.id ? 'active_item' : ''"
            @click="active_item = item.id"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="content">
        <div class="content_title">
          <h3>合作案例</h3>
          <h3>Enterprise news</h3>
        </div>
        <div class="school">
          <img :src="item.image" v-for="item in casesData" :key="item.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner, getChildrenNav, getCases } from '@/api/homeApi.js'
export default {
  data () {
    return {
      flag: false,
      active_item: 0,
      banner: {},
      nav: [],
      casesData: {}
    }
  },
  watch: {
    $route () {
      this.active_item = this.$route.query.cid
    },
    async active_item () {
      this.getCases()
    }
  },
  async created () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.active_item = this.$route.query.cid
    await this.getBanner()
    await this.getChildrenNav()
    this.flag = true
  },
  methods: {
    async getCases () {
      const { data: res } = await getCases(parseInt(this.active_item))
      this.casesData = res.case
    },

    async getChildrenNav () {
      const { data: res } = await getChildrenNav({ navId: this.$route.query.fid })
      this.nav = res.nav
    },
    async getBanner () {
      const { data: res } = await getBanner({ navId: this.$route.query.fid })
      this.banner = res.banner
    }
  }

}
</script>

<style scoped lang="scss">
.content {
  width: 75vw;

  min-height: 26.042vw;
  margin: 0 auto;
  .school {
    text-align: start;
    img:nth-child(3n) {
      margin-right: 0;
    }
    > img {
      box-sizing: border-box;
      margin-right: 1.563vw;
      margin-bottom: 2.083vw;
      width: 23.958vw;
      height: 12.5vw;
      background: rgba(0, 52, 114, 0.05);
      box-shadow: 0vw 0.104vw 0.208vw 0vw rgba(0, 52, 114, 0.5);
      border: 0.417vw solid #ffc11f;
    }
  }
  .content_title {
    margin: 2.604vw 0;
    text-align: start;
    > h3:nth-child(1) {
      font-size: 3.333vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
      line-height: 4.688vw;
    }
    > h3:nth-child(2) {
      font-size: 1.875vw;
      font-family: DIN-RegularAlternate, DIN;
      font-weight: normal;
      color: #003472;
      line-height: 2.24vw;
    }
  }
}
.page {
  margin-top: 3.125vw;
}

.title {
  // width: 100%;
  width: 75vw;
  height: 4.167vw;
  // background: rgba(0, 52, 114, 0.1);
  // padding: 0 10.417vw;
  margin: 0 auto;
  > div:nth-child(2) {
    display: flex;
    float: right;
    cursor: pointer;

    .active_item {
      color: #ffc11f;
      border-bottom: 0.208vw solid #ffc11f;
    }
    > div {
      line-height: 4.167vw;
      padding: 0 0.521vw;
      margin: 0 0.521vw;
      height: 4.167vw;
      font-size: 1.25vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003472;
    }
  }
  > div:nth-child(1) {
    float: left;
    width: 10vw;
    height: 3.49vw;
    font-size: 2.5vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #003472;
    line-height: 3.49vw;
  }
}
.bg_top {
  > h2 {
    font-size: 5vw;
    font-family: DIN-Light, DIN;
    font-weight: 300;
    color: #ffc11f;
    line-height: 6.094vw;
  }
  > h3 {
    font-size: 3.333vw;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ffc11f;
  }

  > div {
    margin-top: 2.083vw;
    font-size: 1.25vw;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ffc11f;
    line-height: 1.719vw;
  }
  .div2 {
    margin-top: 0;
  }
  width: 100%;
  height: 30.469vw;
  background: url(@/static/img/q2.png) no-repeat;
  background-size: 100% 100%;
  padding-left: 10.417vw;
  padding-top: 7.813vw;
  text-align: start;
}
</style>
