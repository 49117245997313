<template>
  <div class="mJournalism" v-if="flag">
    <div class="newsList" v-if="isList">
      <div class="title">
        <span
          v-for="item in nav"
          :key="item.id"
          :class="active_item == item.id ? 'active' : ''"
          @click="(active_item = item.id), (newsId = ''), (isList = true)"
          >{{ item.title }}</span
        >
      </div>
      <div class="items">
        <div
          class="item"
          v-for="item in newsList"
          :key="item.id"
          @click="toDetails(item.id)"
        >
          <img :src="item.image" alt="" />
          <div>
            <div class="content">
              {{ item.title }}
            </div>
            <div class="icon">
              <div>
                <i class="iconfont icon-ico_yueduliang"></i>{{ item.views }}
              </div>
              <div>
                <i class="iconfont icon-shijian"></i
                >{{ item.createtime.split(" ")[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage4"
          :page-sizes="[5, 10, 20]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="newsDetails" v-else>
      <div class="title">
        {{ detailsList.detail.title }}
      </div>
      <div class="subhead">{{ detailsList.detail.subheading }}</div>
      <div class="time">
        <div>发布时间：{{ detailsList.detail.createtime }}</div>
        <div>作者：{{ detailsList.detail.author }}</div>
      </div>
      <div class="content" v-html="detailsList.detail.content"></div>

      <div class="page">
        <div
          class="top"
          @click="next(detailsList.pre.id)"
          :class="Object.keys(detailsList.pre).length != 0 ? 'color' : ''"
        >
          上一篇：{{
            Object.keys(detailsList.pre).length == 0
              ? "暂无"
              : detailsList.pre.title
          }}
        </div>
        <div
          class="bottom"
          @click="next(detailsList.next.id)"
          :class="Object.keys(detailsList.next).length != 0 ? 'color' : ''"
        >
          下一篇：{{
            Object.keys(detailsList.next).length == 0
              ? "暂无"
              : detailsList.next.title
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner, getChildrenNav } from '@/api/homeApi.js'
import { getNewsList, getDetails } from '@/api/news.js'
export default {
  data () {
    return {
      pageNum: 1,
      total: 10,
      pageSize: 10,
      active_item: 0,
      banner: {},
      nav: [],
      currentPage4: 1,
      isList: true,
      newsList: [],
      newsId: '',
      detailsList: [],
      flag: false
    }
  },
  watch: {
    $route () {
      this.active_item = this.$route.query.cid
    },
    active_item () {
      this.getNewsList()
    },
    pageNum () {
      this.getNewsList()
    },
    pageSize () {
      this.getNewsList()
    }
  },
  async created () {
    this.active_item = this.$route.query.cid
    if (this.$route.query.nid) {
      this.newsId = this.$route.query.nid
      this.getDetails()
      this.isList = false
    }
    await this.getBanner()
    await this.getChildrenNav()
    this.flag = true
  },
  methods: {

    next (id) {
      if (!id) return
      this.newsId = id
      this.getDetails()
    },
    // 获取新闻详情
    async getDetails () {
      const { data: res } = await getDetails({
        navId: this.active_item,
        newsId: this.newsId
      })
      this.detailsList = res

      const head = document.getElementsByTagName('head')
      const ArticleTitle = document.getElementsByName('ArticleTitle')
      const Author = document.getElementsByName('Author')
      const Description = document.getElementsByName('Description')
      const Keywords = document.getElementsByName('Keywords')
      if (Keywords.length === 0) {
        head[0].innerHTML = head[0].innerHTML + '<meta name="ArticleTitle" content="' + res.detail.seo_title + '"></meta>' + '<meta name="Author" content="' + res.detail.author + '"></meta>' +
          '<meta name="Description" content="' + res.detail.seo_description + '"></meta>' + '<meta name="Keywords" content="' + res.detail.seo_description + '"></meta>'
      } else {
        Keywords.forEach(item => {
          head[0].removeChild(item)
          console.log(item)
        })
        Description.forEach(item => {
          head[0].removeChild(item)
        })

        Author.forEach(item => {
          head[0].removeChild(item)
        })
        ArticleTitle.forEach(item => {
          head[0].removeChild(item)
        })
        head[0].innerHTML = head[0].innerHTML + '<meta name="ArticleTitle" content="' + res.detail.seo_title + '"></meta>' + '<meta name="Author" content="' + res.detail.author + '"></meta>' +
          '<meta name="Description" content="' + res.detail.seo_description + '"></meta>' + '<meta name="Keywords" content="' + res.detail.seo_description + '"></meta>'
      }
    },

    // 获取新闻列表
    async getNewsList () {
      const head = document.getElementsByTagName('head')
      const ArticleTitle = document.getElementsByName('ArticleTitle')
      const Author = document.getElementsByName('Author')
      const Description = document.getElementsByName('Description')
      const Keywords = document.getElementsByName('Keywords')
      Keywords.forEach(item => {
        head[0].removeChild(item)
        console.log(item)
      })
      Description.forEach(item => {
        head[0].removeChild(item)
      })

      Author.forEach(item => {
        head[0].removeChild(item)
      })
      ArticleTitle.forEach(item => {
        head[0].removeChild(item)
      })

      const { data: res } = await getNewsList({
        navId: this.active_item,
        page: this.pageNum,
        pageSize: this.pageSize
      })
      this.newsList = res.list
      this.total = res.count
      // document.body.scrollTop = 500
      // document.documentElement.scrollTop = 500
    },
    // 新闻列表页来的
    handleSizeChange (val) {
      this.pageSize = val
    },
    handleCurrentChange (val) {
      this.pageNum = val
    },

    async toDetails (id) {
      this.newsId = id
      await this.getDetails()
      this.isList = false
    },
    async getChildrenNav () {
      const { data: res } = await getChildrenNav({ navId: this.$route.query.fid })
      this.nav = res.nav
    },
    async getBanner () {
      const { data: res } = await getBanner({ navId: this.$route.query.fid })
      this.banner = res.banner
    }
  }

}
</script>

<style scoped lang="scss">
.mJournalism {
  background: rgba(0, 51, 113, 0.05);
  .newsDetails {
    .page {
      margin-top: 5.333vw;
      text-align: start;
      div {
        font-size: 3.2vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 51, 113, 0.5);
        line-height: 4.533vw;
        margin-bottom: 2.667vw;
      }
    }
    .content {
      text-align: start;
      font-size: 3.2vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003371;
      line-height: 4.533vw;
      img {
        width: 100%;
        height: 48vw;
      }
      * {
        margin: 2.667vw 0;
      }
    }
    .time {
      display: flex;
      width: 74.667vw;
      justify-content: space-between;
      > div {
        height: 2.933vw;
        transform: scaleX(0.8);
      }
      div:nth-child(1) {
        flex: 1;
        font-size: 2.667vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #003371;
        line-height: 2.933vw;
      }
      div:nth-child(2) {
        font-size: 2.667vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #003371;
        line-height: 2.933vw;
      }
    }
    .subhead {
      font-size: 3.2vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #003371;
      line-height: 4.533vw;
      margin: 2.667vw;
      font-weight: 600;
    }
    .title {
      font-size: 4.267vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #003371;
      line-height: 5.867vw;
      font-weight: 600;
    }
    padding: 19.467vw 5.333vw 5.333vw 5.333vw;
    // min-height: 133.333vw;
  }
  > .newsList {
    .page {
      margin: 0 auto;
      height: 6.4vw;
      .el-pagination {
        display: flex;
        justify-content: center;
      }
    }
    .items {
      margin-top: 2.667vw;
      .item {
        background: #ffffff;
        box-shadow: 0vw 0.533vw 1.067vw 0vw rgba(0, 52, 114, 0.5);
        border-radius: 1.067vw;
        height: 21.333vw;
        margin-bottom: 2.667vw;
        display: flex;
        > img {
          width: 26.667vw;
          height: 21.333vw;
          border-radius: 1.067vw 0vw 0vw 1.067vw;
        }
        > div {
          padding: 2.667vw;
          .icon {
            display: flex;
            justify-content: space-between;
            div {
              font-size: 4.267vw;
              transform: scale(0.5);
            }
            div:nth-child(1) {
              transform-origin: 0 50%;
            }
            div:nth-child(2) {
              transform-origin: 100% 50%;
            }
          }
          > .content {
            width: 100%;
            height: 11.733vw;
            overflow: hidden; //多出的隐藏
            text-overflow: ellipsis; //多出部分用...代替
            display: -webkit-box; //定义为盒子模型显示
            -webkit-line-clamp: 2; //用来限制在一个块元素显示的文本的行数
            -webkit-box-orient: vertical; //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
          }
          width: 57.333vw;
        }
      }
    }
    width: 100vw;
    padding: 17.333vw 5.333vw 5.333vw 5.333vw;
    min-height: 133.333vw;
    text-align: start;
    .title {
      > span {
        display: inline-block;
        width: 14.933vw;
        height: 5.333vw;
        font-size: 3.733vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #003371;
        line-height: 5.333vw;
        margin: 0 2.667vw;
        padding-bottom: 2.667vw;
        text-align: center;
        box-sizing: content-box;
      }
      .active {
        width: 21.867vw;
        border-bottom: 1.067vw solid #003371;
        font-size: 4.8vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #003371;
        line-height: 4.667vw;
      }
    }
  }
}
</style>
