<template>
  <div class="enterprise" v-if="flag">
    <img :src="banner[0].image" />
    <div class="scroll" style="overflow-x: auto">
      <div class="items" id="items" :style="'width: ' + 38 * nav.length + 'vw'">
        <span
          v-for="item in nav"
          :key="item.id"
          @click="active_item = item.id"
          :class="active_item == item.id ? 'active item' : 'item'"
          >{{ item.title }}</span
        >
      </div>
    </div>
    <template>
      <div
        v-html="
          singleData ? (singleData.single ? singleData.single.content : '') : ''
        "
      ></div>
    </template>
  </div>
</template>

<script>
import { getBanner, getChildrenNav } from '@/api/homeApi.js'
import { getSingle, getHonor } from '@/api/enterprise.js'

export default {
  data () {
    return {
      active_item: 0,
      banner: {},
      nav: [],
      singleData: {},
      honorData: {},
      flag: false
    }
  },
  watch: {
    $route () {
      this.active_item = parseInt(this.$route.query.cid)
    },
    active_item (id) {
      this.getSingle()
      this.getHonor()
    }
  },
  async created () {
    this.active_item = parseInt(this.$route.query.cid)
    await this.getBanner()
    await this.getChildrenNav()
    await this.getSingle()
    await this.getHonor()
    this.flag = true
  },
  methods: {
    async getSingle () {
      const cs = { navId: this.active_item }

      const { data: res } = await getSingle(cs)
      this.singleData = res
    },
    async getHonor () {
      const { data: res } = await getHonor({ navId: this.active_item })
      this.honorData = res
    },
    async getChildrenNav () {
      const { data: res } = await getChildrenNav({ navId: this.$route.query.fid })
      this.nav = res.nav
    },
    async getBanner () {
      const { data: res } = await getBanner({ navId: this.$route.query.fid })
      this.banner = res.banner
    }
  }

}
</script>

<style scoped lang="scss">
.enterprise {
  .scroll {
    margin-top: 5.333vw;
    width: 100vw;
    padding-left: 5.333vw;
    overflow: hidden;
    .items {
      text-align: start;
      // width: 133.333vw;
      // height: 10.667vw;
      display: flex;
      align-content: center;
    }
    .item {
      display: inline-block;
      padding: 0 2.133vw;
      font-size: 3.733vw;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #003371;
      line-height: 5.333vw;
    }
    .active {
      padding-bottom: 2.133vw;
      line-height: 4.667vw;
      font-size: 4.8vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      border-bottom: 1.067vw solid #003371;
    }
  }
  > img {
    margin: 0 auto;
    margin-top: 17.333vw;
    width: 84vw;
    height: 53.333vw;
    background: #d8d8d8;
    border-radius: 2.133vw;
  }
  width: 100vw;
  min-height: 133.333vw;
  background: rgba(0, 51, 113, 0.05);
}
</style>
