
<template>
  <div class="wscn-http404-container">
    <div class="wscn-http404">
      <div class="pic-404">
        <img
          class="pic-404__parent"
          src="@/assets/error_img/404.png"
          alt="404"
        />
        <img
          class="pic-404__child left"
          src="@/assets/error_img/404_cloud.png"
          alt="404"
        />
        <img
          class="pic-404__child mid"
          src="@/assets/error_img/404_cloud.png"
          alt="404"
        />
        <img
          class="pic-404__child right"
          src="@/assets/error_img/404_cloud.png"
          alt="404"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isPhone: false
    }
  },
  created () {
    if (document.documentElement.clientWidth < 1440) {
      this.isPhone = true
    } else {
      this.isPhone = false
    }
  }
}
</script>

<style lang="less" scoped>
.wscn-http404-container {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
  left: 50%;
  overflow: hidden;
  width: 95%;
}
.wscn-http404 {
  position: relative;
  padding: 0 2.604vw;
  overflow: hidden;
  .pic-404 {
    position: relative;
    width: 31.25vw;
    overflow: hidden;
    margin: 0 auto;

    &__parent {
      width: 100%;
    }
    &__child {
      position: absolute;
      &.left {
        width: 4.167vw;
        top: 0.885vw;
        left: 11.458vw;
        opacity: 0;
        animation-name: cloudLeft;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }
      &.mid {
        width: 2.396vw;
        top: 0.521vw;
        left: 21.875vw;
        opacity: 0;
        animation-name: cloudMid;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 1.2s;
      }
      &.right {
        width: 3.229vw;
        top: 5.208vw;
        left: 26.042vw;
        opacity: 0;
        animation-name: cloudRight;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }
      @keyframes cloudLeft {
        0% {
          top: 0.885vw;
          left: 11.458vw;
          opacity: 0;
        }
        20% {
          top: 1.719vw;
          left: 9.792vw;
          opacity: 1;
        }
        80% {
          top: 4.219vw;
          left: 4.792vw;
          opacity: 1;
        }
        100% {
          top: 5.052vw;
          left: 3.125vw;
          opacity: 0;
        }
      }
      @keyframes cloudMid {
        0% {
          top: 0.521vw;
          left: 21.875vw;
          opacity: 0;
        }
        20% {
          top: 2.083vw;
          left: 18.75vw;
          opacity: 1;
        }
        70% {
          top: 6.771vw;
          left: 9.375vw;
          opacity: 1;
        }
        100% {
          top: 8.333vw;
          left: 6.25vw;
          opacity: 0;
        }
      }
      @keyframes cloudRight {
        0% {
          top: 5.208vw;
          left: 26.042vw;
          opacity: 0;
        }
        20% {
          top: 6.25vw;
          left: 23.958vw;
          opacity: 1;
        }
        80% {
          top: 9.375vw;
          left: 17.708vw;
          opacity: 1;
        }
        100% {
          top: 10.417vw;
          left: 15.625vw;
          opacity: 0;
        }
      }
    }
  }
}
</style>
