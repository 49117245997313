<template>
  <div class="enterprise" v-if="flag">
    <img :src="banner[0].image" />
    <div class="scroll" style="overflow-x: auto">
      <div class="items" id="items" :style="'width:' + 30 * nav.length + 'vw'">
        <span
          v-for="item in nav"
          :key="item.id"
          :class="active_item == item.id ? 'active item' : 'item'"
          @click="active_item = item.id"
          >{{ item.title }}</span
        >
      </div>
    </div>
    <div class="jj_content">
      <img :src="item.image" v-for="item in casesData" :key="item.id" />
    </div>
  </div>
</template>

<script>
import { getBanner, getChildrenNav, getCases } from '@/api/homeApi.js'
export default {
  data () {
    return {
      flag: false,
      active_item: 0,
      banner: {},
      nav: [],
      casesData: {}
    }
  },
  watch: {
    $route () {
      this.active_item = this.$route.query.cid
    },
    async active_item () {
      this.getCases()
    }
  },
  async created () {
    this.active_item = this.$route.query.cid
    await this.getBanner()
    await this.getChildrenNav()
    this.flag = true
  },
  methods: {
    async getCases () {
      const { data: res } = await getCases(parseInt(this.active_item))
      this.casesData = res.case
    },

    async getChildrenNav () {
      const { data: res } = await getChildrenNav({ navId: this.$route.query.fid })
      this.nav = res.nav
    },
    async getBanner () {
      const { data: res } = await getBanner({ navId: this.$route.query.fid })
      this.banner = res.banner
    }
  }

}
</script>

<style scoped lang="scss">
.scroll {
  margin-top: 3vw;
}
.enterprise {
  .jj_content {
    padding: 3.333vw 8vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    img {
      margin-bottom: 5.333vw;
      width: 40vw;
      height: 26.667vw;
    }
  }
  .scroll {
    margin-top: 5.333vw;
    width: 100vw;
    padding-left: 5.333vw;
    overflow: hidden;
    .items {
      text-align: start;
      // width: 133.333vw;
      // height: 10.667vw;
      display: flex;
      align-content: center;
    }
    .item {
      display: inline-block;
      padding: 0 2.133vw;
      font-size: 3.733vw;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #003371;
      line-height: 5.333vw;
    }
    .active {
      padding-bottom: 2.133vw;
      line-height: 6.667vw;
      font-size: 4.8vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      border-bottom: 1.067vw solid #003371;
    }
  }
  > img {
    margin: 0 auto;
    margin-top: 17.333vw;
    width: 84vw;
    height: 53.333vw;
    background: #d8d8d8;
    border-radius: 2.133vw;
  }
  width: 100vw;
  min-height: 133.333vw;
  background: rgba(0, 51, 113, 0.05);
}
</style>
