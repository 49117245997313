import request from '@/utils/request' // 引入request.js
// 留言
export function leaveMessage (data) {
  return request({
    url: '/msg',
    method: 'post',
    data
  })
}

// 获取新闻列表

export function getNewsList (data) {
  return request({
    url: '/newsList',
    method: 'get',
    params: data
  })
}
// 获取二维码
export function getSite (data) {
  return request({
    url: '/site',
    method: 'get',
    params: data
  })
}

// 获取首页产品中心
export function getProductList (data) {
  return request({
    url: '/productList',
    method: 'get',
    params: data
  })
}

// 栏目列表
export function getNavList (data) {
  return request({
    url: '/navList',
    method: 'get',
    params: data
  })
}
// 获取栏目SEO
export function getSEO (data) {
  return request({
    url: '/seo',
    method: 'get',
    params: data
  })
}
// 获取子级栏目
export function getChildrenNav (data) {
  return request({
    url: '/childrenNav',
    method: 'get',
    params: data
  })
}
// 获取栏目banner
export function getBanner (data) {
  return request({
    url: '/banner',
    method: 'get',
    params: data
  })
}

// 首页关于我们
export function getAbout (data) {
  return request({
    url: '/about',
    method: 'get',
    params: data
  })
}

// 首页合作咨询
export function getService (data) {
  return request({
    url: '/service',
    method: 'get',
    params: data
  })
}

// 首页banner
export function getIndexBanner (data) {
  return request({
    url: '/indexBanner',
    method: 'get',
    params: data
  })
}

// 首页企业资质
export function getQualifications (data) {
  return request({
    url: '/qualifications',
    method: 'get',
    params: data
  })
}

// 合作案例
export function getCases (id) {
  const data = {
    navId: id,
    page: 1,
    pageSize: 16
  }
  return request({
    url: '/cases',
    method: 'get',
    params: data
  })
}
