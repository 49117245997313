import request from '@/utils/request' // 引入request.js

// 产品列表
export function getProductList (data) {
  return request({
    url: '/productList',
    method: 'get',
    params: data
  })
}

// 产品详情
export function getProductDetail (data) {
  return request({
    url: '/productDetail',
    method: 'get',
    params: data
  })
}
