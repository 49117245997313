<template>
  <div v-if="flag">
    <!-- 轮播图部分 -->
    <div class="slideshow1">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <template v-for="(item, index) in banner">
            <button
              v-if="index"
              :key="item.title"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              :data-bs-slide-to="index"
              aria-current="true"
              :aria-label="`Slide ` + (index + 1)"
            ></button>
          </template>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="lbt-content">
              <p>{{ banner[0].en }}</p>
              <p>{{ banner[0].title }}</p>
              <!-- <p>banner标题96px medium</p>
              <p>查看详情36px简介这是部分…</p> -->
              <button
                v-if="banner[0].switch"
                @click.stop="open1(banner[0].link)"
              >
                查看详情
                <i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
              </button>
            </div>
            <img :src="banner[0].image" class="d-block w-100" />
          </div>
          <template v-for="(item, index) in banner">
            <div
              class="carousel-item"
              data-bs-interval="2000"
              :key="item.title"
              v-if="index"
            >
              <div class="lbt-content">
                <p>{{ item.en }}</p>
                <p>{{ item.title }}</p>
                <button v-if="item.switch" @click.stop="open1(item.link)">
                  查看详情
                  <i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
                </button>
              </div>
              <img :src="item.image" class="d-block w-100" />
            </div>
          </template>
          <!-- <div class="carousel-item">
            <img src="@/static/img/lbt2.png" class="d-block w-100" />
          </div> -->
          <!-- <div class="carousel-item active" v-if="banner.length == 0">
            <img src="@/static/img/lbt1.png" class="d-block w-100" />
          </div> -->
        </div>
        <!-- <button class="carousel-control-prev" type="button">
          <span
            class="carousel-control-prev-icon"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button">
          <span
            class="carousel-control-next-icon"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Next</span>
        </button> -->
      </div>
    </div>
    <!-- 产品中心 -->
    <div class="mproduct_center">
      <div class="mpro_titile">
        <div>
          产品中心
          <div>PRODUCT CENTER</div>
        </div>
        <div class="More" @click="check('/mproductcenter?cid=8&fid=5')">
          More<i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
        </div>
      </div>
      <div class="mpro_items">
        <div :style="'width:' + 42.667 * productList.length + 'vw'">
          <div
            class="mpro_item"
            v-for="item in productList"
            :key="item.id"
            @click="toPro(item.id)"
          >
            <div class="mpro_item_title">{{ item.title }}</div>
            <img :src="item.image" />
            <div>
              <i class="iconfont icon-qianjin"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方法 -->
    <div class="solve">
      <div id="three"></div>
      <three2 style="wwidth: 89.333vw; height: 16vw" class="three"></three2>
      <canvas id="mom" style="background: rgba(0, 0, 0, 0)"></canvas>
      <span v-html="serviceData"></span>
    </div>
    <!-- 关于我们 -->
    <div class="mabout_us">
      <div class="mabout_titile">
        <div>
          关于我们
          <div>PRODUCT CENTER</div>
        </div>
        <div class="More" @click="check('/mcorporateinformation?fid=9&cid=29')">
          More<i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
        </div>
      </div>
      <div class="mabout_video">
        <video controls :src="aboutData.video_url"></video>
      </div>
    </div>
    <!-- 企业资质 -->
    <div class="maptitude">
      <div class="maptitude_titile">
        <div>企业资质</div>
        <div class="More" @click="check('/mcorporateinformation?fid=9&cid=29')">
          More<i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
        </div>
      </div>
      <div class="maptitude_cont">
        <div class="ap_item">
          <img src="@/static/img/qy1.png" />
          <div>信用证书<br />认证</div>
          <div>{{ aboutData.zhengshu }}<span>项</span></div>
        </div>
        <div class="ap_item">
          <img src="@/static/img/qy2.png" />
          <div>专利证书<br />认证</div>
          <div>{{ aboutData.zhuanli }}<span>项</span></div>
        </div>
        <div class="ap_item">
          <img src="@/static/img/qy3.png" />
          <div>产品服务<br />人次</div>
          <div>{{ aboutData.numbers }}<span>+</span></div>
        </div>
        <div class="ap_item">
          <img src="@/static/img/qy4.png" />
          <div>战略合作<br />伙伴</div>
          <div>{{ aboutData.hezuo }}<span>家</span></div>
        </div>
      </div>
    </div>
    <!-- 合作案例 -->
    <div class="mcooperation_case">
      <div class="mcooperation_title">
        <div>合作案例</div>
        <div class="More" @click.stop="toCoo">
          More<i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
        </div>
      </div>
      <div class="cooperation_case_content">
        <template v-for="(item, index) in casesData">
          <img :src="item.image" v-if="index <= 4" :key="index" />
        </template>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="mjournalism">
      <div class="mjournalism_title">
        <div>新闻资讯</div>
        <div class="More" @click="check('/mjournalism?cid=17&fid=16')">
          More<i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
        </div>
      </div>
      <div class="mjournalism_content">
        <div
          class="mjournalism_item"
          v-for="item in newsList"
          :key="item.id"
          @click="toJou(item.id, item.navId)"
        >
          <img :src="item.image" />
          <div>
            <div>{{ item.title }}</div>
            <div>
              {{ item.briefly }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 友情链接 -->
    <div class="mlink">
      <div class="mlink_title">
        <div>友情链接</div>
        <div class="More">
          More<i class="iconfont icon-yangshi_icon_tongyong_forward"></i>
        </div>
      </div>
      <div class="mlink_content">
        <div v-for="item in site.friendly_url" :key="item.title">
          <a :href="item.url" target="_blank">{{ item.title }}</a>
        </div>
      </div>
    </div>
    <!-- 侧边栏 -->
    <div class="aside">
      <!-- <div
        :class="aside_active === 0 ? 'active' : ''"
        @click="aside_active === 0 ? (aside_active = '') : (aside_active = 0)"
      >
        <i class="icon-changyonglogo37 iconfont"></i>
        <div>App下载</div>
        <div class="ewm" v-show="aside_active === 0">
          <img :src="site.gongzhongh" />
        </div>
      </div> -->
      <div
        :class="aside_active == 1 ? 'active' : ''"
        @click="aside_active === 1 ? (aside_active = '') : (aside_active = 1)"
      >
        <i class="icon-weixingongzhonghao iconfont"></i>
        <div>微信公众号</div>
        <div class="ewm" v-show="aside_active === 1">
          <img :src="site.gongzhongh" />
        </div>
      </div>
      <div
        :class="aside_active == 2 ? 'active' : ''"
        @click="aside_active === 2 ? (aside_active = '') : (aside_active = 2)"
      >
        <i class="icon-xiaochengxu iconfont"></i>
        <div>微信小程序</div>
        <div class="ewm" v-show="aside_active === 2">
          <img :src="site.xiaochengxu" />
        </div>
      </div>
      <div
        :class="aside_active == 3 ? 'active' : ''"
        @click="aside_active === 3 ? (aside_active = '') : (aside_active = 3)"
      >
        <i class="icon-weixin iconfont"></i>
        <div>微信</div>
        <div class="ewm" v-show="aside_active === 3">
          <img :src="site.Wx" />
        </div>
      </div>
      <!-- <div
        :class="aside_active == 4 ? 'active' : ''"
        @click="aside_active === 4 ? (aside_active = '') : (aside_active = 4)"
      >
        <i class="icon-shouji iconfont"></i>
        <div>手机官网</div>
      </div> -->
      <div
        :class="aside_active == 5 ? 'active' : ''"
        @click="aside_active === 5 ? (aside_active = '') : (aside_active = 5)"
      >
        <i class="icon-zaixiankefux iconfont"></i>
        <div>在线咨询</div>
      </div>
      <!-- <div
        @click="
          () => {
            (aside_active = 6), topFunction();
          }
        "
      >
        <i class="icon-icon-test iconfont"></i>
        <div>回到顶部</div>
      </div> -->
      <div class="minwindow" v-if="aside_active == 5">
        <div class="title">
          <span>山东指南针体育产业有限公司</span>
          <span><i class="iconfont icon-liuyanmoban"></i></span>
          <span><i class="iconfont icon-weixin active"></i></span>
          <span @click="aside_active = ''">
            <i class="iconfont icon-guanbi"></i
          ></span>
        </div>
        <div class="content">
          <div class="content_title">在线留言</div>
          <div>
            <div>
              <span>您的名字:</span
              ><input type="text" v-model="leave_msg.name" />
            </div>
            <div>
              <span>您的电话:</span
              ><input type="text" v-model="leave_msg.phone" />
            </div>
            <div>
              <span>公司名称:</span
              ><input type="text" v-model="leave_msg.company" />
            </div>
            <div>
              <span>您的邮箱:</span
              ><input type="text" v-model="leave_msg.Email" />
            </div>
            <div>
              <span>公司地址:</span
              ><input type="text" v-model="leave_msg.address" />
            </div>
            <div class="ly">
              <span>留&nbsp;&nbsp;&nbsp;&nbsp;言:</span>
              <textarea cols="60" rows="10" v-model="leave_msg.msg"></textarea>
            </div>
            <!-- <div class="yzm">
              <span>验&nbsp;证&nbsp;码:</span><input type="text" id="input1" />
            </div>
            <div class="tp">
              <input
                type="button"
                id="checkCode"
                class="code"
                style="width: 16vw"
                @click="createCode"
              />
            </div> -->
            <button @click="leaveMessage">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import three2 from '@/components/three2.vue'
import { getService, getIndexBanner, getQualifications, getBanner, getSite, getProductList, getNewsList, getCases, leaveMessage } from '@/api/homeApi.js'
import { getAbout } from '@/api/iphone'

let code
export default {
  data () {
    return {
      leave_msg: {
        name: '',
        phone: '',
        company: '',
        address: '',
        msg: ''
      },
      flag: false,
      start: '',
      timer2: null,
      timer3: null,
      dot_active: 0,
      aside_active: '',
      aboutData: {},
      serviceData: {},
      indexBannerData: {},
      qualificationsData: {},
      site: {},
      productList: {},
      newsList: [],
      casesData: [],
      canvas: '',
      banner: [{
        image: 'https://zhinanzhen.shuleizhiti.com/uploads/20221130/cb87a5792ab166e1911042aa48df6a41.png',
        title: '首页1',
        en: '首页1',
        brief: '<div>首页1<br/>12312123123123</div>',
        switch: 0,
        link: ''
      },
      {
        image: 'https://zhinanzhen.shuleizhiti.com/uploads/20221130/cb87a5792ab166e1911042aa48df6a41.png',
        title: '首页2',
        en: '首页2',
        brief: '<div>首页2<br/>12312123123123</div>',
        switch: 0,
        link: ''
      }]
    }
  },

  components: {
    three2
  },
  created () {
    this.initFn()

    window.addEventListener('resize', this.onWindowResize, false)
  },
  mounted () {
    setTimeout(() => {
      this.init()
    }, 3000)
  },
  watch: {
    aside_active (item1) {
      if (item1 === 5) {
        setTimeout(() => {
          this.createCode()
        }, 500)
      } else {
        this.leave_msg = {
          name: '',
          phone: '',
          company: '',
          address: '',
          msg: ''
        }
      }
    },
    dot_active (item1) {
      this.getProductList()
    }
  },
  beforeDestroy () {
    clearInterval(this.timer2)
    clearTimeout(this.timer2)
  },
  methods: {
    open1 (url) {
      window.open(url)
    },
    toJou (id, navId) {
      if (id) {
        this.$router.push('/mjournalism?fid=16&cid=' + navId + '&nid=' + id).catch(() => { })
      }
    },
    check (path) {
      this.$router.push(path).catch(() => { })
    },
    // 生成验证码
    createCode () {
      code = []
      const codeLength = 4 // 验证码的长度
      const checkCode = document.getElementById('checkCode')
      checkCode.value = ''
      const selectChar = [2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      for (let i = 0; i < codeLength; i++) {
        const charIndex = Math.floor(Math.random() * 32)
        code += selectChar[charIndex]
      }
      if (code.length !== codeLength) {
        this.createCode()
      }
      checkCode.value = code
    },
    // 校验验证码
    validate () {
      const inputCode = document.getElementById('input1').value.toUpperCase()
      if (inputCode.length <= 0) {
        this.$message({
          title: '请输入验证码',
          type: 'warning'
        })
        return false
      } else if (inputCode !== code) {
        alert('验证码输入错误！')
        this.createCode()
        return false
      } else {
        return true
      }
    },
    // 确定提交留言
    async leaveMessage () {
      if (this.leave_msg.msg === '' || this.leave_msg.name === '' || this.leave_msg.phone === '' || this.leave_msg.Email === '') {
        return this.$message({
          message: '请正确填写信息',
          type: 'warning'
        })
      }
      const telStr = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
      const yxStr = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      // if (this.validate()) {
      if (!(yxStr.test(this.leave_msg.Email))) {
        return this.$message({
          message: '邮箱输入不规范',
          type: 'warning'
        })
      }
      if (!(telStr.test(this.leave_msg.phone))) {
        return this.$message({
          message: '手机号码输入不规范',
          type: 'warning'
        })
      }
      const res = await leaveMessage(this.leave_msg)
      this.$message({
        message: '留言成功！',
        type: 'success'
      })
      this.leave_msg.msg = ''
      this.leave_msg.name = ''
      this.leave_msg.phone = ''
      this.leave_msg.Email = ''
      this.leave_msg.address = ''
      this.leave_msg.company = ''
      // }
    },
    // 初始化数据
    async initFn () {
      await this.getQualifications()
      await this.getIndexBanner()
      await this.getService()
      await this.getAbout()
      await this.getBanner()
      await this.getSite()
      await this.getProductList()
      await this.getNewsList()
      await this.getCases()
      this.flag = true
    },
    // 点击合作案例更多
    toCoo () {
      this.$router.push('/mcooperation?fid=20&cid=30').catch(() => { })
    },
    // 点击企业资质的更多
    toCor () {
      this.$router.push('/mcorporateinformation?fid=9&cid=29').catch(() => { })
    },
    // 点击产品详情
    toPro (id) {
      if (id) {
        this.$router.push('/mproductcenter?fid=5&did=' + id).catch(() => { })
      }
    },
    // 获取合作案例
    async getCases () {
      const { data: res } = await getCases(-1)
      this.casesData = res.case
    },
    // 获取新闻资讯列表
    async getNewsList () {
      const { data: res } = await getNewsList({
        // navId: -1,
        navId: 17,
        page: 1,
        pageSize: 4
      })

      this.newsList = res.list
    },
    // 获取产品中心列表
    async getProductList () {
      const { data: res } = await getProductList({
        navId: -1,
        page: this.dot_active + 1,
        pageSize: 3
      })
      this.productList = res.list
    },
    // 获取侧边二维码
    async getSite () {
      const { data: res } = await getSite()
      this.site = res.site
    },
    // 获取轮播图
    async getBanner () {
      const { data: res } = await getBanner({
        navId: -1
      })
      this.banner = res.banner
    },
    //   获取企业资质   !!需要跟换
    async getQualifications () {
      const { data: res } = await getQualifications()
      this.qualificationsData = this.replaceHhf(res.quali)
    },
    // 获取首页banner
    async getIndexBanner () {
      const { data: res } = await getIndexBanner()
      this.indexBannerData = res.banner
    },
    // 真正的全渠道
    async getService () {
      const { data: res } = await getService()
      this.serviceData = this.replaceHhf(res.service)
    },
    // 关于我们格式化需要更改
    async getAbout () {
      const { data: res } = await getAbout()
      this.aboutData = res.about
    },

    // yc () {
    //   if (this.timer3) return
    //   this.timer3 = setInterval(() => {
    //     this.setScrollY()
    //   }, 3000)
    // },
    scrollY (e) {
    },
    topFunction () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    // 匹配尺寸变化时重新加载数字加载效果
    onWindowResize () {
      // const windowHalfX = parseInt(document.body.offsetWidth) / 2
      // const windowHalfY = (parseInt(document.body.offsetWidth) / 3.5) / 2
      // this.camera.aspect = parseInt(document.body.offsetWidth) / (parseInt(document.body.offsetWidth) / 3.5)
      // this.camera.updateProjectionMatrix()
      // renderer.setSize(parseInt(document.body.offsetWidth), (parseInt(document.body.offsetWidth) / 3.5))
      this.w = parseInt(document.body.offsetWidth)
      this.h = (parseInt(document.body.offsetWidth) / 3)
      this.canvas.width = this.w
      this.canvas.height = (parseInt(document.body.offsetWidth) / 3)
    },
    // 数字下降效果
    init () {
      try {
        this.canvas = document.getElementById('mom')
        this.ctx = this.canvas.getContext('2d')
        this.w = parseInt(document.body.offsetWidth)
        this.h = (parseInt(document.body.offsetWidth) / 3)
        this.canvas.width = parseInt(this.w)
        this.canvas.height = (parseInt(document.body.offsetWidth) / 3)
        // this.canvas.height = 558

        this.fontSize = 16
        this.colunms = Math.floor(this.w / this.fontSize)
        this.drops = []/* 数组用来存放canvas中文字的Y值 更新位置 */
        for (let i = 0; i < this.colunms; i++) {
          this.drops.push(0)// 规定初始时从页面最顶端下来
        }
        this.str = 'CANVAS Javascript'
        this.draw()
        setInterval(this.draw, 50)
      } catch (err) {

      }
    },
    draw () {
      this.ctx.fillStyle = 'rgba(0,0,0,0.05)'/* 背景颜色 */
      // 由于页面在反复调用这个draw方法，透明度也在叠加，使里面的文字颜色也有变化，所有看起来感觉有3D的感觉，有层次感。
      this.ctx.fillRect(0, 0, this.w, this.h)
      this.ctx.font = '700 ' + this.fontSize + 'px 微软雅黑'/* 字体颜色 */
      this.ctx.fillStyle = 'rgb(72,106,141)'
      // 写入画布
      for (let i = 0; i < this.colunms; i++) {
        const index = Math.floor(Math.random() * this.str.length)
        const x = i * this.fontSize
        const y = this.drops[i] * this.fontSize
        this.ctx.fillText(this.str[index], x, y)
        if (y >= this.canvas.height && Math.random() > 0.99) { // 0.99的随机数造成列消失的时间不一致，产生的列也随之改变
          this.drops[i] = 0// 超出高度回到起点
        }
        this.drops[i]++
      }
    }
  }
}
</script>

<style scoped lang="scss">
.navbar-toggler:focus {
  box-shadow: 0 0 0 0 !important;
}
.mlink {
  .mlink_title {
    padding: 0 5.333vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    > div:nth-child(1) {
      font-size: 6.4vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #003472;
      line-height: 9.6vw;
      padding: 0 4.8vw;
      border-left: 1.067vw solid #003371;
      position: relative;

      > div {
        position: absolute;
        bottom: 0;
        left: 3vw;
        width: 29.867vw;
        height: 4.267vw;
        font-size: 3.2vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 52, 114, 0.1);
        line-height: 4.267vw;
      }
    }
  }
  .mlink_content {
    padding: 2.667vw 5.333vw;
    display: flex;
    flex-wrap: wrap;
    > div {
      margin-bottom: 2.667vw;
      a {
        color: #003371;
      }
      margin-right: 2.667vw;
    }
  }
}
.maptitude {
  .maptitude_cont {
    margin: 2.667vw auto;
    width: 84vw;
    height: 420;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .ap_item {
      position: relative;
      > div:nth-child(3) {
        position: absolute;
        right: 2.667vw;
        bottom: 2.667vw;
        font-size: 6.4vw;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #003472;
        > span {
          font-size: 3.2vw;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #003472;
        }
      }
      > div:nth-child(2) {
        position: absolute;
        top: 2.667vw;
        right: 2.667vw;
        width: 12.8vw;
        height: 9.067vw;
        font-size: 3.2vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #003472;
      }
      > img {
        left: 3.2vw;
        top: 5.333vw;
        position: absolute;
        width: 15.733vw;
        height: 16.267vw;
        // background: #000000;
        opacity: 0.2;
      }
      width: 40vw;
      height: 26.667vw;
      margin-bottom: 2.667vw;
      background: #ffffff;
      box-shadow: 0px 0.533vw 1.067vw 0px rgba(0, 51, 113, 0.5);
      border-radius: 2.133vw;
    }
  }
  .maptitude_titile {
    padding: 0 5.333vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div:nth-child(1) {
      font-size: 6.4vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #003472;
      line-height: 9.6vw;
      padding: 0 4.8vw;
      border-left: 1.067vw solid #003371;
      position: relative;

      > div {
        position: absolute;
        bottom: 0;
        left: 3vw;
        // width: 29.867vw;
        height: 4.267vw;
        font-size: 3.2vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 52, 114, 0.1);
        line-height: 4.267vw;
      }
    }
  }
}
.mcooperation_case {
  .cooperation_case_content {
    width: 80vw;
    height: 93.333vw;
    margin: 0 auto;
    > img {
      width: 80vw;
      height: 16vw;
      background: #003472;
      border-radius: 2.133vw;
      margin-bottom: 2.667vw;
    }
  }
  .mcooperation_title {
    padding: 0 5.333vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.667vw;

    > div:nth-child(1) {
      font-size: 6.4vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #003472;
      line-height: 9.6vw;
      padding: 0 4.8vw;
      border-left: 1.067vw solid #003371;
      position: relative;

      > div {
        position: absolute;
        bottom: 0;
        left: 3vw;
        width: 29.867vw;
        height: 4.267vw;
        font-size: 3.2vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 52, 114, 0.1);
        line-height: 4.267vw;
      }
    }
  }
}

.mproduct_center {
  .mpro_items {
    text-align: start;
    margin-top: 2.667vw;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100vw;
    padding-left: 5.333vw;
    height: 26.667vw;
    // white-space: normal;
    > div {
      height: 26.667vw;
      padding-bottom: 5.333vw;
    }
    .mpro_item {
      .mpro_item_title {
        z-index: 9;
        position: absolute;
        left: 2.4vw;
        top: 2.667vw;
        width: 21.333vw;
        height: 11.733vw;
        font-size: 4.267vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #003472;
      }
      > img {
        position: absolute;
        top: 5.067vw;
        right: 2.667vw;
        bottom: 2.933vw;
        left: 16vw;
        width: 21.333vw;
        height: 18.533vw;
      }
      > div:nth-child(3) {
        width: 2.4vw;
        height: 26.003px;
        position: absolute;
        // left: 367vw;
        bottom: 2.933vw;
      }
      position: relative;
      float: left;
      width: 40vw;
      height: 26.667vw;
      margin-right: 2.667vw;
      background: url("@/static/img/product/bg.png") no-repeat 100% / cover;
    }
  }

  .mpro_titile {
    padding: 0 5.333vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div:nth-child(1) {
      font-size: 6.4vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #003472;
      line-height: 9.6vw;
      padding: 0 4.8vw;
      border-left: 1.067vw solid #003371;
      position: relative;

      > div {
        position: absolute;
        bottom: 0;
        left: 3vw;
        width: 29.867vw;
        height: 4.267vw;
        font-size: 3.2vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 52, 114, 0.1);
        line-height: 4.267vw;
      }
    }
  }
  box-sizing: content-box;
  padding-top: 17.067vw;
  width: 100%;
  height: 42.667vw;
}

.mjournalism {
  position: relative;
  width: 100%;
  height: 120.8vw;
  .mjournalism_title {
    padding: 0 5.333vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    > div:nth-child(1) {
      font-size: 6.4vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #003472;
      line-height: 9.6vw;
      padding: 0 4.8vw;
      border-left: 1.067vw solid #003371;
      position: relative;

      > div {
        position: absolute;
        bottom: 0;
        left: 3vw;
        width: 29.867vw;
        height: 4.267vw;
        font-size: 3.2vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 52, 114, 0.1);
        line-height: 4.267vw;
      }
    }
  }
  .mjournalism_content {
    width: 84vw;
    margin: 0 auto;
    .mjournalism_item {
      text-align: start;
      display: flex;
      margin-bottom: 20px;
      width: 84vw;
      height: 21.333vw;
      background: #ffffff;
      box-shadow: 0px 0.533vw 1.067vw 0px rgba(0, 52, 114, 0.5);
      border-radius: 1.067vw;
      img {
        width: 26.667vw;
        height: 21.333vw;
        border-radius: 1.067vw 0px 0px 1.067vw;
      }
      > div {
        padding: 2.667vw;
        width: 57.333vw;

        > div:nth-child(1) {
          width: 48.8vw;
          height: 6.4vw;
          color: #003472;
          font-size: 4.267vw;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
        }
        > div:nth-child(2) {
          width: 52vw;
          height: 8.533vw;
          font-size: 3.2vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #003472;
          line-height: 4.267vw;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

#map_container {
  width: 100%;
  height: 106.665vw;
}
.amap_demo {
  width: 100%;
  height: 100%;
}

.More {
  color: #003472;

  a {
    font-size: 3.2vw;
    color: #003472;
    display: inline;
    position: relative;
    i {
      position: absolute;
      font-size: 9.6vw;
      transform: translateY(-15%);
    }
  }
}
.title1 {
  position: absolute;
  top: 0;
  height: 24.003vw;
  width: 100%;
  div:nth-child(1) {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 10.665vw;
    width: 273.065vw;
    height: 24.003vw;
    font-size: 17.065vw;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    z-index: 3;
    line-height: 24.003vw;
  }
  div:nth-child(2) {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 2365vw;
    transform: translateX(-50%);
    height: 15.467vw;
    font-size: 12.8vw;
    font-family: DIN-MediumAlternate, DIN;
    font-weight: normal;
    color: rgb(68, 122, 154);
    line-height: 15.467vw;
  }
}
.mabout_us {
  position: relative;
  margin: 5.333vw 0;
  .mabout_titile {
    padding: 0 5.333vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div:nth-child(1) {
      font-size: 6.4vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #003472;
      line-height: 9.6vw;
      padding: 0 4.8vw;
      border-left: 1.067vw solid #003371;
      position: relative;

      > div {
        position: absolute;
        bottom: 0;
        left: 3vw;
        width: 29.867vw;
        height: 4.267vw;
        font-size: 3.2vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 52, 114, 0.1);
        line-height: 4.267vw;
      }
    }
  }
  .mabout_video {
    margin: 2.667vw auto;
    width: 85.333vw;
    height: 42.667vw;
    border-radius: 2.133vw;
    overflow: hidden;
    > video {
      width: 100%;
      height: 100%;
    }
  }
  width: 100%;
  background: #f2f5f8;
}

a {
  text-decoration: none;
  outline: none;
  color: #000;
}
.aside {
  position: fixed;
  z-index: 10;
  // height: 149.335vw;
  height: 50vw;
  width: 12.8vw;
  right: 0;
  top: 40vw;
  background-color: rgb(229, 235, 241);
  .minwindow {
    .code {
      background-image: url("@/static/img/bg3.png");
      font-family: Arial, 宋体;
      font-style: italic;
      color: green;
      border: 0;
      padding: 0.267vw 0.4vw;
      letter-spacing: 0.4vw;
      font-weight: bolder;
    }
    .unchanged {
      border: 0;
    }

    .content {
      .ly {
        height: 32vw !important;
      }
      > div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 2.667vw;
        // width: 70.667vw;
        margin: 0 auto;
        box-sizing: content-box;
        height: 200.8vw;
        textarea {
          margin-bottom: 2.667vw;
          width: 100%;
          height: 32vw;
          resize: none;
        }
        > div {
          margin-bottom: 2.667vw;
          // width: 100%;
          height: 5.333vw;
          display: flex;
          align-content: center;
          flex-wrap: nowrap;

          > span {
            padding: 0 1.333vw;
            white-space: nowrap;
            font-size: 3.2vw;
          }
          > input {
            display: inline-block;
            width: 62.2vw;
            height: 10.667vw;
            background: rgba(0, 52, 114, 0.1);
            border: 0.267vw solid #003472 !important;

            outline: none;
            border: none;
          }
        }
        div:nth-child(5) {
          width: 100%;
        }
        > div:nth-last-child(2) {
          height: 40px;
          margin-right: 100px;
          img {
            width: 100%;
          }
        }
        button {
          width: 120px;
          margin: 5px auto;
          border: none;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          height: 36px;
          background: #ffc11f;
          box-shadow: 0px 0px 4px 0px #003472;
          border-radius: 8px;
        }
      }
      .content_title {
        margin: 2.667vw auto;
        width: 12.8vw;
        height: 4.4vw;
        font-size: 3.2vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #003472;
        line-height: 4.4vw;
      }
      .yzm {
        input {
          width: 25vw !important;
        }
      }
      .tp {
        width: 25vw !important;
        margin-right: 0 !important;
      }
    }
    .title {
      padding: 0 2.8vw;
      span {
        font-size: 4.2vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 4.4vw;
      }
      > span:nth-child(1) {
        font-size: 3.2vw;
      }
      .active {
        background: #fff;
        color: #003371;
      }
      i {
        display: inline-block;
        font-size: 5.467vw;
        color: #fff;
        border-radius: 0.267vw;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      width: 100%;
      height: 11.733vw;
      background: #003472;
      border-radius: 1.067vw 1.067vw 0px 0px;
    }
    position: fixed;
    right: 6.667vw;
    width: 84vw;
    height: 162.133vw;
    top: 6.667vw;
    background: #f2f5f8;
    box-shadow: 0px 2px 4px 0px #003472;
    border-radius: 8px;
    background-color: rgb(242, 245, 248);
  }
  .ewm {
    position: absolute;
    left: -25.003vw;
    width: 25.067vw;
    height: 25.067vw;
    top: 0vw;
    > img {
      width: 100%;
      height: 100%;
    }
    background: red;
  }
  > div {
    cursor: pointer;
    height: 12.8vw;
    width: 12.8vw;

    text-align: center;

    > i {
      color: #003472;
      // font-size: 9.344vw;
    }
    > div:nth-child(2) {
      @media screen and (max-width: 400vw) {
        font-size: 3.2vw;
        transform: scale(0.8);
      }
      @media screen and (max-width: 386.667vw) {
        font-size: 3.2vw;
        transform: scale(0.7);
      }
    }
    > div {
      height: 4.531vw;
      font-size: 3.2vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #003371;
      line-height: 4.531vw;
    }
  }
  > .active {
    background: #003472;

    > i {
      color: rgb(229, 235, 241);
    }
    > div {
      color: rgb(229, 235, 241);
    }
  }
}
.slideshow1 {
  width: 100%;
  height: 53.333vw;
  padding-top: 11.733vw;
  #carouselExampleIndicators {
    height: 53.333vw;
  }
  .carousel-inner {
    height: 53.333vw;
  }
  .carousel-item {
    height: 53.333vw;
  }

  .carousel-item {
    position: relative;

    img {
      height: 53.333vw;
    }

    .lbt-content {
      position: absolute;
      left: 15%;
      top: 15%;
      text-align: start;

      p {
        text-align: start;
      }

      p:nth-child(1),
      p:nth-child(2) {
        width: 128vw;
        font-size: 6.4vw;
        display: block;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: medium;
        color: #ffffff;
      }

      // p:nth-child(3),
      // p:nth-child(4),
      // p:nth-child(5) {
      //   width: 166.134vw;
      //   height: 13.333vw;
      //   display: block;
      //   font-size: 9.6vw;
      //   font-family: PingFangSC-Regular, PingFang SC;
      //   font-weight: 400;
      //   color: #ffffff;
      //   line-height: 13.333vw;
      // }

      button {
        .iconfont {
          font-size: 3.2vw;
        }

        font-size: 3.2vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 7.467vw;
        width: 21.333vw;
        height: 7.467vw;
        background: #ffc11f;
        border: none;
        box-shadow: 0vw 0vw 1.065vw 0vw rgba(0, 52, 114, 1);
        border-radius: 1.067vw;
      }
    }
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 21.335vw;
  height: 21.335vw;
  cursor: pointer;
}
.carousel-indicators {
  button {
    width: 8.003vw;
  }
}
</style>
