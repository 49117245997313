import request from '@/utils/request' // 引入request.js

// 单页面内容展示
export function getSingle (data) {
  return request({
    url: '/single',
    method: 'get',
    params: data
  })
}

// 获取企业
export function getHonor (data) {
  return request({
    url: '/honor',
    method: 'get',
    params: data
  })
}
