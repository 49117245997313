
<template>
  <div class="wscn-http404-container">
    <div class="wscn-http404">
      <div class="pic-404">
        <img
          class="pic-404__parent"
          src="@/assets/error_img/404.png"
          alt="404"
        />
        <img
          class="pic-404__child left"
          src="@/assets/error_img/404_cloud.png"
          alt="404"
        />
        <img
          class="pic-404__child mid"
          src="@/assets/error_img/404_cloud.png"
          alt="404"
        />
        <img
          class="pic-404__child right"
          src="@/assets/error_img/404_cloud.png"
          alt="404"
        />
      </div>
      <div class="bullshit">
        <div class="bullshit__oops">404错误!</div>
        <div class="bullshit__headline">找不到网页！</div>
        <div class="bullshit__info">
          对不起，您正在寻找的页面不存在。尝试检查URL的错误，然后按浏览器上的刷新按钮或尝试在我们的应用程序中找到其他内容。
        </div>
        <router-link to="/" class="bullshit__return-home">
          返回首页
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.wscn-http404-container {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  width: 60%;
}
.wscn-http404 {
  position: relative;
  width: 100%;
  padding: 0 2.604vw;
  overflow: hidden;
  .pic-404 {
    position: relative;
    float: left;
    width: 31.25vw;
    overflow: hidden;
    &__parent {
      width: 100%;
    }
    &__child {
      position: absolute;
      &.left {
        width: 4.167vw;
        top: 0.885vw;
        left: 11.458vw;
        opacity: 0;
        animation-name: cloudLeft;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }
      &.mid {
        width: 2.396vw;
        top: 0.521vw;
        left: 21.875vw;
        opacity: 0;
        animation-name: cloudMid;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 1.2s;
      }
      &.right {
        width: 3.229vw;
        top: 5.208vw;
        left: 26.042vw;
        opacity: 0;
        animation-name: cloudRight;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }
      @keyframes cloudLeft {
        0% {
          top: 0.885vw;
          left: 11.458vw;
          opacity: 0;
        }
        20% {
          top: 1.719vw;
          left: 9.792vw;
          opacity: 1;
        }
        80% {
          top: 4.219vw;
          left: 4.792vw;
          opacity: 1;
        }
        100% {
          top: 5.052vw;
          left: 3.125vw;
          opacity: 0;
        }
      }
      @keyframes cloudMid {
        0% {
          top: 0.521vw;
          left: 21.875vw;
          opacity: 0;
        }
        20% {
          top: 2.083vw;
          left: 18.75vw;
          opacity: 1;
        }
        70% {
          top: 6.771vw;
          left: 9.375vw;
          opacity: 1;
        }
        100% {
          top: 8.333vw;
          left: 6.25vw;
          opacity: 0;
        }
      }
      @keyframes cloudRight {
        0% {
          top: 5.208vw;
          left: 26.042vw;
          opacity: 0;
        }
        20% {
          top: 6.25vw;
          left: 23.958vw;
          opacity: 1;
        }
        80% {
          top: 9.375vw;
          left: 17.708vw;
          opacity: 1;
        }
        100% {
          top: 10.417vw;
          left: 15.625vw;
          opacity: 0;
        }
      }
    }
  }
  .bullshit {
    position: relative;
    float: left;
    width: 15.625vw;
    padding: 1.563vw 0;
    overflow: hidden;
    &__oops {
      font-size: 1.667vw;
      font-weight: bold;
      line-height: 2.083vw;
      color: #1482f0;
      opacity: 0;
      margin-bottom: 1.042vw;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }
    &__headline {
      font-size: 1.042vw;
      line-height: 1.25vw;
      color: #222;
      font-weight: bold;
      opacity: 0;
      margin-bottom: 0.521vw;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-delay: 0.1s;
      animation-fill-mode: forwards;
    }
    &__info {
      font-size: 0.677vw;
      line-height: 1.094vw;
      color: grey;
      opacity: 0;
      margin-bottom: 1.563vw;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-delay: 0.2s;
      animation-fill-mode: forwards;
    }
    &__return-home {
      display: block;
      float: left;
      width: 5.729vw;
      height: 1.875vw;
      background: #1482f0;
      border-radius: 5.208vw;
      text-align: center;
      color: #ffffff;
      opacity: 0;
      font-size: 0.729vw;
      line-height: 1.875vw;
      cursor: pointer;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-delay: 0.3s;
      animation-fill-mode: forwards;
    }
    @keyframes slideUp {
      0% {
        transform: translateY(3.125vw);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}
</style>
